import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Button, Typography } from '@mui/material';
import $ from 'jquery';


import Dollar from '../../../shared/Media/Svg/Dollar';
import ButtonArrowRight from '../../../shared/Media/Svg/ButtonArrowRight';
import ButtonArrowLeft from '../../../shared/Media/Svg/ButtonArrowLeft';
import { tableStyles } from './styles';


interface GridData {
  type: string;
  Id: string;
  YearKey: number | never;
  WeekKey: number | never;
  BiRevlineNm: string | never;
  BiSyscodeTypeNm: string | never;
  MdlGrpNm: string | never;
  ModelType: string | never;
  SumOfJanAmt: number;
  SumOfFebAmt: number;
  SumOfMarAmt: number;
  SumOfAprAmt: number;
  SumOfMayAmt: number;
  SumOfJunAmt: number;
  SumOfJulAmt: number;
  SumOfAugAmt: number;
  SumOfSepAmt: number;
  SumOfOctAmt: number;
  SumOfNovAmt: number;
  SumOfDecAmt: number;
  Total: number;
}
interface TablesType {
  gridData: GridData;
  keys: Array<string>;
  selectedRevenue: any;
  heading: string;
}
const Tables: any = ({ gridData, keys }: TablesType | any) => {
  const classes = tableStyles();
  const [header, setHeader] = useState([]);
  useEffect(() => {
    keys = keys && keys.filter((x: any) => x.toString() !== 'type');
    setHeader(keys);
  }, [keys]);

  const headers = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Total',
  ];

  const scrollLeft: any = () => {
    $('.table-responsive').animate(
      { scrollLeft: '-=1100px' },
      '300',
    );
  };

  const scrollRight: any = () => {
    $('.table-responsive').animate(
      { scrollLeft: '+=1100px' },
      '300',
    );
  };

  return (
    <div>
      <Typography component="div" className={classes.byMonth}>
        <Typography component="div" className={classes.ByMonthTitle}>
          Monthly Revenue
        </Typography>

        <TableContainer
          component={Paper}
          className={`${classes.tableContainer} table-responsive`}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell id="firstMonth" className={classes.headCell}></TableCell>
                {headers.map((x) => (
                  <TableCell
                    className={`${classes.headCell} ${x === 'Total' ? classes.Total : ''}`}
                    key={Math.random().toString(16).slice(2)}
                    align="left"
                  >
                    {x}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {gridData &&
                gridData.map((x: any) =>
                  x ? (
                    <TableRow
                      key={Math.random().toString(16).slice(2)}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {x.type === 'Payment Total' ||
                        x.type === 'Performance to Budget B/(W)' ||
                        x.type === 'Performance to Budget % B/(W)' ? (
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableHeadRows}
                        >
                          {x.type} 
                        </TableCell>
                      ) : (
                        <TableCell
                          key={Math.random().toString(16).slice(2)}
                          component="th"
                          scope="row"
                          className={classes.tableHeadRows}
                        >
                          {x.type} 
                        </TableCell>
                      )}
                      {header &&
                        header.map((key: any, headerIndex: any) => (
                          <TableCell
                            align="left"
                            key={Math.random().toString(16).slice(2)}
                            className={`${classes.tableHead} ${headerIndex === header.length - 1 ? classes.Total : ''}`}
                          >
                            {x.type === 'Performance to Budget % B/(W)' ? (
                              <span className={classes.percent}>
                                {x[key].split('%')}%
                              </span>
                            ) : (
                              <span className={x.type === 'Performance to Budget B/(W)' || x.type === 'Payment Total' ? classes.boldRow : classes.normalRow}>
                                {x[key].toString() === '0' ? '-' : (
                                  <>
                                    {x.type === 'Adjustments' || x.type === 'Performance to Budget B/(W)' && x[key] > 0 ? '(' : ''}
                                    <Dollar />{' '}
                                    {Math.abs(x[key])
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    {x.type === 'Adjustments' || x.type === 'Performance to Budget B/(W)' && x[key] > 0 ? ')' : ''} 
                                  </>
                                )}
                              </span>
                            )}
                          </TableCell>
                        ))}
                    </TableRow>
                  ) : (
                    ''
                  ),
                )}
            </TableBody>

          </Table>
        </TableContainer>

        <div className={classes.scrollButtons}>
          <Button id="first" onClick={() => scrollLeft()}>
            <ButtonArrowLeft />
          </Button>
          <Button id="last" onClick={() => scrollRight()}>
            <ButtonArrowRight />
          </Button>
        </div>
      </Typography>
    </div>
  );
};

export default Tables;
