import React from 'react';

const ButtonArrowRight: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#F2F3F5" />
      <path
        d="M14.6666 12L13.7266 12.94L16.7799 16L13.7266 19.06L14.6666 20L18.6666 16L14.6666 12Z"
        fill="#001B33"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#CCD1D6" />
    </svg>
  );
};

export default ButtonArrowRight;
