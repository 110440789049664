import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const navbarStyles = makeStyles(() =>
  createStyles({
    appBar: {
      backgroundColor: '#FFFFFF!important',
      boxShadow: 'none!important',
      height: '72px',
      borderBottom: '1px solid #B3BBC2',
    },
    affiliateLogo: {
      display: 'inline-block',
      borderTop: '0px solid #B3BBC2',
      borderRight: '0px solid #B3BBC2',
      borderBottom: '0px solid #B3BBC2',
      borderLeft: '2px solid #B3BBC2',
      paddingLeft: '16px',
    },
    spectrumLogo: {
      marginRight: '10px',
      paddingRight: '20px',
    },
    tabs: {
      color: '#001B33!important',
      '& .MuiTabs-indicator': {
        backgroundColor: '#66c2e8!important',
      },
      height: '71px!important',
    },
    tab: {
      textTransform: 'capitalize !important' as any,
      display: 'flex!important',
      flexDirection: 'inherit !important' as any,
      alignItems: 'center',
      paddingTop: '0px !important',
    },
    helpNavbar: {
      paddingTop: '.8em',
    },
    TabIcon: {
      marginRight: '7px!important',
      marginBottom: ' 0px!important',
      fill: '#0099D8!important',
    },
    Toolbar: {
      minHeight: '70px!important',
      display: 'flex!important',
      justifyContent: 'space-Between!important',
    },
    profileAndHamMenu: {
      display: 'flex!important',
      alignItems: 'center!important',
      justifyContent: 'space-between!important',
    },
    changePassText: {
      color: '#001B33',
    },
    tabMobileProfile: {
      marginTop: '3% !important',
      justifyContent: 'end !important',
      width: '32%',
    },
    tabMobileHelp: {
      justifyContent: 'end !important',
      position: 'absolute !important' as any,
      right: '0',
    },
    helpIcon: {
      position: 'absolute',
      right: 0,
      marginRight: '1%',
    },
  }),
);

export const drawerStyles = makeStyles({
  hamburger: {
    color: '#001B33!important',
    marginLeft: 'auto !important',
  },
  links: {
    textDecoration: 'none !important',
  },
  TabIcon: {
    marginRight: '7px!important',
    marginBottom: ' 0px!important',
    fill: '#0099D8!important',
  },
  drawertext: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const popOverStyles = makeStyles((theme: Theme) =>
  createStyles({
    popOverContent: {
      top: '3.5vh!important',
      left: '0vw!important',
      [theme.breakpoints.between(0, 400)]: {
        top: '3.7vh!important',
        left: '0vw!important',
      },
    },
    links: {
      textDecoration: 'none !important',
    },
    TabIcon: {
      marginBottom: ' 0px!important',
      fill: '#0099D8!important',
    },
    drawertext: {
      display: 'flex',
      alignItems: 'center',
    },
    list: {
      width: '100vw!important',
      paddingLeft: ' 2vw !important',
    },
  }),
);

export const profileDropDownStyles = makeStyles(() =>
  createStyles({
    dropDownUsername: {
      display: 'inline-block !important',
      padding: '16px 24px 0 24px !important',
      marginBottom: '10px!important',
      textDecoration: 'none !important',
    },
    userDrop: {
      fontWeight: '700 !important' as any,
      color: '#001B33',
      textDecoration: 'none !important',
    },
    userEmailDrop: {
      color: '#007DB3 !important',
      textDecoration: 'none !important',
    },
    dropDownChangePass: {
      padding: '0 24px !important',
      marginBottom: '10px!important',
    },
    helpText: {
      color: '#001B33',
      textDecoration: 'none !important',
    },
    changePassText: {
      color: '#001B33',
      textDecoration: 'none !important',
    },
    dropDownHelp: {
      padding: '0 24px !important',
      marginBottom: '16px!important',
      fontSize: '14px!important',
    },
    dropDownSignOut: {
      padding: '16px 24px 16px 24px !important',
    },
    signOutLink: {
      color: '#667685',
      textDecoration: 'none !important',
    },
    hr: {
      margin: '0 24px 0 24px',
      color: '1px solid #CCD1D6',
    },
  }),
);
