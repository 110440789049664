import {
  FETCH_REVENUE_BY_REQUEST,
  FETCH_REVENUE_BY_SUCCESS,
  FETCH_REVENUE_BY_FAILURE,
} from './revenueByActionTypes';
import { store } from '../../../../app/store';
import axios from 'axios';
import { Dispatch } from 'redux';

interface RevenueBy {
  label: string;
  value: string;
}

const fetchRevenueByRequest:any = () => {
  return {
    type: FETCH_REVENUE_BY_REQUEST,
  };
};

const fetchRevenueBySuccess:any = (REVENUEBY: RevenueBy) => {
  return {
    type: FETCH_REVENUE_BY_SUCCESS,
    payload: REVENUEBY,
  };
};

const fetchRevenueByFailure:any = (error: string) => {
  return {
    type: FETCH_REVENUE_BY_FAILURE,
    payload: error,
  };
};

export const fetchRevenueBy:any = () => {
  const token = store.getState().user.token;
  return function (dispatch: Dispatch) {
    dispatch(fetchRevenueByRequest());
    axios
      .get(`${process.env.REACT_APP_BACKEND}api/AffiliateRevenueBy`, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json-patch+json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        const REVENUEBY = response.data;
        const revenues: any = [];
        REVENUEBY.forEach((element: any) => {
          revenues.push({
            label: element.DisplayName,
            value: element.ID,
          });
        });
        dispatch(fetchRevenueBySuccess(revenues));
      })
      .catch((error) => {
        dispatch(fetchRevenueByFailure(error.response.data.Message));
      });
  };
};
