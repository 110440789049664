import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Checkbox } from '@material-ui/core';
import './AffiliateSalesEditFilter.css';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { affiliateFilterStyles, affiliateFilterStylesUSer } from '../styles';
import { applyDataChange, setSelectedAffiliatesRedux } from '../../../redux';

interface ComboBoxType2 {
  label: string;
  selectAllLabel: string;
  limitTags?: number;
  setAffiliateDropDown: any;
  placeholder?: string;
  component: string;
  optionsData?: Array<AffiliateType2>;
  selectedOptions?: any;
  setSelectedOptions?: any;
}
interface AffiliateType2 {
  AffiliateID: number;
  AffiliateName: string;
  SalesMarkets: Array<string>;
  label: string;
  value: string;
}

const ComboBox2 = ({
  label,
  selectAllLabel,
  limitTags = 3,
  setAffiliateDropDown,
  component,
  optionsData,
  selectedOptions: propSelectedOptions,
  setSelectedOptions: propSetSelectedOptions,
}: ComboBoxType2): any => {
  const defaultAffiliates = useAppSelector((store: any) => store.user.user.Affiliates);
  // Process defaultAffiliates to have label and value
  const processedDefaultAffiliates = defaultAffiliates.map((affiliate: any) => ({
    ...affiliate,
    label: affiliate.AffiliateName,
    value: affiliate.AffiliateName,
  }));
  // defaultAffiliates.forEach((affiliate: AffiliateType) => {
  //   affiliate.label = affiliate.AffiliateName;
  //   affiliate.value = affiliate.AffiliateName;
  // });
  
  // Use optionsData if provided, otherwise use default affiliates
  const affiliates: Array<AffiliateType2> = optionsData || processedDefaultAffiliates;

  //const [selectedOptions , setSelectedOptions ] = React.useState<any>( propSelectedOptions || [] );
  const [selectedOptions, setSelectedOptions] = React.useState<any>(propSelectedOptions || []);

  useEffect(() => {
    if (propSelectedOptions) {
      setSelectedOptions(propSelectedOptions);
    }
  }, [propSelectedOptions]);

  useEffect(() => {
    if (setAffiliateDropDown) {
      setAffiliateDropDown(selectedOptions);
    }
    if (propSetSelectedOptions) {
      propSetSelectedOptions(selectedOptions);
    }
  }, [selectedOptions]);

  const dispatch = useAppDispatch();

  affiliates.forEach((affiliate: AffiliateType2) => {
    affiliate.label = affiliate.AffiliateName;
    affiliate.value = affiliate.AffiliateName;
  });

  let initial = affiliates;


  useEffect(() => {
    setAffiliateDropDown(selectedOptions);
    dispatch(setSelectedAffiliatesRedux(selectedOptions));
    if (initial !== selectedOptions) {
      dispatch(applyDataChange(true));
      initial = selectedOptions;
    }
  }, [selectedOptions]);

  const allSelected = affiliates.length === selectedOptions.length;
  // const handleToggleOption = (selectedOption: any): void =>
  //   setSelectedOptions(selectedOption);
  const handleClearOptions = (): void => setSelectedOptions([]);
  const getOptionLabel = (option: any): any => `${option.label}`;
  const handleSelectAll = (isSelected: boolean): void => {
    if (isSelected) {
      setSelectedOptions(affiliates);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = (): void => {
    handleSelectAll(!allSelected);
  };

  const handleChange = (
    event: React.SyntheticEvent,
    selectedOptionsChange: any,
    reason: any,
  ): any => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOptionsChange.find((option: any) => option.value === 'select-all')) {
        handleToggleSelectAll();
        setAffiliateDropDown(affiliates);
      } else {
        setSelectedOptions(selectedOptionsChange);
        setAffiliateDropDown(selectedOptionsChange);
      }
    } else if (reason === 'clear') {
      handleClearOptions();
    }
  };
  const optionRenderer = (props: any, option: any, { selected }: any): JSX.Element => {
    const selectAllProps =
      affiliates.length > 0 && option.value === 'select-all' // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <>
        <li {...props}>
          <Checkbox
            color="primary"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={selected}
            {...selectAllProps}
          />
          {getOptionLabel(option)}
        </li>
      </>
    );
  };
  const inputRenderer = (params: any): JSX.Element => (
    <>
      <TextField
        {...params}
        className='red-asterik'
        required
        label={
          <>
           {label}
          </>
        }
        variant="outlined"
      />
    </>
  );

  const filter = createFilterOptions();
  const classes = component === 'Performance' ? affiliateFilterStyles() : affiliateFilterStylesUSer();

  return (
    <Autocomplete
      includeInputInList
      size="small"
      multiple
      limitTags={limitTags}
      className={`${classes.filterMain} comboBox chips innerStyles`}
      options={affiliates.sort((a: any, b: any) => -b.value.localeCompare(a.value))}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      //getOptionSelected={(option: any, value) => option.value === value.value}
      isOptionEqualToValue={(option: any, value: any) => {
        return option.value === value.value;
      }}
      filterOptions={(options: any, params: any) => {
        if (affiliates.length > 0) {
          const filtered = filter(options, params);
          return [{ label: selectAllLabel, value: 'select-all' }, ...filtered] as any;
        } else if (affiliates.length === 0) {
          const filtered = filter(options, params);
          return [...filtered] as any;
        }
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
};

ComboBox2.defaultProps = {
  limitTags: 3,
  affiliates: [],
  selectedValues: [],
  getOptionLabel: (value: any) => value,
};

export default ComboBox2;
