import { closeIcon } from './styles';
import React from 'react';

interface ChildPropsType {
  setSnackClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloseIcon = ({ setSnackClose }: ChildPropsType): JSX.Element => {
  const classes = closeIcon();

  return (
    <svg
      onClick={() => setSnackClose(false)}
      className={classes.cancel}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66683 1.27325L8.72683 0.333252L5.00016 4.05992L1.2735 0.333252L0.333496 1.27325L4.06016 4.99992L0.333496 8.72659L1.2735 9.66659L5.00016 5.93992L8.72683 9.66659L9.66683 8.72659L5.94016 4.99992L9.66683 1.27325Z"
        fill="#007DB3"
      />
    </svg>
  );
};

export default CloseIcon;
