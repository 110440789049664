export const FETCH_ADMIN_AFFILIATES_REQUEST = 'FETCH_ADMIN_AFFILIATES_REQUEST';
export const FETCH_ADMIN_AFFILIATES_SUCCESS = 'FETCH_ADMIN_AFFILIATES_SUCCESS';
export const FETCH_ADMIN_AFFILIATES_FAILURE = 'FETCH_ADMIN_AFFILIATES_FAILURE';

export const ADD_AFFILIATE_REQUEST = 'ADD_AFFILIATE_REQUEST';
export const ADD_AFFILIATE_SUCCESS = 'ADD_AFFILIATE_SUCCESS';
export const ADD_AFFILIATE_FAILURE = 'ADD_AFFILIATE_FAILURE';

export const EDIT_AFFILIATE_REQUEST = 'EDIT_AFFILIATE_REQUEST';
export const EDIT_AFFILIATE_SUCCESS = 'EDIT_AFFILIATE_SUCCESS';
export const EDIT_AFFILIATE_FAILURE = 'EDIT_AFFILIATE_FAILURE';

export const DELETE_AFFILIATE_REQUEST = 'DELETE_AFFILIATE_REQUEST';
export const DELETE_AFFILIATE_SUCCESS = 'DELETE_AFFILIATE_SUCCESS';
export const DELETE_AFFILIATE_FAILURE = 'DELETE_AFFILIATE_FAILURE';
