import {
  FETCH_RESET_PASSWORD_REQUEST,
  FETCH_RESET_PASSWORD_SUCCESS,
  FETCH_RESET_PASSWORD_FAILURE,
} from './resetPasswordActionTypes';
import axios from 'axios';
import { alertAction } from '../Alert/alertActions';
import { fetchUser } from '../user/userActions';
import { Dispatch } from 'redux';


const fetchResetPasswordRequest: any = () => {
  return {
    type: FETCH_RESET_PASSWORD_REQUEST,
  };
};

const fetchResetPasswordSuccess: any = (RESETPASSWORD: any) => {
  return {
    type: FETCH_RESET_PASSWORD_SUCCESS,
    payload: RESETPASSWORD,
  };
};

const fetchResetPasswordFailure: any = (error: string) => {
  return {
    type: FETCH_RESET_PASSWORD_FAILURE,
    payload: error,
  };
};

export const resetPassword: any = (loginDto: any, isNewPassword: boolean) => {
  return function (dispatch: Dispatch) {
    dispatch(fetchResetPasswordRequest());
    axios({
      method: 'post',
      data: JSON.stringify(loginDto),
      url: `${process.env.REACT_APP_BACKEND}api/Users/ResetPassword`,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json-patch+json',
      },
    })
      .then((response) => {
        const RESETPASSWORD = response.data;
        dispatch(alertAction('Your password is successfully changed', true, 'success') as any);
        dispatch(fetchResetPasswordSuccess(RESETPASSWORD));
        if (isNewPassword) {
          dispatch(fetchUser({ userName: loginDto.Username, password: loginDto.NewPassword }) as any,
          );
        }
      })
      .catch((error) => {
        dispatch(alertAction(`${error.response.data.Message}`, true, 'error') as any);
        dispatch(fetchResetPasswordFailure(error.response.data.Message));
      });
  };
};
