import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import ErrorImage from './shared/Media/Images/ErrorImage.gif';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useAppSelector } from './app/hooks';

const NotFoundStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainDiv: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 40px!important',
    },
    Para1: {
      fontSize: '39px!important',
      marginBottom: '8px!important',
      color: '#001B33!important',
    },
    Para2: {
      fontSize: '20px!important',
      width: '90%!important',
    },
    buttons: {
      display: 'flex!important',
      flexDirection: 'row!important' as any,
    },
    LoginButton: {
      width: '20%!important',
      textTransform: 'capitalize!important' as any,
      borderRadius: '40px!important',
      backgroundColor: '#007DB3!important',
      padding: '10px 24px!important',
      marginTop: '22px!important',
      [theme.breakpoints.down(920)]: {
        width: '30%!important',
      },
      [theme.breakpoints.down(616)]: {
        width: '40%!important',
      },
    },
    SupportButton: {
      width: '20%!important',
      textTransform: 'capitalize!important' as any,
      borderRadius: '40px!important',
      backgroundColor: '#ffffff!important',
      padding: '10px 24px!important',
      border: '1px solid #001B3399!important',
      color: '#001B3399 !important',
      marginTop: '22px!important',
      marginLeft: '20px!important',
      [theme.breakpoints.down(920)]: {
        width: '30%!important',
      },
      [theme.breakpoints.down(616)]: {
        width: '40%!important',
      },
    },
    LinkLogin: {
      textDecoration: 'none',
      color: '#ffffff!important',
    },
    LinkSupport: {
      textDecoration: 'none',
      color: '#001B33!important',
    },
    errorGIF: {
      width: '30vw!important',
      [theme.breakpoints.down(920)]: {
        display: 'none!important',
      },
    },
  }),
);

function NotFound(): JSX.Element {
  const classes = NotFoundStyles();
  const isLoggedIn = useAppSelector(store => store.user.isLoggedIn);
  return (
    <>
      <Typography component="div" className={classes.mainDiv}>
        <Typography component="div">
          <Typography component="div" className={classes.Para1}>
            Somethings wrong here...
          </Typography>
          <Typography component="div" className={classes.Para2}>
            We cant find the Page you are looking for. Checkout on our Support or head
            back to {isLoggedIn ? (
                'Performance'
              ) : (
                'Login'
              )}
          </Typography>
          <Typography component="div" className={classes.buttons}>
            <Button variant="contained" className={classes.LoginButton}>
              {isLoggedIn ? (
                <Link className={classes.LinkLogin} to="/performance">
                Performance
              </Link>
              ) : (
                <Link className={classes.LinkLogin} to="/">
                Login
              </Link>
              )}
              {' '}
            </Button>
            <Button variant="outlined" className={classes.SupportButton}>
              <Link className={classes.LinkSupport} to="/support">
                Support
              </Link>{' '}
            </Button>
          </Typography>
        </Typography>
        <Typography component="div">
          <img src={ErrorImage} alt="" className={classes.errorGIF} />
        </Typography>
      </Typography>
      {/* <div>NotFound Status error </div> */}
    </>
  );
}

export default NotFound;
