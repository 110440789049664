import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  SET_IS_NEW_PASSWORD_FALSE,
  USER_LOGOUT,
  USER_LOGIN,
} from '../../actions/user/userActionType';

interface ResponseProps {
  payload: any;
  type: any;
}

const initialState = {
  loading: false,
  user: {},
  error: '',
  fetched: false,
  token: '',
  isLoggedIn: false,
  failedAttempts: 0,
};

const userReducer = (state = initialState, action: ResponseProps): any => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        token: action.payload.Token,
        fetched: true,
        isLoggedIn: true,
        failedAttempts: 0,
        error: '',
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.payload,
        isLoggedIn: false,
        failedAttempts: state.failedAttempts + 1,
      };
    case SET_IS_NEW_PASSWORD_FALSE:
      const newUser = action.payload;
      newUser.IsNewPassword = false;
      return {
        ...state,
        user: newUser,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        user: {},
        error: '',
        fetched: false,
        token: '',
        isLoggedIn: false,
        failedAttempts: 0,
      };
    case USER_LOGIN:
      return {
        ...state,
        loading: false,
        error: '',
        fetched: false,
        token: '',
        isLoggedIn: true,
        failedAttempts: 0,
      };
    default:
      return state;
  }
};

export default userReducer;
