import React from 'react';
import { DeleteIconStyles } from './styles';

const DeleteIcon: React.FC = () => {
  const classes = DeleteIconStyles();

  return (
    <svg
      className={classes.DeleteIcon}
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999674 10.6667C0.999674 11.4 1.59967 12 2.33301 12H7.66634C8.39967 12 8.99967 11.4 8.99967 10.6667V2.66667H0.999674V10.6667ZM9.66634 0.666667H7.33301L6.66634 0H3.33301L2.66634 0.666667H0.333008V2H9.66634V0.666667Z"
        fill="#001B33"
      />
    </svg>
  );
};

export default DeleteIcon;
