import {
  FETCH_EXPORT_REQUEST,
  FETCH_EXPORT_FAILURE,
} from './exportActionTypes';
import { store } from '../../../app/store';
import axios from 'axios';
import { alertAction } from '../Alert/alertActions';
import { Dispatch } from 'redux';
import { loaderAction } from '../loader/loaderActions';

const fetchExportRequest: any = () => {
  return {
    type: FETCH_EXPORT_REQUEST,
  };
};



const fetchExportFailure: any = (error: any) => {
  return {
    type: FETCH_EXPORT_FAILURE,
    payload: error,
  };
};

export const exportData: any = () => {
  const selectedData: any = store.getState().selectedValue;
  const user = store.getState().user.user;
  const gridHeader: any = {
    email: user.Email,
    isAdmin: user.IsAdmin,
    weekKey: selectedData.week.toString(),
    yearKey: selectedData.year.toString(),
    biRevline: selectedData.revenueType,
    affiliateNames: selectedData.affiliatesArray,
    salesMarkets: selectedData.salesMarketsArray,
  };

  return function (dispatch: Dispatch) {
    dispatch(fetchExportRequest());
    const token = store.getState().user.token;
    const comapre = selectedData.compare;

    if (comapre) {
      const bodyComapre: any = {
        firstTimePeriod: { ...gridHeader },
        secondTimePeriod: { ...gridHeader },
      };
      bodyComapre.secondTimePeriod.weekKey = (selectedData.week - 1).toString();
      if (selectedData.dateType === 'month') {
        delete gridHeader.weekKey;
        gridHeader.month = selectedData.month.toString();
        bodyComapre.firstTimePeriod.month = selectedData.month.toString();
        bodyComapre.secondTimePeriod.month = (selectedData.month - 1).toString();
      } else if (selectedData.dateType === 'quater') {
        delete gridHeader.weekKey;
        gridHeader.quarter = selectedData.quarter.toString();
        bodyComapre.firstTimePeriod.quarter = selectedData.quarter.toString();
        bodyComapre.secondTimePeriod.quarter = (selectedData.quarter - 1).toString();
      }
      dispatch(loaderAction(true) as any);
      axios
        .get(
          `${process.env.REACT_APP_EXPORT}api/AffiliateWeeklyRevenues/ExportAffiliatesPerformanceData`,
          {
            responseType: 'arraybuffer',
            headers: {
              AffiliatePerformanceFilterExport: JSON.stringify(gridHeader),
              Authorization: 'Bearer ' + token,
              'Content-Type': 'blob',
            },
          },
        )
        .then(() => {
          dispatch(alertAction('Export Initiated', true, 'success') as any);
          dispatch(loaderAction(false) as any);
        })
        .catch((error: any) => {
          dispatch(alertAction(`${error.message}`, true, 'error') as any);
          dispatch(fetchExportFailure(error.message));
          dispatch(loaderAction(false) as any);
        });
    } else {
      if (selectedData.dateType === 'month') {
        delete gridHeader.weekKey;
        gridHeader.monthKey = selectedData.month.toString();
      } else if (selectedData.dateType === 'quater') {
        delete gridHeader.weekKey;
        gridHeader.quarterKey = selectedData.quarter.toString();
      }

      dispatch(loaderAction(false) as any);
      axios
        .get(
          `${process.env.REACT_APP_EXPORT}api/AffiliateWeeklyRevenues/ExportAffiliatesPerformanceData`,
          {
            responseType: 'arraybuffer',
            headers: {
              AffiliatePerformanceFilterExport: JSON.stringify(gridHeader),
              Authorization: 'Bearer ' + token,
              'Content-Type': 'blob',
            },
          },
        )
        .then(() => {
          dispatch(alertAction('Export Initiated', true, 'success') as any);
          dispatch(loaderAction(false) as any);
        })
        .catch((error: any) => {
          dispatch(alertAction(`${error.message}`, true, 'error') as any);
          dispatch(fetchExportFailure(error.message));
          dispatch(loaderAction(false) as any);
        });
    }

  };
};
