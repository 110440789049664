import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import './MultiSelect.css';
import { reportTypeFilterStyles, reportTypeFilterStylesExport } from '../styles';



export default function MultiSelect({
  data,
  label_select,
  selectedData,
  setSelectedData,
  exportComponent,
}: any): any {
  const [selected, setSelected] = useState(selectedData);

  useEffect(() => {
    setSelected(selectedData);
  }, [selected, selectedData, data, setSelectedData]);

  const handleSelect = (value: any): void => {
    if (label_select === 'Revenue Type') {
      setSelected(value.value);
      setSelectedData(value.value);
    } else {
      setSelected(value.value);
      setSelectedData(value.value);
    }
  };

  const classes = exportComponent ? reportTypeFilterStylesExport() : reportTypeFilterStyles();
  return (
    <>
      <Autocomplete
        id="checkboxes-tags-demo"
        options={data}
        size="small"
        value={{ value: selected, label: selected.toString() }}
        getOptionLabel={(option: any) => option.label}
        renderOption={(props, option) => (
          <li {...props}>
            <Radio checked={option.value == selected} />
            {option.label}
          </li>
        )}
        isOptionEqualToValue={(option: any, value: any) => {
          return option.value === value.value;
        }}
        className={`${classes.ReportType} comboBox`}
        renderInput={(params) => (
          <TextField
            {...params}
            className="comboBox red-asterik"
            required
            label={
              label_select === 'Revenue Type' ? (
                <>
                   {label_select}
                </>
              ) : (
                '' + label_select
              )
            }
          />
        )}
        onChange={(event, value) => handleSelect(value)}
      />
    </>
  );
}
