import {
  FETCH_WEEKS_REQUEST,
  FETCH_WEEKS_SUCCESS,
  FETCH_WEEKS_FAILURE,
  FETCH_MONTHS_REQUEST,
  FETCH_MONTHS_SUCCESS,
  FETCH_MONTHS_FAILURE,
  FETCH_QUATER_REQUEST,
  FETCH_QUATER_SUCCESS,
  FETCH_QUATER_FAILURE,
} from '../../actions/performance/weeks/weeksActionTypes';

const initialState = {
  loading: false,
  weeks: [],
  months: [],
  quaters: [],
  error: '',
  fetched: false,
};

const weeksReducer = ( state = initialState, action: any):any => {
  switch (action.type) {
    case FETCH_WEEKS_REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      };
    case FETCH_WEEKS_SUCCESS:
      return {
        ...state,
        loading: false,
        weeks: action.payload,
        fetched: true,
        error: '',
      };
    case FETCH_WEEKS_FAILURE:
      return {
        ...state,
        loading: false,
        weeks: [],
        error: action.payload,
        fetched: false,
      };
    case FETCH_MONTHS_REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      };
    case FETCH_MONTHS_SUCCESS:
      return {
        ...state,
        loading: false,
        months: action.payload,
        fetched: true,
        error: '',
      };
    case FETCH_MONTHS_FAILURE:
      return {
        ...state,
        loading: false,
        months: [],
        error: action.payload,
        fetched: false,
      };
    case FETCH_QUATER_REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      };
    case FETCH_QUATER_SUCCESS:
      return {
        ...state,
        loading: false,
        quaters: action.payload,
        fetched: true,
        error: '',
      };
    case FETCH_QUATER_FAILURE:
      return {
        ...state,
        loading: false,
        quaters: [],
        error: action.payload,
        fetched: false,
      };
    default:
      return state;
  }
};

export default weeksReducer;
