import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import HamBurger from '../Media/Svg/HamBurger';
import { popOverStyles } from './styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import './HamMenu.css';
import { useAppSelector } from '../../app/hooks';

const HamMenu: React.FC = () => {
  const classes = popOverStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const allTabs = ['/performance', '/statement', '/admin'];
  const isAdmin = useAppSelector((store) => store.user.user.IsAdmin);

  return (
    <>
      <Typography aria-describedby={id} onClick={handleClick}>
        <HamBurger />
      </Typography>

      <Popover
        className={`popOverPaper popOver ${classes.popOverContent}`}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List className={classes.list}>
          <ListItemButton>
            <Link to={allTabs[0]} className={classes.links}>
              <ListItemIcon>
                <ListItemText className={classes.drawertext}>
                  <StackedLineChartIcon className={classes.TabIcon} /> Performance
                  Dashboard
                </ListItemText>
              </ListItemIcon>
            </Link>
          </ListItemButton>
          <ListItemButton>
            <Link to={allTabs[1]} className={classes.links}>
              <ListItemIcon>
                <ListItemText className={classes.drawertext}>
                  {' '}
                  <FileOpenIcon className={classes.TabIcon} /> Statements
                </ListItemText>
              </ListItemIcon>
            </Link>
          </ListItemButton>
          {isAdmin ? (
            <ListItemButton>
              <Link to={allTabs[2]} className={classes.links}>
                <ListItemIcon>
                  <ListItemText className={classes.drawertext}>
                    {' '}
                    <ManageAccountsIcon className={classes.TabIcon} /> Admin
                  </ListItemText>
                </ListItemIcon>
              </Link>
            </ListItemButton>
          ) : (
            ''
          )}
        </List>
      </Popover>
    </>
  );
};

export default HamMenu;
