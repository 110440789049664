import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
export const addUserStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
      [theme.breakpoints.between(0, 920)]: {
        width: '70%',
      },
      backgroundColor: '#ffffff!important',
      borderRadius: '4px!important',

      padding: '10px 16px 32px!important',
    },
    addUser: {
      borderRadius: '40px!important',
      display: 'flex',
      alignItems: 'center!important',
      textTransform: 'capitalize !important' as any,
      background: '#007DB3!important',
      color: '#FFFFFF!important',
      boxShadow: 'none!important',
      [theme.breakpoints.between(0, 616)]: {
        width: '48px!important',
        minWidth: ' 0px!important',
        height: '48px!important',
        padding: '0px!important',
      },
    },
    modalTitle: {
      marginTop: '6px!important',
      marginBottom: '16px!important',
      fontSize: '27.65px!important' as any,
    },
    textFields: {
      width: '100%!important',
      marginBottom: '16px!important',
    },
    updateButton: {
      width: '100%!important',
      textTransform: 'capitalize!important' as any,
      borderRadius: '40px!important',
      backgroundColor: '#007DB3!important',
      padding: '10px 24px!important',
    },
    copyButton: {
      width: '100%!important',
      textTransform: 'capitalize!important' as any,
      borderRadius: '40px!important',
      backgroundColor: '#007DB3!important',
      padding: '10px 24px!important',
      marginBottom: '8px!important',
    },
  }),
);
