import React from 'react';

const DownArrow: React.FC = () => {
  return (
    <svg
      width="4"
      height="9"
      viewBox="0 0 4 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.133 9C1.83833 8.38467 1.37033 7.81267 0.729 7.284V6.829C1.00633 6.959 1.249 7.089 1.457 7.219C1.665 7.34033 1.83833 7.46167 1.977 7.583V0.00399959H2.523V7.583C2.67033 7.46167 2.848 7.34033 3.056 7.219C3.264 7.089 3.50233 6.959 3.771 6.829V7.284C3.13833 7.81267 2.67033 8.38467 2.367 9H2.133Z"
        fill="#B00317"
      />
    </svg>
  );
};

export default DownArrow;
