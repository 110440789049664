import {
  SET_SELECTED_YEAR,
  SET_SELECTED_WEEK,
  SET_SELECTED_MONTH,
  SET_SELECTED_QUARTER,
  SET_SELECTED_AFFILIATES,
  SET_SELECTED_REVENUE_TYPE,
  SET_SELECTED_SALES_MARKETS,
  SET_CHANGED,
  SET_COMPARE,
  SET_DATE_TYPE,
} from './selectedValueActionTypes';
import { loaderAction } from '../../loader/loaderActions';
import { Dispatch } from 'redux';
import { getWeeks, getMonths, getQuaters } from '../weeks/weeksActions';

interface Affiliates {
  AffiliateID: number;
  AffiliateName: string;
  SalesMarkets: Array<string>;
  label: string;
  value: string;
}

const selectedYearSet:any = (selectedYear: number) => {
  return {
    type: SET_SELECTED_YEAR,
    payload: selectedYear,
  };
};

const selectedWeekSet:any = (selectedWeek: number) => {

  return {
    type: SET_SELECTED_WEEK,
    payload: selectedWeek,
  };
};
const selectedMonthSet:any = (selectedMonth: number) => {

  return {
    type: SET_SELECTED_MONTH,
    payload: selectedMonth,
  };
};
const selectedQuarterSet:any = (selectedQuarter: number) => {

  return {
    type: SET_SELECTED_QUARTER,
    payload: selectedQuarter,
  };
};

const selectedAffiliateSet:any = (affiliates: Affiliates) => {

  return {
    type: SET_SELECTED_AFFILIATES,
    payload: affiliates,
  };
};
const selectedSalesMarketsSet:any = (salesMarkets: { label: string; value: string }) => {

  return {
    type: SET_SELECTED_SALES_MARKETS,
    payload: salesMarkets,
  };
};
const selectedRevenueTypeSet:any = (revenueType: string) => {

  return {
    type: SET_SELECTED_REVENUE_TYPE,
    payload: revenueType,
  };
};
const setChanged:any = (changed: boolean) => {
  return {
    type: SET_CHANGED,
    payload: changed,
  };
};
const setCompare:any = (changed: boolean) => {
  return {
    type: SET_COMPARE,
    payload: changed,
  };
};

const setDateType:any = (type: string) => {
  return {
    type: SET_DATE_TYPE,
    payload: type,
  };
};
export const applyDataChange = (changed: boolean) => {
  return function (dispatch: Dispatch) {
    dispatch(setChanged(changed));
  };
};

export const setSelectedYearRedux = (selectedYear: any) => {
  return function (dispatch: Dispatch) {
    dispatch(selectedYearSet(selectedYear));
    dispatch(getWeeks(selectedYear) as any);
    dispatch(getMonths(selectedYear) as any);
    dispatch(getQuaters(selectedYear) as any);
    dispatch(applyDataChange(true) as any);
  };
};
export const setSelectedWeekRedux = (selectedWeek: any) => {
  return function (dispatch: Dispatch) {
    dispatch(selectedWeekSet(selectedWeek));
    dispatch(applyDataChange(true) as any);
  };
};
export const setSelectedMonthRedux = (selectedMonth: any) => {
  return function (dispatch: Dispatch) {
    dispatch(selectedMonthSet(selectedMonth));
    dispatch(applyDataChange(true) as any);
  };
};
export const setSelectedQuaterRedux = (selectedQuarter: any) => {
  return function (dispatch: Dispatch) {
    dispatch(selectedQuarterSet(selectedQuarter));
    dispatch(applyDataChange(true) as any);
  };
};
export const setSelectedRevenueTypeRedux = (revenueType: any) => {
  return function (dispatch: Dispatch) {
    dispatch(selectedRevenueTypeSet(revenueType));
    dispatch(applyDataChange(true) as any);
  };
};

export const setSelectedAffiliatesRedux = (affiliates: any) => {
  return function (dispatch: Dispatch) {
    dispatch(selectedAffiliateSet(affiliates));
    dispatch(applyDataChange(true) as any);
  };
};
export const setSelectedSalesMarketsRedux = (salesMarkets: any) => {
  return function (dispatch: Dispatch) {
    dispatch(selectedSalesMarketsSet(salesMarkets));
    dispatch(applyDataChange(true) as any);
    dispatch(loaderAction(false) as any);
  };
};

export const applyCompareChange = (compare: boolean) => {
  return function (dispatch: Dispatch) {
    dispatch(setCompare(compare));
    dispatch(applyDataChange(true) as any);
  };
};
export const changeDateType = (type: string) => {
  return function (dispatch: Dispatch) {
    dispatch(setDateType(type));
    dispatch(applyDataChange(true) as any);
  };
};
