import React from 'react';

const File: React.FC = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33366 0.333496H2.00033C1.6467 0.333496 1.30756 0.473972 1.05752 0.72402C0.807468 0.974069 0.666992 1.31321 0.666992 1.66683V12.3335C0.666992 12.6871 0.807468 13.0263 1.05752 13.2763C1.30756 13.5264 1.6467 13.6668 2.00033 13.6668H10.0003C10.3539 13.6668 10.6931 13.5264 10.9431 13.2763C11.1932 13.0263 11.3337 12.6871 11.3337 12.3335V4.3335L7.33366 0.333496ZM4.66699 11.6668H2.66699V10.3335H4.66699V11.6668ZM4.66699 9.66683H2.66699V8.3335H4.66699V9.66683ZM4.66699 7.66683H2.66699V6.3335H4.66699V7.66683ZM7.33366 11.6668H5.33366V10.3335H7.33366V11.6668ZM7.33366 9.66683H5.33366V8.3335H7.33366V9.66683ZM7.33366 7.66683H5.33366V6.3335H7.33366V7.66683ZM6.66699 5.00016V1.3335L10.3337 5.00016H6.66699Z"
        fill="#001B33"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default File;
