import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const graphStyles = makeStyles((theme: Theme) =>
  createStyles({
    graphs: {
      height: '260px!important',
      width: '100%!important',
      [theme.breakpoints.up(4800)]: {
        height: '760px!important',
      },
      [theme.breakpoints.between(2200, 4800)]: {
        height: '560px!important',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        height: '360px!important',
      },
      [theme.breakpoints.between(1240, 1448)]: {
        height: '300px!important',
      },
      [theme.breakpoints.between(920, 1240)]: {
        height: '278px!important',
      },
      [theme.breakpoints.between(0, 616)]: {
        height: '191px!important',
        paddingRight: '16px!important',
      },
    },
    marketAndPayments: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      border: '1px solid #CCD1D6',
      padding: '1.5% 32px 40px 10px !important',
      [theme.breakpoints.between(616, 1240)]: {
        padding: '16px 16px 24px !important',
      },
      [theme.breakpoints.between(0, 616)]: {
        padding: '0!important',
      },
    },
    PerformanceBudgetTitle: {
      padding: '0 22px 0 22px !important',
      fontSize: '19.2px !important',
      color: '#001B33!important',
      [theme.breakpoints.between(616, 1240)]: {
        padding: '0 22px 0 13px !important',
      },
      [theme.breakpoints.between(0, 616)]: {
        padding: '0 22px 0 13px !important',
      },
    },
    PerformanceBudgetSubTitle: {
      padding: '0 22px 0 22px !important',
      color: '#667685',
      fontSize: '11.33px !important',
      [theme.breakpoints.between(616, 1240)]: {
        padding: '0 22px 0 13px !important',
      },
      [theme.breakpoints.between(0, 616)]: {
        padding: '0 22px 0 13px!important',
      },
    },
  }),
);

export const tableStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      boxShadow: 'none!important',
      overflowX: 'hidden !important' as any,
    },
    tableHead: {
      minWidth: '100px!important',
      padding: '16px 2px !important',
    },
    tableHeadRows: {
      minWidth: '200px!important',
      padding: '16px 2px !important',
    },
    headCell: {
      paddingTop: '0px !important',
      color: '#0099D8!important',
      borderBottomColor: '#0099D8!important',
    },
    Total:{
      fontWeight:'700!important' as any,

    },
    scrollButtons: {
      textAlign: 'right !important' as any,
    },
    ByMonthTitle: {
      marginBottom: '24px!important',
      fontSize: '19.2px !important',
    },
    byMonth: {
      padding: '24px',
      border: '1px solid #CCD1D6',
      marginTop: '24px!important',
    },
    boldRow: {
      minWidth: '150px !important',
      [theme.breakpoints.down(1380)]: {
        minWidth: '123px !important',
        padding: '0px !important',
      },
    },
    normalRow: {
      minWidth: '150px !important',
      [theme.breakpoints.down(1380)]: {
        minWidth: '123px !important',
        padding: '0px !important',
      },
    },
    percent: {
      color: '#000000!important',
    },
  }),
);

export const monthStyles = makeStyles((theme: Theme) =>
  createStyles({
    // ====================================================MOB MONTH VALUES=======================
    byMonthMob: {
      padding: '16px !important',
      paddingBottom: '30px !important',
      border: '1px solid #CCD1D6',
      marginTop: '24px!important',
    },
    byMonthMobTitle: {
      fontWeight: 350,
      fontSize: '19.2px!important',
    },
    calendarMonth: {
      fontSize: '23.4px !important',
      color: '#0099D8',
      borderBottom: '1px solid #0099D8',
      marginTop: '3.2%!important',
    },
    monthValues: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      marginTop: '2.5%!important',
    },
    dataTitles: {
      fontSize: '13.33px!important',
    },
    dataValues: {
      fontSize: '23.04px!important',
      paddingBottom: '2px',
      marginBottom: '2px!important',
    },
    compareValues: {
      fontSize: '13px!important',
      position: 'relative!important' as any,
      top: '-10%!important',
      color: '#09753B!important',
    },
    compareRedValues: {
      fontSize: '13px!important',
      position: 'relative!important' as any,
      top: '-10%!important',
      color: '#B00317!important',
    },
    monthCurrent: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    monthBudgeted: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    monthPerformance: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    monthPerformancePercent: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    buttonsByMonthMob: {
      width: '100%',
      marginTop: '24px!important',
    },
    previousButton: {
      display: 'flex',
      borderRadius: '40px!important',
      textTransform: 'capitalize !important' as any,
      borderColor: '#001B33!important',
      color: '#001B33!important',
    },
    nextButton: {
      display: 'flex',
      float: 'right !important' as any,
      borderRadius: '40px!important',
      textTransform: 'capitalize !important' as any,
      borderColor: '#001B33!important',
      color: '#001B33!important',
    },
  }),
);

export const yearStyles = makeStyles((theme: Theme) =>
  createStyles({
    // ====================================================MOB MONTH VALUES=======================
    byMonthMob: {
      padding: '16px !important',
      paddingBottom: '30px !important',
      border: '1px solid #CCD1D6',
      marginTop: '24px!important',
    },
    byMonthMobTitle: {
      fontWeight: 350,
      fontSize: '19.2px!important',
    },
    calendarMonth: {
      fontSize: '23.4px !important',
      color: '#0099D8',
      borderBottom: '1px solid #0099D8',
      marginTop: '3.2%!important',
    },
    monthValues: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      marginTop: '2.5%!important',
    },
    dataTitles: {
      fontSize: '13.33px!important',
    },
    dataValues: {
      fontSize: '23.04px!important',
      paddingBottom: '2px',
      marginBottom: '2px!important',
    },
    compareValues: {
      fontSize: '13px!important',
      position: 'relative!important' as any,
      top: '-10%!important',
      color: '#09753B!important',
    },
    compareRedValues: {
      fontSize: '13px!important',
      position: 'relative!important' as any,
      top: '-10%!important',
      color: '#B00317!important',
    },
    monthCurrent: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    monthBudgeted: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    monthPerformance: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    monthPerformancePercent: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    buttonsByMonthMob: {
      width: '100%',
      marginTop: '24px!important',
    },
    previousButton: {
      display: 'flex',
      borderRadius: '40px!important',
      textTransform: 'capitalize !important' as any,
      borderColor: '#001B33!important',
      color: '#001B33!important',
    },
    nextButton: {
      display: 'flex',
      float: 'right !important' as any,
      borderRadius: '40px!important',
      textTransform: 'capitalize !important' as any,
      borderColor: '#001B33!important',
      color: '#001B33!important',
    },
  }),
);
