import React from 'react';
import { CloseIconModalsStyles } from './styles';

interface ChildPropsType {
  setSnackClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloseIconModals = ({ setSnackClose }: ChildPropsType): JSX.Element => {
  const classes = CloseIconModalsStyles();

  return (
    <svg
      onClick={() => setSnackClose(false)}
      className={classes.closeIcon}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66634 1.27301L8.72634 0.333008L4.99967 4.05967L1.27301 0.333008L0.333008 1.27301L4.05967 4.99967L0.333008 8.72634L1.27301 9.66634L4.99967 5.93967L8.72634 9.66634L9.66634 8.72634L5.93967 4.99967L9.66634 1.27301Z"
        fill="#667685"
      />
    </svg>
  );
};

export default CloseIconModals;
