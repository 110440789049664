import {
  FETCH_EMAIL_REQUEST,
  FETCH_EMAIL_SUCCESS,
  FETCH_EMAIL_FAILURE,
  CHANGE_EMAIL_FETCHED_STATE,
} from '../../actions/email/emailActionTypes';

const initialState = {
  loading: false,
  response: '',
  error: '',
  fetched: false,
};

interface Affiliates {
  AffiliateID: number;
  AffiliateName: string;
  SalesMarkets: Array<string>;
}
interface YearWeek {
  value: number;
  label: string;
}
interface YearWeeks {
  Year: YearWeek;
  Week: YearWeek;
}
interface Payload {
  Affiliates: Affiliates;
  CurrentTokenExpiry: string;
  Email: string;
  IsAdmin: boolean;
  IsNewPassword: boolean;
  Name: string;
  Password: any;
  Roles: Array<string>;
  Token: string;
  YearWeeks: YearWeeks;
}
interface EmailAction {
  payload: Payload;
  type: string;
}

const emailReducer = ( state = initialState, action: EmailAction | any):any => {
  switch (action.type) {
    case FETCH_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      };
    case FETCH_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: '',
        fetched: true,
      };
    case FETCH_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        response: '',
        error: action.payload,
        fetched: false,
      };
    case CHANGE_EMAIL_FETCHED_STATE:
      return {
        ...state,
        fetched: false,
      };
    default:
      return state;
  }
};

export default emailReducer;
