import { changePasswordLogo } from './styles';
import React from 'react';
const ChangePasswordLogo: React.FC = () => {
  const classes = changePasswordLogo();

  return (
    <svg
      className={classes.SVGDropdown}
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 5.33342H9.33317V4.00008C9.33317 2.16008 7.83984 0.666748 5.99984 0.666748C4.15984 0.666748 2.6665 2.16008 2.6665 4.00008V5.33342H1.99984C1.2665 5.33342 0.666504 5.93341 0.666504 6.66675V13.3334C0.666504 14.0667 1.2665 14.6667 1.99984 14.6667H9.99984C10.7332 14.6667 11.3332 14.0667 11.3332 13.3334V6.66675C11.3332 5.93341 10.7332 5.33342 9.99984 5.33342ZM5.99984 11.3334C5.2665 11.3334 4.6665 10.7334 4.6665 10.0001C4.6665 9.26675 5.2665 8.66675 5.99984 8.66675C6.73317 8.66675 7.33317 9.26675 7.33317 10.0001C7.33317 10.7334 6.73317 11.3334 5.99984 11.3334ZM8.0665 5.33342H3.93317V4.00008C3.93317 2.86008 4.85984 1.93341 5.99984 1.93341C7.13984 1.93341 8.0665 2.86008 8.0665 4.00008V5.33342Z"
        fill="#001B33"
      />
    </svg>
  );
};

export default ChangePasswordLogo;
