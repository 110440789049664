import React from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { Typography } from '@mui/material';
import { ChangePasswordLogo, GetHelpLogo } from '../../Media/Svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import { profileDropDownStyles } from '../styles';
import { logout } from '../../../redux/actions/logout/logoutActions';

interface ChildPropsType {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const ProfileDropdown: React.FC<ChildPropsType> = ({ anchorEl, setAnchorEl }) => {
  const classes = profileDropDownStyles();
  const dispatch = useAppDispatch();

  const user = useAppSelector((store) => store.user.user);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClose = ():void => {
    setAnchorEl(null);
  };
  const logOutUser = ():void => {
    dispatch(logout());
    navigate('/');
    setAnchorEl(null);
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      sx={{ boxShadow: 'none' }}
      className="dropDownShadow"
    >
      <MenuItem className={classes.dropDownUsername}>
        <Typography component="h2" className={classes.userDrop}>
          {user.Name}
        </Typography>
        <Link to="/" onClick={handleClose} className={classes.userEmailDrop}>
          {user.Email}
        </Link>
      </MenuItem>
      <MenuItem className={classes.dropDownChangePass}>
        <Typography component="h2" className={classes.changePassText}>
          <ChangePasswordLogo />
          <Link to="/changePassword" onClick={handleClose} className={classes.helpText}>
            Change Password
          </Link>
        </Typography>
      </MenuItem>
      <MenuItem className={classes.dropDownHelp}>
        <Typography component="h2" className={classes.helpText}>
          <GetHelpLogo />
          <Link to="/support" onClick={handleClose} className={classes.helpText}>
            Get Help
          </Link>
        </Typography>
      </MenuItem>
      <hr className={classes.hr} />
      <MenuItem onClick={logOutUser} className={classes.dropDownSignOut}>
        <Link to="" onClick={handleClose} className={classes.signOutLink}>
          Sign out
        </Link>
      </MenuItem>
    </Menu>
  );
};

export default ProfileDropdown;
