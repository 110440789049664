import { SHOW_ALERT, HIDE_ALERT } from './alertActionTypes';
import { Dispatch } from 'redux';

const showAlert:any = (message: string, type: string) => {
  return {
    type: SHOW_ALERT,
    payload: { message: message, type: type },
  };
};

const hideAlert:any = () => {
  return {
    type: HIDE_ALERT,
  };
};

export const alertAction = (message: string, show: boolean, type: string) => {
  return function (dispatch: Dispatch) {
    if (show) {
      dispatch(showAlert(message, type));
    } else {
      dispatch(hideAlert());
    }
  };
};
