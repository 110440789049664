import {
  FETCH_STATEMENT_REQUEST,
  FETCH_STATEMENT_SUCCESS,
  FETCH_STATEMENT_FAILURE,
  UPLOAD_STATEMENT_REQUEST,
  UPLOAD_STATEMENT_SUCCESS,
  UPLOAD_STATEMENT_FAILURE,
  DELETE_STATEMENT_REQUEST,
  DELETE_STATEMENT_SUCCESS,
  DELETE_STATEMENT_FAILURE,
} from './statementActionTypes';
import { store } from '../../../app/store';
import { loaderAction } from '../loader/loaderActions';

import axios from 'axios';
import { alertAction } from '../Alert/alertActions';

interface Statements {
  Id: number;
  AffiliateName: string;
  DocumentFieldId: string;
  FileName: string;
  Month: string;
  Year: number;
}

const fetchStatementRequest: any = () => {
  return {
    type: FETCH_STATEMENT_REQUEST,
  };
};

const fetchStatementSuccess: any = (Statement: Statements) => {
  return {
    type: FETCH_STATEMENT_SUCCESS,
    payload: Statement,
  };
};

const fetchStatementFailure: any = (error: string) => {
  return {
    type: FETCH_STATEMENT_FAILURE,
    payload: error,
  };
};

const uploadStatementRequest: any = () => {
  return {
    type: UPLOAD_STATEMENT_REQUEST,
  };
};

const uploadStatementSuccess: any = (Statement: Statements) => {
  return {
    type: UPLOAD_STATEMENT_SUCCESS,
    payload: Statement,
  };
};

const uploadStatementFailure: any = (error: string) => {
  return {
    type: UPLOAD_STATEMENT_FAILURE,
    payload: error,
  };
};

const deleteStatementRequest: any = () => {
  return {
    type: DELETE_STATEMENT_REQUEST,
  };
};

const deleteStatementSuccess: any = (Statement: Statements) => {
  return {
    type: DELETE_STATEMENT_SUCCESS,
    payload: Statement,
  };
};

const deleteStatementFailure: any = (error: any) => {
  return {
    type: DELETE_STATEMENT_FAILURE,
    payload: error,
  };
};

export const getStatements: any = () => {
  const email = store.getState().user.user.Email;
  const token = store.getState().user.token;
  return function (dispatch: any) {
    dispatch(loaderAction(true) as any);
    dispatch(fetchStatementRequest());
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND}api/Statements/GetUploadedFiles`,
      headers: {
        UserName: email,
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const Statement = response.data;
        dispatch(fetchStatementSuccess(Statement));
        dispatch(loaderAction(false) as any);
      })
      .catch((error) => {
        dispatch(fetchStatementFailure(error.response.data.Message));
        dispatch(loaderAction(false) as any);
      });
  };
};

export const uploadStatementRedux: any = (formData: any, modifiedDate: any, type: any) => {
  const token = store.getState().user.token;
  const username = store.getState().user.user.Email;
  return function (dispatch: any) {
    dispatch(uploadStatementRequest());
    dispatch(loaderAction(true) as any);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND}api/Statements/${type === 'single' ? 'UploadStatementFile' : 'UploadMultipleStatementFiles'}`,
      headers: {
        UserName: username,
        Year: modifiedDate[3],
        Month: modifiedDate[1],
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
      .then((response) => {
        const Statement = response.data;
        if (Statement.Success) {
          dispatch(uploadStatementSuccess(Statement));
          dispatch(alertAction('Statement Successfully Uploaded ...', true, 'success'));
          dispatch(loaderAction(false) as any);
          dispatch(getStatements());
        } else {
          dispatch(alertAction(`${Statement.Details}`, true, 'error'));
          dispatch(uploadStatementFailure(Statement.Details));
          dispatch(loaderAction(false) as any);

        }
      })
      .catch((error) => {
        dispatch(alertAction(`${error.response.data.Message}`, true, 'error'));
        dispatch(uploadStatementFailure(error.response.data.Message));
        dispatch(loaderAction(false) as any);
      });
  };
};

export const deleteStatement: any = (id: any) => {
  const token = store.getState().user.token;
  return function (dispatch: any) {
    dispatch(deleteStatementRequest());
    dispatch(loaderAction(true) as any);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND}api/Statements/DeleteDocAsync?id=${id}`,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const Statement = response.data;
        if (Statement.Success) {
          dispatch(deleteStatementSuccess(Statement));
          dispatch(loaderAction(false) as any);
          dispatch(alertAction('Statement Successfully Deleted ...', true, 'success'));
          dispatch(getStatements());
        } else {
          dispatch(alertAction(`${Statement.Details}`, true, 'error'));
          dispatch(loaderAction(false) as any);
        }
      })
      .catch((error) => {
        dispatch(alertAction(`${error.response.data.Message}`, true, 'error'));
        dispatch(deleteStatementFailure(error.response.data.Message));
        dispatch(loaderAction(false) as any);
      });
  };
};
