import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Button from '@mui/material/Button';
import { dateFilterStyles } from '../../../shared/Filters/styles';
import DateIcon from '../../../shared/Media/Svg/DateIcon';
import './Export.css';
import { getUniqueYears } from '../helperFunctions';
import { changeDateType, setSelectedWeekRedux, setSelectedYearRedux } from '../../../redux';
import { Typography } from '@mui/material';
import YearWeekSelect from '../../../shared/Filters/DateFilter/YearWeekSelect';

const ExportDate: any = () => {
  const classes = dateFilterStyles();
  const yearWeeks = useAppSelector((store) => store.user.user.YearWeeks);
  const weeks = useAppSelector((store) => store.weeks.weeks);
  const [years] = useState(getUniqueYears(yearWeeks));
  const dispatch = useAppDispatch();
  const show = 'week';

  const [selectedYear, setSelectedYear] = useState(
    yearWeeks ? yearWeeks[0].Year.value : -1,
  );

  const [selectedWeek, setSelectedWeek] = useState(
    yearWeeks ? yearWeeks[0].Week.value : -1,
  );

  useEffect(() => {
    if (years.length > 0) {
      setSelectedYear(years[0].value);
    }
  }, [years]);

  useEffect(() => {
    if (weeks.length > 0) {
      setSelectedWeek(weeks[0].value);
    }
  }, [weeks]);

  useEffect(() => {
    dispatch(setSelectedYearRedux(selectedYear));
  }, [selectedYear]);

  useEffect(() => {
    dispatch(setSelectedWeekRedux(selectedWeek));
  }, [selectedWeek]);

  useEffect(() => {
    dispatch(changeDateType(show));
  }, [show]);

  return (
    <Button
      className={`${classes.datePopover} datePop`}
      variant="contained"
    >
      <DateIcon />
      <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <YearWeekSelect
          data={years}
          selectedData={selectedYear}
          setSelectedData={setSelectedYear}
        />
        <YearWeekSelect
          data={weeks}
          selectedData={selectedWeek}
          setSelectedData={setSelectedWeek}
        />
      </Typography>
    </Button>
  );
};

export default ExportDate;
