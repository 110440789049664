import React from 'react';

const FileAttatchment: React.FC = () => {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00033 3.99984V11.6665C7.00033 13.1398 5.80699 14.3332 4.33366 14.3332C2.86033 14.3332 1.66699 13.1398 1.66699 11.6665V3.33317C1.66699 2.41317 2.41366 1.6665 3.33366 1.6665C4.25366 1.6665 5.00033 2.41317 5.00033 3.33317V10.3332C5.00033 10.6998 4.70033 10.9998 4.33366 10.9998C3.96699 10.9998 3.66699 10.6998 3.66699 10.3332V3.99984H2.66699V10.3332C2.66699 11.2532 3.41366 11.9998 4.33366 11.9998C5.25366 11.9998 6.00033 11.2532 6.00033 10.3332V3.33317C6.00033 1.85984 4.80699 0.666504 3.33366 0.666504C1.86033 0.666504 0.666992 1.85984 0.666992 3.33317V11.6665C0.666992 13.6932 2.30699 15.3332 4.33366 15.3332C6.36033 15.3332 8.00033 13.6932 8.00033 11.6665V3.99984H7.00033Z"
        fill="#001B33"
      />
    </svg>
  );
};

export default FileAttatchment;
