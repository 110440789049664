import Loading from './loading.gif';
import { useStyles } from './styles';
import React from 'react';
interface IMyProps {
  isLoading: boolean;
}

export default function Loader({ isLoading }: IMyProps): JSX.Element {
  const classes = useStyles();

  return (
    <div id="loader" className={isLoading ? classes.loader : classes.fadeOut}>
      <img src={Loading} className={classes.loaderImg} alt="Loading..." />
    </div>
  );
}
