import {
  FETCH_WEEKLY_REVENUES_REQUEST,
  FETCH_WEEKLY_REVENUES_SUCCESS,
  FETCH_WEEKLY_REVENUES_FAILURE,
  FETCH_WEEKLY_REVENUES_COMPARE_REQUEST,
  FETCH_WEEKLY_REVENUES_COMPARE_SUCCESS,
  FETCH_WEEKLY_REVENUES_COMPARE_FAILURE,
} from './weeklyRevenuesActionTypes';
import axios from 'axios';
import { store } from '../../../../app/store';
import { Dispatch } from 'redux';
import { loaderAction } from '../../loader/loaderActions';
import { alertAction } from '../../Alert/alertActions';

interface GridData {
  type: string;
  Id: string;
  YearKey: number | never;
  WeekKey: number | never;
  BiRevlineNm: string | never;
  BiSyscodeTypeNm: string | never;
  MdlGrpNm: string | never;
  ModelType: string | never;
  SumOfJanAmt: number;
  SumOfFebAmt: number;
  SumOfMarAmt: number;
  SumOfAprAmt: number;
  SumOfMayAmt: number;
  SumOfJunAmt: number;
  SumOfJulAmt: number;
  SumOfAugAmt: number;
  SumOfSepAmt: number;
  SumOfOctAmt: number;
  SumOfNovAmt: number;
  SumOfDecAmt: number;
  Total: number;
}

interface CompareGridData {
  differenceBetweenPeriods: GridData;
  firstTimePeriod: GridData;
  secondTimePeriod: GridData;
}

const fetchWeeklyRevenuesRequest: any = () => {
  return {
    type: FETCH_WEEKLY_REVENUES_REQUEST,
  };
};

const fetchWeeklyRevenuesSuccess: any = (gridHeaderData: GridData) => {
  return {
    type: FETCH_WEEKLY_REVENUES_SUCCESS,
    payload: gridHeaderData,
  };
};

const fetchWeeklyRevenuesFailure: any = (error: string) => {
  return {
    type: FETCH_WEEKLY_REVENUES_FAILURE,
    payload: error,
  };
};
const fetchWeeklyRevenuesCompareRequest: any = () => {
  return {
    type: FETCH_WEEKLY_REVENUES_COMPARE_REQUEST,
  };
};

const fetchWeeklyRevenuesCompareSuccess: any = (gridHeaderData: CompareGridData) => {
  return {
    type: FETCH_WEEKLY_REVENUES_COMPARE_SUCCESS,
    payload: gridHeaderData,
  };
};

const fetchWeeklyRevenuesCompareFailure: any = (error: string) => {
  return {
    type: FETCH_WEEKLY_REVENUES_COMPARE_FAILURE,
    payload: error,
  };
};

export const fetchWeeklyRevenues = () => {
  return function (dispatch: Dispatch) {
    const user: any = store.getState().user.user;
    const selectedData: any = store.getState().selectedValue;
    const comapre = selectedData.compare;
    const body: any = {
      email: user.Email,
      isAdmin: user.IsAdmin,
      weekKey: selectedData.week.toString(),
      yearKey: selectedData.year.toString(),
      biRevline: selectedData.revenueType,
      affiliateNames: selectedData.affiliatesArray,
      salesMarkets: selectedData.salesMarketsArray,
    };

    if (comapre) {
      const bodyComapre: any = {
        firstTimePeriod: { ...body },
        secondTimePeriod: { ...body },
      };

      bodyComapre.secondTimePeriod.weekKey = (selectedData.week - 1).toString();
      if (selectedData.dateType === 'month') {
        delete body.weekKey;
        body.monthKey = selectedData.month.toString();
        bodyComapre.firstTimePeriod.month = selectedData.month.toString();
        bodyComapre.secondTimePeriod.month = (selectedData.month - 1).toString();
      } else if (selectedData.dateType === 'quater') {
        delete body.weekKey;
        body.quarterKey = selectedData.quarter.toString();
        bodyComapre.firstTimePeriod.quarter = selectedData.quarter.toString();
        bodyComapre.secondTimePeriod.quarter = (selectedData.quarter - 1).toString();
      }
      dispatch(fetchWeeklyRevenuesRequest());
      dispatch(loaderAction(true) as any);

      axios
        .get(`${process.env.REACT_APP_BACKEND}api/AffiliateWeeklyRevenues`, {
          headers: {
            AffiliatePerformanceFilter: JSON.stringify(body),
            Authorization: 'Bearer ' + user.Token,
          },
        })
        .then((response: any) => {
          const REVENUES = response.data;
          dispatch(fetchWeeklyRevenuesSuccess(REVENUES));
          dispatch(loaderAction(false) as any);
        })
        .catch((error: any) => {
          dispatch(fetchWeeklyRevenuesFailure(error.response.data.Message));
          dispatch(alertAction(`${error.response.data.Message}`, true, 'error') as any);
          dispatch(loaderAction(false) as any);
        });
      dispatch(fetchWeeklyRevenuesCompareRequest());
      dispatch(loaderAction(true) as any);

      axios
        .get(
          `${process.env.REACT_APP_BACKEND}api/AffiliateWeeklyRevenues/GetComparisonOfWeeklyRevenues`,
          {
            headers: {
              AffiliatePerformanceFilter: JSON.stringify(bodyComapre),
              Authorization: 'Bearer ' + user.Token,
            },
          },
        )
        .then((response: any) => {
          const REVENUES = response.data;
          dispatch(fetchWeeklyRevenuesCompareSuccess(REVENUES));
          dispatch(loaderAction(false) as any);
        })
        .catch((error: any) => {
          dispatch(fetchWeeklyRevenuesCompareFailure(error.response.data.Message));
          dispatch(alertAction(`${error.response.data.Message}`, true, 'error') as any);
          dispatch(loaderAction(false) as any);
        });
    } else {
      if (selectedData.dateType === 'month') {
        delete body.weekKey;
        body.monthKey = selectedData.month.toString();
      } else if (selectedData.dateType === 'quater') {
        delete body.weekKey;
        body.quarterKey = selectedData.quarter.toString();
      }
      dispatch(fetchWeeklyRevenuesRequest());
      dispatch(loaderAction(true) as any);

      axios
        .get(`${process.env.REACT_APP_BACKEND}api/AffiliateWeeklyRevenues`, {
          headers: {
            AffiliatePerformanceFilter: JSON.stringify(body),
            Authorization: 'Bearer ' + user.Token,
          },
        })
        .then((response: any) => {
          const REVENUES = response.data;
          dispatch(fetchWeeklyRevenuesSuccess(REVENUES));
          dispatch(loaderAction(false) as any);
        })
        .catch((error: any) => {
          dispatch(fetchWeeklyRevenuesFailure(error.response.data.Message));
          dispatch(alertAction(`${error.response.data.Message}`, true, 'error') as any);
          dispatch(loaderAction(false) as any);
        });
    }
  };
};
