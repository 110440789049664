export const getUniqueYears:any = (yearWeeks: any) => {
  let years: any = [];
  yearWeeks.forEach((obj: any) => {
    years.push(obj.Year.value);
  });
  years = Array.from(new Set(years));
  years = years?.map((x: any) => ({ label: x.toString(), value: x }));
  return years;
};

export const getUniqueWeeks:any = (yearWeeks: any, selectedYear: any) => {
  const weeks: any = [];
  yearWeeks.map((x: any) => {
    if (x.Year === selectedYear) {
      weeks.push({
        label: x.Week,
        value: x.Week,
      });
    }
  });
  return weeks;
};

export const getUniqueAffiliates:any = (affiliates: any) => {
  const uniqueAffiliates: any = [];
  affiliates.map((x: any) => uniqueAffiliates.push(x.AffiliateName));
  return uniqueAffiliates;
};
