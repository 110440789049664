import LoginImage from '../../shared/Media/Images/LoginImage.png';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const loginStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainDiv: {
      [theme.breakpoints.up(2200)]: {
        height: '100%!important',
        display: 'flex',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        height: '72%!important',
        display: 'flex',
      },
      [theme.breakpoints.between(1240, 1448)]: {
        height: '72%!important',
        display: 'flex',
      },
      [theme.breakpoints.between(920, 1240)]: {
        height: '66%!important',
        display: 'flex',
      },
      [theme.breakpoints.between(616, 920)]: {
        height: '66%!important',
        display: 'flex',
      },
      [theme.breakpoints.between(0, 615)]: {
        height: '66%!important',
        display: 'flex',
      },
    },

    loginImage: {
      backgroundImage: `url(${LoginImage})`,
      backgroundPosition: 'center!important',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: 'auto',
      [theme.breakpoints.up(2200)]: {
        width: '77%',
        marginTop: '72px!important',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        width: '77%',
        marginTop: '72px!important',
      },
      [theme.breakpoints.between(1240, 1448)]: {
        width: '67%',
      },
      [theme.breakpoints.between(920, 1240)]: {
        width: '67%',
      },
      [theme.breakpoints.between(616, 920)]: {
        width: '67%',
        height: '84vh',
        display: 'none',
      },
      [theme.breakpoints.between(0, 615)]: {
        display: 'none',
      },
    },

    signUpBox: {
      overflow: 'auto', ////// new css /////////////////////
      [theme.breakpoints.up(2200)]: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '23% !important',
        height: '90vh',
        paddingLeft: '40px',
        paddingRight: '40px',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '23% !important',
        marginTop: '7%!important',
        height: '705px',
        paddingLeft: '40px',
        paddingRight: '40px',
      },
      [theme.breakpoints.between(1240, 1448)]: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '33% !important',
        marginTop: '7%!important',
        height: '705px',
        paddingLeft: '40px',
        paddingRight: '40px',
      },
      [theme.breakpoints.between(920, 1240)]: {
        display: 'flex',
        flexDirection: 'column',
        width: '33% !important',
        marginTop: '17vh!important',
        height: '67vh',
        paddingLeft: '40px',
        paddingRight: '40px',
      },
      [theme.breakpoints.between(616, 920)]: {
        display: 'flex',
        flexDirection: 'column',
        width: '100% !important',
        marginTop: '9vh!important',
        height: '68vh',
        padding: '48px 104px 56px !important',
      },
      [theme.breakpoints.between(700, 850)]: {
        display: 'flex',
        flexDirection: 'column',
        width: '100% !important',
        marginTop: '20vh!important',
        height: '54vh',
        padding: '48px 104px 48px !important',
      },
      [theme.breakpoints.between(0, 615)]: {
        display: 'flex',
        flexDirection: 'column',
        width: '100% !important',
        marginTop: '9vh!important',
        height: '62%',
        padding: '48px 24px 56px !important',
        overflow: 'hidden',
      },
    },

    // =======================================CONTENT=========================================

    signInTitle: {
      height: '48px',
      fontWeight: 350,
      fontSize: '39.81px!important',
      lineHeight: '48px!important',
      marginBottom: '16px!important',
      color: '#001B33',
    },
    username: {
      marginBottom: '24px!important',
    },
    password: {
      marginBottom: '23px!important',
    },
    toggleIcon: {
      cursor: 'pointer',
      color: '#001B33',
      height: '24px',
    },
    signInButtonStack: {
      marginBottom: '24px!important',
    },
    signInButton: {
      padding: '16px 24px !important',
      textTransform: 'capitalize !important' as any,
      width: '100%',
      borderRadius: '40px!important',
      backgroundColor: '#007DB3!important',
    },
    rememberMe: {
      height: '20px',
      marginBottom: '40px!important',
      color: 'rgba(0, 27, 51, 0.8) !important',
    },
    rememberMeCheckBox: {
      color: '#0099D8!important',
    },
    resetPassword: {
      height: '56px',
      borderTop: '1px solid #CCD1D6',
      paddingTop: '16px',
      marginBottom: '40px!important',
    },
    resetPasswordLink: {
      color: '#007DB3',
    },
  }),
);
