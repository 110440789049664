import {
  FETCH_SALES_MARKETS_REQUEST,
  FETCH_SALES_MARKETS_SUCCESS,
  FETCH_SALES_MARKETS_FAILURE,
} from './salesMarketsActionTypes';
import axios from 'axios';
import { store } from '../../../../app/store';
import { Dispatch } from 'redux';

type SalesMarketDTO = {
  label: string;
  value: string;
};

const fetchSalesMarketsRequest = ():any => {
  return {
    type: FETCH_SALES_MARKETS_REQUEST,
  };
};

const fetchSalesMarketsSuccess = (SALES_MARKETS: SalesMarketDTO):any => {
  return {
    type: FETCH_SALES_MARKETS_SUCCESS,
    payload: SALES_MARKETS,
  };
};

const fetchSalesMarketsFailure = (error: string):any => {
  return {
    type: FETCH_SALES_MARKETS_FAILURE,
    payload: error,
  };
};

export const getSalesMarkets = (affiliates: string[]):any => {
  const searchParams = new URLSearchParams(
    affiliates.map((s: string) => ['affiliates', s]),
  );
  searchParams.toString();
  return function (dispatch: Dispatch) {
    const token = store.getState().user.token;
    dispatch(fetchSalesMarketsRequest());
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND}api/AffiliateSalesMarkets/GetSalesMarket?${searchParams}
          `,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const SALES_MARKETS: SalesMarketDTO = response.data;
        dispatch(fetchSalesMarketsSuccess(SALES_MARKETS));
      })
      .catch((error) => {
        dispatch(fetchSalesMarketsFailure(error.response.data.Message));
      });
  };
};
