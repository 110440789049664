export const SET_SELECTED_YEAR = 'SET_SELECTED_YEAR';
export const SET_SELECTED_WEEK = 'SET_SELECTED_WEEK';
export const SET_SELECTED_MONTH = 'SET_SELECTED_MONTH';
export const SET_SELECTED_QUARTER = 'SET_SELECTED_QUARTER';
export const SET_WEEKS = 'SET_SELECTED_WEEK';
export const SET_SELECTED_AFFILIATES = 'SET_SELECTED_AFFILIATES';
export const SET_SELECTED_REVENUE_TYPE = 'SET_SELECTED_REVENUE_TYPE';
export const SET_SELECTED_SALES_MARKETS = 'SET_SELECTED_SALES_MARKETS';
export const SET_CHANGED = 'SET_CHANGED';
export const SET_COMPARE = 'SET_COMPARE';
export const SET_DATE_TYPE = 'SET_DATE_TYPE';
