import * as React from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { alertAction } from '../../redux';

export interface State extends SnackbarOrigin {
  open: boolean;
}


export default function PositionedSnackbar(): JSX.Element {
  const [state] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal } = state;
  const dispatch = useAppDispatch();
  const showAlert = useAppSelector((store:any) => store.alert.show);
  const message = useAppSelector((store:any) => store.alert.message);
  const type = useAppSelector((store:any) => store.alert.type);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = ():void => {
    dispatch(alertAction('Closing Notification', false, type));
  };

  return (
    <div>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        open={showAlert}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
