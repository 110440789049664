import { SHOW_LOADER, HIDE_LOADER } from '../../actions/loader/loaderActionTypes';
const initialState = {
  loading: true,
};
interface Loader {
  payload: any;
  type: string;
}

const loaderReducer = (state = initialState, action: Loader):any => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default loaderReducer;
