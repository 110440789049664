import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';

import { statementStyles } from './styles';


import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { useMediaQuery, useTheme } from '@mui/material';
import File from '../../shared/Media/Svg/File';
import FileAttatchment from '../../shared/Media/Svg/FileAttatchment';


import { getStatements } from '../../redux';
import '../Admin/Pagination.css';
import cloneDeep from 'lodash/cloneDeep';
import 'rc-pagination/assets/index.css';
import UploadStatement from '../../shared/Modals/UploadStatement/UploadStatement';
import DeleteStatementModal from '../../shared/Modals/DeleteStatement/DeleteStatementModal';
import SearchBar from '../../shared/Search/Search';
import Paginations from '../../shared/Pagination/Pagination';

interface CollectionStatements {
  Id: number;
  AffiliateName: string;
  DocumentFileId: string;
  FileName: string;
  Month: string;
  Year: number;
}

export default function Statement(): any {
  const countPerPage = 10;

  const classes = statementStyles();

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(920));

  const statements: any = useAppSelector((state: any) => state.statements.response);
  const dispatch: any = useAppDispatch();

  const fetched: boolean = useAppSelector((store: any) => store.statements.fetched);
  const isAdmin = useAppSelector((store: any) => store.user.user.IsAdmin);
  const [value, setValue] = React.useState('');

  const [currentPage, setCurrentPage] = React.useState(1);
  const [collection, setCollection] = React.useState(cloneDeep(cloneDeep(statements.slice(0, countPerPage))));
  const [filteredData, setFilteredData] = useState(statements);
  let statementCouter = 0;

  useEffect(() => {
    if (statementCouter === 0) {
      statementCouter = statementCouter + 1;
      dispatch(getStatements());
    }
  }, [statementCouter]);

  useEffect(() => {
    setCollection(statements.slice(0, countPerPage));
    setFilteredData(statements);
    setCurrentPage(1);
  }, [fetched]);

  const searchData2: any = () => {
    let filteredStatements;
    if (value && value.length > 0) {
      filteredStatements = statements.filter(
        (row: CollectionStatements) =>
          row.FileName.toLowerCase().includes(value.toLowerCase()) ||
          row.AffiliateName.toLowerCase().includes(value.toLowerCase()) ||
          row.Year.toString().includes(value.toLowerCase()) ||
          row.Month.toLowerCase().includes(value.toLowerCase()),
      );
    } else {
      filteredStatements = statements;
    }
    setFilteredData(filteredStatements);
    const from = (currentPage - 1) * countPerPage;
    const to = from + countPerPage;
    setCollection(filteredStatements.slice(from, to));
  };

  React.useEffect(() => {
    searchData2();
  }, [value, currentPage]);
  
  const updatePage: any = (p: number) => {
    if (filteredData.length >= 10) {
      setCurrentPage(p);
      const to = countPerPage * p;
      const from = to - countPerPage;
      setCollection(cloneDeep(filteredData.slice(from, to)));
    }
  };

  const downloadStatement: any = (row: any) => {
    const id = row.DocumentFileId.split('?id=')[1].split("'>")[0];
    window.location.href = `${process.env.REACT_APP_BACKEND}api/Statements/DownloadDocAsync?id=${id}`;
  };

  const tableData: any = () => {
    return (
      collection &&
      collection.map((row: CollectionStatements, index: number) => {
        return (
          <Typography component="div" className={classes.tableBody} key={index}>
            <Typography component="div" className={classes.reportDateBody}>
              {row.Month}, {row.Year}
            </Typography>
            <Typography component="div" className={classes.affiliateBody}>
              {row.AffiliateName === '' ? row.FileName.split('_')[2] : row.AffiliateName}
            </Typography>
            <Typography component="div" className={classes.reportBody}>
              <Typography component="div">
                {row.FileName.split('_').splice(2).join('')}
              </Typography>
              <Typography component="div" className={classes.fileName}>
                <FileAttatchment /> &nbsp; {row.FileName}
              </Typography>
            </Typography>
            <Button
              variant="contained"
              className={classes.downloadReport}
              onClick={() => downloadStatement(row)}
            >
              Download Report
            </Button>
            {isAdmin ? (
              <DeleteStatementModal row={row} />
            ) : (
              ''
            )}
          </Typography>
        );
      })
    );
  };

  const tableData2: any = () => {
    return (
      collection &&
      collection.map((row: CollectionStatements, index: number) => {
        return (
          <Typography component="div" className={classes.box} key={index}>
            <Typography component="div" className={classes.yearMonthAffMob}>
              <Typography component="div" className={classes.yearMonth}>
                {row.Month}, {row.Year}
              </Typography>
              <Typography component="div" className={classes.AffName}>
                {row.AffiliateName === '' ? row.FileName.split('_')[2] : row.AffiliateName}
              </Typography>
            </Typography>
            <Typography component="div" className={classes.financalStatement}>
              {row.FileName.split('_').splice(2).join('')}
            </Typography>
            <Typography component="div" className={classes.fileNameMob}>
              <File /> &nbsp; &nbsp;{row.FileName}
            </Typography>
            <Button
              variant="contained"
              className={classes.downloadReport}
              onClick={() => downloadStatement(row)}
            >
              Download Report
            </Button>
            {isAdmin ? (
              <div className={classes.deleteModal}>
                <DeleteStatementModal row={row} />
              </div>
            ) : (
              ''
            )}
          </Typography>
        );
      })
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
    setCurrentPage(1);
  };

 
  return (
    <Typography component="div" className={classes.mainDiv}>
      <Typography component="div" className={classes.titleDiv}>
        <Typography component="div" className={classes.title}>
          Statements
        </Typography>
        {isAdmin ? <UploadStatement /> : ''}
      </Typography>

      <SearchBar values={value} onChange={handleChange}/>

      {!isMatch ? (
        <div>
          <Typography component="div" className={classes.tableHead}>
            <Typography component="div" className={classes.reportDateHead}>
              Report date
            </Typography>
            <Typography component="div" className={classes.affiliateHead}>
              Affiliate
            </Typography>
            <Typography component="div" className={classes.reportHead}>
              Report
            </Typography>
          </Typography>
          <table className={classes.table}>
            <tbody>{tableData()}</tbody>
          </table>
        </div>
      ) : (
        <table className={classes.table}>
          <tbody>{tableData2()}</tbody>
        </table>
      )}

     
      <Paginations 
          updatePage={updatePage} 
          updatePage1={()=>updatePage(1)} 
          currentPage={currentPage} 
          countPerPage={countPerPage} 
          filteredData={filteredData} 
          updatePage2={() =>
            updatePage(Math.floor((filteredData.length / 10) % 10 === 0 ? filteredData.length / 10 : filteredData.length / 10 + 1),
            )}
        />
    </Typography>
  );
}
