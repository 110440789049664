import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainDiv: {
      overflow: 'auto', ////// new css /////////////////////
      [theme.breakpoints.up(2200)]: {
        height: '86vh',
        backgroundColor: '#FFFFFF',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '72px 200px 0px',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        height: '76.4vh',
        backgroundColor: '#FFFFFF',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '72px 200px 0px',
      },
      [theme.breakpoints.between(1240, 1447)]: {
        height: '73.4vh',
        backgroundColor: '#FFFFFF',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '72px 200px 0px',
      },
      [theme.breakpoints.between(920, 1240)]: {
        height: '75.5vh',
        backgroundColor: '#ffffff',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '48px 40px 0px',
      },
      [theme.breakpoints.between(616, 920)]: {
        height: '78.5vh',
        backgroundColor: '#FFFFFF',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '47px 32px 0px',
      },
      [theme.breakpoints.between(0, 615)]: {
        height: '733px',
        backgroundColor: '#FFFFFF',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '70px 10px 0px',
      },
    },

    contentDiv: {
      [theme.breakpoints.up(2200)]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        height: '50%',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        display: 'flex',
        width: '1032px',
        height: '301px',
      },
      [theme.breakpoints.between(1240, 1447)]: {
        display: 'flex',
        width: '840px',
        height: '313px',
      },
      [theme.breakpoints.between(920, 1240)]: {
        display: 'flex',
        width: '840px',
        height: '301px',
      },
      [theme.breakpoints.between(616, 920)]: {
        display: 'inline-block',
        width: '480px',
        height: '533px',
        padding: '0px 72px',
      },
      [theme.breakpoints.between(0, 615)]: {
        display: 'inline-block',
        width: '329px',
        height: '613px',
      },
    },

    resetDiv: {
      fontFamily: 'sans-serif!important',
      [theme.breakpoints.up(2200)]: {
        display: 'inline-block',
        width: '950px',
        height: '366px',
        borderRight: '1px solid #E6E9EB',
        marginRight: '20px!important',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        display: 'inline-block',
        width: '682px',
        height: '301px',
        borderRight: '1px solid #E6E9EB',
        marginRight: '20px!important',
      },
      [theme.breakpoints.between(1240, 1447)]: {
        display: 'inline-block',
        width: '552px',
        height: '313px',
        borderRight: '1px solid #E6E9EB',
        marginRight: '20px!important',
      },
      [theme.breakpoints.between(920, 1240)]: {
        display: 'inline-block',
        width: '552px',
        height: '301px',
        borderRight: '1px solid #E6E9EB',
        marginRight: '20px!important',
      },
      [theme.breakpoints.between(616, 920)]: {
        display: 'inline-block',
        width: '100%',
        height: '281px',
        marginBottom: '20px!important',
      },
      [theme.breakpoints.between(0, 615)]: {
        display: 'inline-block',
        width: '100%',
        height: '317px',
        marginBottom: '20px!important',
      },
    },

    resetHelpDiv: {
      fontFamily: 'sans-serif!important',
      [theme.breakpoints.up(2200)]: {
        display: 'inline-block',
        width: '326px',
        height: '351px',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        display: 'inline-block',
        width: '326px',
        height: '301px',
      },
      [theme.breakpoints.between(1240, 1447)]: {
        display: 'inline-block',
        width: '264px',
        height: '313px',
      },
      [theme.breakpoints.between(920, 1240)]: {
        display: 'inline-block',
        width: '264px',
        height: '301px',
      },
      [theme.breakpoints.between(616, 920)]: {
        display: 'inline-block',
        width: '100%',
        height: '225px',
        borderTop: '1px solid #E6E9EB',
        marginTop: '8% !important',
        paddingTop: '24px',
      },
      [theme.breakpoints.between(0, 615)]: {
        display: 'inline-block',
        width: '100%',
        height: '272px',
        borderTop: '1px solid #E6E9EB',
        marginTop: '9% !important',
        paddingTop: '15px',
      },
    },

    // ========================================contentCSS===========================

    returnToSignIn: {
      width: '186px',
      height: '16px',
      fontSize: '11.33px !important',
      lineHeight: '16px!important',
      color: '#001B33!important',
      textDecoration: 'none!important',
      [theme.breakpoints.up(2200)]: {
        fontSize: '20px!important',
      },
    },
    resetHeading: {
      height: '48px',
      width: '79.2%',
      fontSize: '2.3em!important',
      margin: '18px 0 16px 0!important',
      [theme.breakpoints.up(2200)]: {
        fontSize: '49px!important',
        margin: '28px 0 26px 0!important',
      },
      [theme.breakpoints.between(1240, 1447)]: {
        margin: '24px 0 16px 0!important',
        width: '90.2%',
      },
      [theme.breakpoints.between(0, 615)]: {
        margin: '18px 0 20px 0!important',
        width: '100.2%',
      },

      color: '#001B33!important',
    },
    snack: {
      padding: '8px',
      width: '73.6%',
      backgroundColor: '#E6F5FC',
      fontSize: '13.33px !important',
      display: 'flex',
      color: 'rgba(0, 27, 51, 0.8)',
      borderRadius: '5px',
      [theme.breakpoints.up(2200)]: {
        fontSize: '52px!important',
      },
      [theme.breakpoints.between(1240, 1447)]: {
        width: '90.2%!important',
      },
      [theme.breakpoints.between(616, 920)]: {
        width: '95.2%!important',
      },
      [theme.breakpoints.between(0, 615)]: {
        width: '94%',
      },
    },
    inputField: {
      width: '75.7%!important',
      height: '49px !important',
      margin: '16px 0 22px 0 !important',
      [theme.breakpoints.up(2200)]: {
        margin: '27px 0 33px 0 !important',
      },
      [theme.breakpoints.between(1240, 1447)]: {
        width: '92.7%!important',
      },
      [theme.breakpoints.between(616, 920)]: {
        width: '98.6%!important',
      },
      [theme.breakpoints.between(0, 615)]: {
        width: '99.8%!important',
      },
    },
    loginButton: {
      backgroundColor: '#007DB3 !important',
      color: '#FFFFFF',
      padding: '16px 20px!important',
      border: '1px solid white',
      borderRadius: '40px!important',
      cursor: 'pointer',
      width: '176px',
      height: '56px',
      opacity: ' 0.9',
      textTransform: 'capitalize !important' as any,
      '&:hover': {
        opacity: 1,
      },
      [theme.breakpoints.up(2200)]: {
        width: '206px',
        marginTop: '10px!important',
      },
      marginBottom: '27px!important',
      [theme.breakpoints.between(920, 1240)]: {
        width: '32%',
        padding: '16px 24px!important',
      },
      [theme.breakpoints.between(616, 920)]: {
        width: '100%',
        padding: '16px 24px!important',
        marginTop: '5px!important',
      },
      [theme.breakpoints.between(0, 615)]: {
        width: '99.8%!important',
      },
    },
    helpTitle: {
      marginBottom: '16px!important',
      fontSize: '19.2px!important',
      fontWeight: '350!important' as any,
      color: '#001B33!important',
      [theme.breakpoints.up(2200)]: {
        fontSize: '29.2px!important',
      },
    },
    helpContext: {
      marginBottom: '16px!important',
      color: 'rgba(0, 27, 51, 0.8)',
      [theme.breakpoints.up(2200)]: {
        fontSize: '19.2px!important',
      },
    },
    helpLinks: {
      color: '#007DB3!important',
      textDecorationColor: '#007DB3!important',
      cursor: 'pointer',
    },
    linkSupport: {
      color: 'red!important',
      textDecorationColor: 'red!important',
      cursor: 'pointer',
    },
    validationError: {
      marginBottom: '10px!important',
      [theme.breakpoints.up(920)]: {
        width: '77%!important',
      },
    },
  }),
);

export { useStyles };
