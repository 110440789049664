import React from 'react';
import 'rc-pagination/assets/index.css';
import { adminStyles } from '../../components/Admin/styles';
import Typography from '@mui/material/Typography';



const SearchBar: any = ({ values, onChange }:any) => {
  const classes: any = adminStyles();
  return (
    <Typography component="div" className={classes.searchDiv}>
    <input
      placeholder="Search by keywords ..."
      value={values}
      className={classes.paper}
      onChange={onChange}
    />
  </Typography>
  );
};
export default SearchBar;
