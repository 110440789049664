import { Routes, Route, useNavigate } from 'react-router-dom';
import {
  Login,
  ResetPassword,
  Support,
  ConfirmPage,
  Performance,
  Admin,
  Statement,
  ChangePassword,
} from '../components';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import NotFound from '../NotFound';

import React, { useEffect } from 'react';

import secureLocalStorage from 'react-secure-storage';
import { fetchUser, loaderAction } from '../redux';

const Routing: any = () => {
  const isLoggedIn = useAppSelector((store) => store.user.isLoggedIn);
  const isNewPassword = useAppSelector((store) => store.user.user.IsNewPassword);
  const username = 'username';
  const passwords = 'password';
  useNavigate();
  const sessionUsername = secureLocalStorage.getItem(username)?.toString();
  const sessionPassword = secureLocalStorage.getItem(passwords)?.toString();
  const dispatch = useAppDispatch();
  let userCounter = 0;

  useEffect(() => {
    if (
      !isLoggedIn &&
      sessionUsername &&
      sessionUsername.length > 0 &&
      sessionPassword &&
      sessionPassword.length > 0 &&
      userCounter === 0
    ) {
      userCounter = userCounter + 1;
      dispatch(
        fetchUser({
          userName: sessionUsername,
          password: sessionPassword,
        }),
      );
    } else {
      dispatch(loaderAction(false));
    }
  }, [sessionUsername, sessionPassword]);

  const getProtectedRoute = (Component: React.FC): React.ReactNode => {
    return !isLoggedIn ? <Login /> : isNewPassword ? <ChangePassword /> : <Component />;
  };

  return (
    <Routes>
      <Route path="/" element={getProtectedRoute(Performance)} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/support" element={<Support />} />
      <Route path="/confirmPage" element={<ConfirmPage />} />
      <Route path="/changePassword" element={<ChangePassword />} />
      <Route path="/performance" element={getProtectedRoute(Performance)} />
      <Route path="/admin" element={getProtectedRoute(Admin)} />
      <Route path="/statement" element={getProtectedRoute(Statement)} />
      <Route path="*" element={isNewPassword ? <ChangePassword /> : <NotFound />} />
    </Routes>
  );
};
export default Routing;
