/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
// import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';

import { Typography } from '@mui/material';
import { graphStyles } from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface GridData {
  type: string;
  Id: string;
  YearKey: number | never;
  WeekKey: number | never;
  BiRevlineNm: string | never;
  BiSyscodeTypeNm: string | never;
  MdlGrpNm: string | never;
  ModelType: string | never;
  SumOfJanAmt: number;
  SumOfFebAmt: number;
  SumOfMarAmt: number;
  SumOfAprAmt: number;
  SumOfMayAmt: number;
  SumOfJunAmt: number;
  SumOfJulAmt: number;
  SumOfAugAmt: number;
  SumOfSepAmt: number;
  SumOfOctAmt: number;
  SumOfNovAmt: number;
  SumOfDecAmt: number;
  Total: number;
}

interface GraphType {
  gridData: GridData;
  gridData2: GridData;
  keys: any;
}

const Graph: any = ({ gridData, gridData2, keys, compareFor }: GraphType | never | any) => {
  const classes = graphStyles();
  const [header, setHeader] = useState([]);
  console.log('gridData', gridData);
  console.log('gridData2', gridData2);
  console.log('compareFor ', compareFor);
  

  useEffect(() => {
    keys = keys && keys.filter((x: any) => x.toString() !== 'type');
    setHeader(keys);
  }, [keys]);

  const headers = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];



  const labels = headers.map((x) => x);
  const graphVal: any = [];
  const graphVal2: any = [];

  const mappedData = gridData && gridData.map((x: any) => {
    let mappedHeaders;
    if (gridData.length === 4) {
      if (x.type === 'Current Total Bookings' || x.type === compareFor) {
        mappedHeaders = header && header.map((key: any) => {
          if (x.type === 'Current Total Bookings' || x.type === compareFor) {
            if (x.type === 'Current Total Bookings') {
              graphVal.push(x[key]);
            } else {
              graphVal2.push(x[key]);
            }
          }
        });
      }
    } else {
      if (x.type === 'Payment Subtotal' || x.type === compareFor) {
        mappedHeaders = header && header.map((key: any) => {
          if (x.type === 'Payment Subtotal' || x.type === compareFor) {
            if (x.type === 'Payment Subtotal') {
              graphVal.push(x[key]);
            } else {
              graphVal2.push(x[key]);
            }
          }
        });
      }
    }
    
    if (mappedHeaders) {
      return {
        ...x,
        headers: mappedHeaders,
      };
    }
    return x;
});
  


  graphVal.pop();
  graphVal2.pop();
 

  const positiveArray = [];
  const positiveArray2 = [];


for (let i = 0; i < graphVal.length; i++) {
  positiveArray.push(Math.abs(graphVal[i]));
}
for (let i = 0; i < graphVal2.length; i++) {
  positiveArray2.push(Math.abs(graphVal2[i]));
}

console.log('mappedData', mappedData);

  const data: any = {
    labels,
    datasets: [
      {
        label: compareFor,
        data: positiveArray2,
        borderColor: '#0099D8',
        backgroundColor: '#0099D8',
        yAxisID: 'y',
        tension: 0.4,
      },
      {
        label: gridData[0]?.type === 'Current Total Bookings' ? 'Current Total Bookings' : 'Payment Subtotal',
        data: positiveArray,
        borderColor: '#2300AF',
        backgroundColor: '#2300AF',
        yAxisID: 'y',
        tension: 0.4,
      },
    ],
  };



  const options2: any = {
    tension: 0.4,
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
      },
    }, 
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <Typography component="div" className={classes.marketAndPayments}>
      <Typography component="h1" className={classes.PerformanceBudgetTitle}>
        Performance to Budget
      </Typography>
      <Typography component="p" className={classes.PerformanceBudgetSubTitle}>
        In Dollars
      </Typography>
      {/* <Line options={options2} data={data} className={classes.graphs} /> */}
      <Bar options={options2} data={data} className={classes.graphs}/>
    </Typography>
    
  );
};

export default Graph;
