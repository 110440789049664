import { combineReducers } from 'redux';
import supportReducer from '../reducers/support/supportReducer';
import userReducer from '../reducers/user/userReducer';
import weeksReducer from '../reducers/performance/weeksReducer';
import adminAffiliatesReducer from '../reducers/adminAffiliates/adminAffiliatesReducer';
import addAffiliateReducer from '../reducers/adminAffiliates/addAffiliateReducer';
import editAffiliateReducer from '../reducers/adminAffiliates/editAffiliateReducer';
import deleteAffiliateReducer from '../reducers/adminAffiliates/deleteAffiliateReducer';
import revenueByReducer from '../reducers/performance/revenueByReducer';
import selectedValueReducer from '../reducers/performance/selectedValuesReducer';
import weeklyRevenuesReducer from '../reducers/performance/weeklyRevenuesReducer';
import alertReducer from '../reducers/alert/alertReducer';
import exportReducer from '../reducers/export/exportReducer';
import emailReducer from '../reducers/email/emailReducer';
import resetPasswordReducer from '../reducers/resetPassword/resetPasswordReducer';
import forgotPasswordReducer from '../reducers/forgotPassword/forgotPasswordReducer';
import statementsReducer from '../reducers/statements/statementsReducer';
import deleteStatementReducer from '../reducers/statements/deleteStatementReducer';
import uploadStatementReducer from '../reducers/statements/uploadStatementReducer';
import loaderReducer from '../reducers/loader/loaderReducer';
import salesMarketsReducer from '../reducers/performance/salesMarketsReducer';
import { LOGOUT } from './constants';

const appReducer:any = combineReducers({
  user: userReducer,
  support: supportReducer,
  weeks: weeksReducer,
  admin: adminAffiliatesReducer,
  revenueBy: revenueByReducer,
  selectedValue: selectedValueReducer,
  weeklyRevenues: weeklyRevenuesReducer,
  addAffiliate: addAffiliateReducer,
  editAffiliate: editAffiliateReducer,
  deleteAffiliate: deleteAffiliateReducer,
  alert: alertReducer,
  export: exportReducer,
  email: emailReducer,
  resetPassword: resetPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  statements: statementsReducer,
  deleteStatement: deleteStatementReducer,
  uploadStatement: uploadStatementReducer,
  loader: loaderReducer,
  salesMarkets: salesMarketsReducer,
});

const rootReducer = (state: any, action: any):any => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
