import { SHOW_ALERT, HIDE_ALERT } from '../../actions/Alert/alertActionTypes';

const initialState = {
  loading: false,
  message: '',
  show: false,
  type: '',
};
interface Payload {
  AppUserID: number;
  AffiliateName: Array<string>;
  UserName: string;
  Email: string;
  Detail: any;
  message: string;
  type: any;
}
interface AlertAction {
  payload: Payload;
  type: string;
}
const alertReducer = ( state = initialState, action: AlertAction):any => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        loading: true,
        message: action.payload.message,
        show: true,
        type: action.payload.type,
      };
    case HIDE_ALERT:
      return {
        ...state,
        loading: false,
        message: '',
        show: false,
      };
    default:
      return state;
  }
};

export default alertReducer;
