import {
  FETCH_WEEKS_REQUEST,
  FETCH_WEEKS_SUCCESS,
  FETCH_WEEKS_FAILURE,
  FETCH_MONTHS_REQUEST,
  FETCH_MONTHS_SUCCESS,
  FETCH_MONTHS_FAILURE,
  FETCH_QUATER_REQUEST,
  FETCH_QUATER_SUCCESS,
  FETCH_QUATER_FAILURE,
} from './weeksActionTypes';
import axios from 'axios';
import { store } from '../../../../app/store';
import { Dispatch } from 'redux';

const fetchWeeksRequest:any = () => {
  return {
    type: FETCH_WEEKS_REQUEST,
  };
};

const fetchWeeksSuccess:any = (WEEKS: any) => {
  return {
    type: FETCH_WEEKS_SUCCESS,
    payload: WEEKS,
  };
};

const fetchWeeksFailure:any = (error: string) => {
  return {
    type: FETCH_WEEKS_FAILURE,
    payload: error,
  };
};
const fetchMonthsRequest:any = () => {
  return {
    type: FETCH_MONTHS_REQUEST,
  };
};

const fetchMonthsSuccess:any = (WEEKS: any) => {
  return {
    type: FETCH_MONTHS_SUCCESS,
    payload: WEEKS,
  };
};

const fetchMonthsFailure:any = (error: string) => {
  return {
    type: FETCH_MONTHS_FAILURE,
    payload: error,
  };
};
const fetchQuatersRequest:any = () => {
  return {
    type: FETCH_QUATER_REQUEST,
  };
};

const fetchQuatersSuccess:any = (WEEKS: any) => {
  return {
    type: FETCH_QUATER_SUCCESS,
    payload: WEEKS,
  };
};

const fetchQuatersFailure:any = (error: string) => {
  return {
    type: FETCH_QUATER_FAILURE,
    payload: error,
  };
};

export const getWeeks = (selectedYear: any) => {
  return function (dispatch: Dispatch) {
    const token = store.getState().user.token;
    dispatch(fetchWeeksRequest());
    axios({
      method: 'get',
      url: `${
        process.env.REACT_APP_BACKEND
      }api/AffiliateYearWeeks/GetAffiliateWeeks?year=${
        typeof selectedYear === 'number' ? selectedYear : selectedYear.value
      }
          `,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const WEEKS = response.data;
        dispatch(fetchWeeksSuccess(WEEKS));
      })
      .catch((error) => {
        dispatch(fetchWeeksFailure(error.response.data.Message));
      });
  };
};
export const getMonths = (selectedYear: any) => {
  return function (dispatch: Dispatch) {
    const token = store.getState().user.token;
    dispatch(fetchMonthsRequest());
    axios({
      method: 'get',
      url: `${
        process.env.REACT_APP_BACKEND
      }api/AffiliateYearWeeks/GetAffiliateMonths?year=${
        typeof selectedYear === 'number' ? selectedYear : selectedYear.value
      }
          `,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const WEEKS = response.data;
        dispatch(fetchMonthsSuccess(WEEKS));
      })
      .catch((error) => {
        dispatch(fetchMonthsFailure(error.response.data.Message));
      });
  };
};
export const getQuaters = (selectedYear: any) => {
  return function (dispatch: Dispatch) {
    const token = store.getState().user.token;
    dispatch(fetchQuatersRequest());
    axios({
      method: 'get',
      url: `${
        process.env.REACT_APP_BACKEND
      }api/AffiliateYearWeeks/GetAffiliateQuarters?year=${
        typeof selectedYear === 'number' ? selectedYear : selectedYear.value
      }
          `,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const WEEKS = response.data;
        dispatch(fetchQuatersSuccess(WEEKS));
      })
      .catch((error) => {
        dispatch(fetchQuatersFailure(error.response.data.Message));
      });
  };
};
