import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainDiv: {
      [theme.breakpoints.up(2200)]: {
        height: '95vh',
        backgroundColor: '#FFFFFF',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '72px 200px 0px',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        height: '76.4vh',
        backgroundColor: '#FFFFFF',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '72px 200px 0px',
      },
      [theme.breakpoints.between(1240, 1447)]: {
        height: '73.4vh',
        backgroundColor: '#FFFFFF',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '72px 100px 0px',
      },
      [theme.breakpoints.between(920, 1240)]: {
        height: '71.5vh',
        backgroundColor: '#ffffff',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '48px 240px 0px',
      },
      [theme.breakpoints.between(616, 920)]: {
        height: '78.5vh',
        backgroundColor: '#FFFFFF',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '47px 132px 0px',
      },
      [theme.breakpoints.between(0, 615)]: {
        height: '60vh',
        backgroundColor: '#FFFFFF',
        display: 'flex !important',
        flexWrap: 'wrap !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        padding: '70px 20px 0px',
      },
    },
    internalMainDiv: {
      [theme.breakpoints.between(1448, 2200)]: {
        padding: ' 0 246px',
        width: '1032px !important',
      },
      [theme.breakpoints.between(1240, 1447)]: {
        padding: ' 0 206px',
        width: '1032px !important',
      },
    },
    confirmedTitle: {
      fontSize: '36px!important',
      marginBottom: '16px !important',
    },
    confirmedtext: {
      marginBottom: '24px !important',
      [theme.breakpoints.between(1240, 1447)]: {
        marginBottom: '32px !important',
      },
    },
    Button: {
      margin: '0',
      borderRadius: '40px!important',
      padding: '16px 24px !important',
      background: '#007DB3 !important',
      textTransform: 'capitalize !important' as any,
      textDecoration: 'none !important',
    },
    links: {
      textDecoration: 'none !important',
      color: '#ffffff !important',
    },
  }),
);
