import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const performanceStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainDiv: {
      height: 'auto',
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.up(2200)]: {
        padding: '152px 508px 96px 508px',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        padding: '152px 208px 96px 208px',
      },
      [theme.breakpoints.between(1240, 1448)]: {
        padding: '136px 200px 96px !important',
      },
      [theme.breakpoints.between(920, 1240)]: {
        padding: '120px 40px 64px 40px',
      },
      [theme.breakpoints.between(0, 920)]: {
        padding: '122px 24px 64px 24px',
      },
    },
    contentDiv: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    pageTitle: {
      fontWeight: 350,
      fontSize: '39.81px !important',
      lineHeight: '48px !important',
      marginBottom: '16px!important',
    },
    dropdownBox: {
      padding: '16px 24px',
      border: '1px solid #CCD1D6',
      marginBottom: '1.45%!important',
      [theme.breakpoints.between(616, 1240)]: {
        marginBottom: '2.8%!important',
      },
    },
    filtersTitle: {
      fontSize: '19.2px!important',
      color: '#000000!important',
    },
    applyButton: {
      padding: '10px 18px!important',
      backgroundColor: '#007DB3!important',
      textTransform: 'capitalize!important' as any,
      fontSize: '16px !important',
      marginBottom: '8px!important',
      minWidth: '9%',
      width: '9%!important',
      height:'37px!important',
    },
    snack: {
      padding: '8px',
      width: '98%',
      backgroundColor: '#E6F5FC',
      fontSize: '13.33px !important',
      display: 'flex',
      color: '#007DB3',
      borderRadius: '5px',
      fontStyle: 'italic',
    },
    applySpan: {
      fontWeight: '700!important' as any,
    },
    tabPanel: {
      padding: '24px 0 0 0 !important',
    },
    tabList: {
      color: '#001B33 !important',
      fontWeight: '350 !important' as any,
      fontSize: '13.33px !important',
      lineHeight: '16px',
      '& .MuiTabs-indicator': {
        backgroundColor: '#0099D8!important',
      },
      textTransform: 'capitalize !important' as any,
    },
    affiliateSummaryTitle: {
      marginBottom: '18px!important',
      fontSize: '33.18px!important',
      fontWeight: '350 !important' as any,
    },
    totalMarketTitle: {
      fontSize: '20px !important',
      fontWeight: '350 !important' as any,
      color:'#fff !important',
    },
    MainAccordion:{
      marginBottom:'10px',
      borderRadius: '5px 5px 0 0',
    },
    Accordion:{
      height:'46px !important',
      backgroundColor:'rgba(0, 27, 51, 1) !important',
    },
    download: {
      display: 'flex',
      justifyContent: 'center',
      padding: '16px 24px',
      boxShadow: '0px -4px 8px rgba(0, 27, 51, 0.2)',
    },
    downloadButton: {
      fontSize: '16px !important',
      padding: '16px 24px 16px 29px !important',
      background: '#007DB3 !important',
      borderRadius: '40px !important',
      textTransform: 'capitalize !important' as any,
    },
    partnerPaymentTitle: {
      fontSize: '24px !important',
      fontWeight: '700 !important' as any,
      paddingTop: '24px!important',
      paddingBottom: '10px!important',
    },
    revenueType: {
      fontSize: '24px !important',
      fontWeight: '700 !important' as any,
      paddingTop: '12px!important',
      paddingBottom: '12px!important',
    },
    tableContainer: {
      boxShadow: 'none!important',
    },
    graphs: {
      height: '260px!important',
      width: '100%!important',
      [theme.breakpoints.up(4800)]: {
        height: '760px!important',
      },
      [theme.breakpoints.between(2200, 4800)]: {
        height: '560px!important',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        height: '360px!important',
      },
      [theme.breakpoints.between(1240, 1448)]: {
        height: '300px!important',
      },
      [theme.breakpoints.between(920, 1240)]: {
        height: '278px!important',
      },
      [theme.breakpoints.between(0, 616)]: {
        height: '191px!important',
        paddingRight: '16px!important',
      },
    },
    // ====================================================MOB MONTH VALUES=======================
    byMonthMob: {
      padding: '16px !important',
      paddingBottom: '30px !important',
      border: '1px solid #CCD1D6',
      marginTop: '24px!important',
    },
    byMonthMobTitle: {
      fontWeight: 350,
      fontSize: '19.2px!important',
    },
    calendarMonth: {
      fontSize: '23.4px !important',
      color: '#0099D8',
      borderBottom: '1px solid #0099D8',
      marginTop: '3.2%!important',
    },
    monthValues: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      marginTop: '2.5%!important',
    },
    dataTitles: {
      fontSize: '13.33px!important',
    },
    dataValues: {
      fontSize: '23.04px!important',
      paddingBottom: '2px',
      marginBottom: '2px!important',
    },
    monthCurrent: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    monthBudgeted: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    monthPerformance: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    monthPerformancePercent: {
      width: '48%!important',
      marginRight: '2%!important',
      marginBottom: '8px !important',
      borderBottom: '1px solid #E6E9EB',
      [theme.breakpoints.down(616)]: {
        width: '100%!important',
      },
    },
    buttonsByMonthMob: {
      width: '100%',
      marginTop: '24px!important',
    },
    previousButton: {
      display: 'flex',
      borderRadius: '40px!important',
      textTransform: 'capitalize !important' as any,
      borderColor: '#001B33!important',
      color: '#001B33!important',
    },
    nextButton: {
      display: 'flex',
      float: 'right !important' as any,
      borderRadius: '40px!important',
      textTransform: 'capitalize !important' as any,
      borderColor: '#001B33!important',
      color: '#001B33!important',
    },
    exportTitle: {
      padding: '0 16px 16px 16px!important',
      fontSize: '27.65px !important',
      fontWeight: '350 !important' as any,
    },
    exportData: {
      paddingBottom: '0%!important',
    },
    exportDailogue: {
      maxWidth: '312px!important',
      padding: '0 16px 24px 16px!important',
    },
    closeExport: {
      // display:'flex!important',
      justifyContent: 'end!important',
      padding: '12px 12px 0px 12px!important',
    },
    ExportConfirmButton: {
      padding: '10px 18px!important',
      backgroundColor: '#007DB3!important',
      textTransform: 'capitalize!important' as any,
      fontSize: '16px !important',
      marginTop: '16px!important',
      borderRadius: '40px!important',
    },
    exportYearWeeks: {
      display: 'flex!important',
      justifyContent: 'space-between !important',
      flexDirection: 'column!important' as any,
      marginBottom: '1%!important',
    },
    exportActionsButton: {
      padding: '0px 18px 5px 18px!important',
      color: '#007DB3!important',
    },
  }),
);

export const salesMarketFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterMain: {
      // minWidth: '100%',
      width: '100%!important',
      paddingTop: '10px',
      [theme.breakpoints.down(920)]: {
        width: '100%',
        marginLeft: '0px!important',
      },
    },
  }),
);
