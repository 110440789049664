import { makeStyles, createStyles } from '@material-ui/core/styles';

export const spectrumLogoStyles = makeStyles(() =>
  createStyles({
    spectrumLogo: {
      marginRight: '.5em!important',
    },
  }),
);
export const DashboardLogoStyles = makeStyles(() =>
  createStyles({
    AffiliateLogo: {
      paddingLeft: '.5em!important',
      borderLeft: '1px solid #CCD1D6',
    },
  }),
);

export const backIcon = makeStyles(() =>
  createStyles({
    returnToSignInSVG: {
      margin: '1px',
      marginRight: '6px',
    },
  }),
);

export const buttonNext = makeStyles(() =>
  createStyles({
    buttonNext: {
      marginLeft: '9.7px',
    },
  }),
);

export const buttonPrevious = makeStyles(() =>
  createStyles({
    buttonPrevious: {
      marginRight: '9.7px',
    },
  }),
);

export const changePasswordLogo = makeStyles(() =>
  createStyles({
    SVGDropdown: {
      paddingRight: '7px',
    },
  }),
);

export const closeIcon = makeStyles(() =>
  createStyles({
    cancel: {
      width: '20px!important',
      height: '20px!important',
      paddingLeft: '4px',
      cursor: 'pointer',
    },
  }),
);
export const dollar = makeStyles(() =>
  createStyles({
    dollar: {
      // marginRight:'3px',
      marginTop: 'px',
    },
  }),
);

export const download = makeStyles(() =>
  createStyles({
    downloadSVG: {
      paddingRight: '9px',
    },
  }),
);
export const getHelpLogo = makeStyles(() =>
  createStyles({
    SVGDropdown: {
      paddingRight: '7px',
    },
  }),
);
export const helpFooterLogo = makeStyles(() =>
  createStyles({
    help: {
      marginRight: '17.63px',
    },
  }),
);
export const helpLogo = makeStyles(() =>
  createStyles({
    help: {
      marginRight: '17.63px',
      cursor: 'pointer',
    },
  }),
);
export const infoIcon = makeStyles(() =>
  createStyles({
    info: {
      width: '40px!important',
      height: '25px!important',
      paddingRight: '5px',
    },
  }),
);
export const spectrumFooterLogo = makeStyles(() =>
  createStyles({
    cursorPointer: {
      cursor: 'pointer',
    },
  }),
);
export const dateLogo = makeStyles(() =>
  createStyles({
    dateicon: {
      marginRight: '5px!important',
      marginLeft: '0px!important',
      paddingLeft: '0px!important',
    },
  }),
);

export const CloseIconModalsStyles = makeStyles(() =>
  createStyles({
    closeIcon: {
      float: 'right',
    },
  }),
);

export const EditIconStyles = makeStyles(() =>
  createStyles({
    EditIcon: {
      marginRight: '10px',
    },
  }),
);
export const ResetIconStyles = makeStyles(() =>
  createStyles({
    ResetIcon: {
      marginRight: '10px',
    },
  }),
);
export const DeleteIconStyles = makeStyles(() =>
  createStyles({
    DeleteIcon: {
      marginRight: '10px',
    },
  }),
);
