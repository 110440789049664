import {
  FETCH_SUPPORT_REQUEST,
  FETCH_SUPPORT_SUCCESS,
  FETCH_SUPPORT_FAILURE,
} from '../../actions/support/supportActionType';

const initialState = {
  loading: false,
  affiliateArray: [],
  error: '',
  fetched: false,
};

interface Support {
  type: string;
  payload: any;
}

const supportReducer = (state = initialState, action: Support):any => {
  switch (action.type) {
    case FETCH_SUPPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUPPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        affiliateArray: action.payload,
        error: '',
        fetched: true,
      };
    case FETCH_SUPPORT_FAILURE:
      return {
        ...state,
        loading: false,
        affiliateArray: [],
        error: action.payload,
        fetched: false,
      };
    default:
      return state;
  }
};

export default supportReducer;
