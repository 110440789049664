import React from 'react';
import { Typography } from '@mui/material';

import Dollar from '../../../shared/Media/Svg/Dollar';
import Percent from '../../../shared/Media/Svg/Percent';
import { yearStyles } from './styles';
import { useAppSelector } from '../../../app/hooks';
import DownArrow from '../../../shared/Media/Svg/DownArrow';
import UpArrow from '../../../shared/Media/Svg/UpArrow';

interface GridData {
  type: string;
  Id: string;
  YearKey: number | never;
  WeekKey: number | never;
  BiRevlineNm: string | never;
  BiSyscodeTypeNm: string | never;
  MdlGrpNm: string | never;
  ModelType: string | never;
  SumOfJanAmt: number;
  SumOfFebAmt: number;
  SumOfMarAmt: number;
  SumOfAprAmt: number;
  SumOfMayAmt: number;
  SumOfJunAmt: number;
  SumOfJulAmt: number;
  SumOfAugAmt: number;
  SumOfSepAmt: number;
  SumOfOctAmt: number;
  SumOfNovAmt: number;
  SumOfDecAmt: number;
  Total: number;
}

interface YearlyType {
  gridData: GridData;
  gridData2: GridData;
  gridData3: GridData;
  keys: Array<string>;
  keysCompare: Array<string>;
}

const Yearly: any = ({ gridData, gridData2 }: YearlyType | never | any) => {
  const classes = yearStyles();

  const compare = useAppSelector((store: any) => store.selectedValue.compare);

  return (
    <Typography component="div" className={classes.byMonthMob}>
      <Typography component="div" className={classes.byMonthMobTitle}>
        Year Total
      </Typography>
      <Typography component="div" className={classes.monthValues}>
        <Typography component="div" className={classes.monthCurrent}>
          <Typography component="div" className={classes.dataTitles}>
            {gridData && gridData[0].type}
          </Typography>
          <Typography component="div" className={classes.dataValues}>
            <Dollar /> {Math.abs(gridData[0].Total).toLocaleString()}
          </Typography>
          {compare && gridData2 && gridData2.length > 0 ? (
            parseInt(gridData[0].Total) < parseInt(gridData2[0].Total) ? (
              <Typography component="div" className={classes.compareRedValues}>
                {gridData[0].Total - gridData2[0].Total === 0 ? (
                  '-'
                ) : (
                  <>
                    - ${' '}
                    {Math.abs(gridData[0].Total - gridData2[0].Total).toLocaleString()} ({' '}
                    <DownArrow /> &nbsp;
                    {(
                      (Math.abs(gridData[0].Total - gridData2[0].Total) /
                        Math.abs(gridData[0].Total)) *
                      100
                    ).toFixed(2)}{' '}
                    %)
                  </>
                )}
              </Typography>
            ) : (
              <Typography component="div" className={classes.compareValues}>
                {gridData[0].Total - gridData2[0].Total === 0 ? (
                  '-'
                ) : (
                  <>
                    + ${' '}
                    {Math.abs(gridData[0].Total - gridData2[0].Total).toLocaleString()} ({' '}
                    <UpArrow /> &nbsp;
                    {(
                      (Math.abs(gridData[0].Total - gridData2[0].Total) /
                        Math.abs(gridData[0].Total)) *
                      100
                    ).toFixed(2)}{' '}
                    %)
                  </>
                )}
              </Typography>
            )
          ) : (
            ''
          )}
        </Typography>
        <Typography component="div" className={classes.monthBudgeted}>
          <Typography component="div" className={classes.dataTitles}>
            {gridData[1].type}
          </Typography>
          <Typography component="div" className={classes.dataValues}>
            {gridData[1].type === 'Adjustments' ? '(' : ''}
            <Dollar />
            {Math.abs(gridData[1].Total).toLocaleString()}
            {gridData[1].type === 'Adjustments' ? ')' : ''}
          </Typography>
          {compare && gridData2 && gridData2.length > 0 ? (
            parseInt(gridData[1].Total) < parseInt(gridData2[1].Total) ? (
              <Typography component="div" className={classes.compareRedValues}>
                {gridData[1].Total - gridData2[1].Total === 0 ? (
                  '-'
                ) : (
                  <>
                    - (${' '}
                    {Math.abs(gridData[1].Total - gridData2[1].Total).toLocaleString()}) ({' '}
                    <DownArrow /> &nbsp;
                    {(
                      (Math.abs(gridData[1].Total - gridData2[1].Total) /
                        Math.abs(gridData[1].Total)) *
                      100
                    ).toFixed(2)}{' '}
                    %)
                  </>
                )}
              </Typography>
            ) : (
              <Typography component="div" className={classes.compareValues}>
                {gridData[1].Total - gridData2[1].Total === 0 ? (
                  '-'
                ) : (
                  <>
                    + ${' '}
                    {Math.abs(gridData[1].Total - gridData2[1].Total).toLocaleString()} ({' '}
                    <UpArrow /> &nbsp;
                    {(
                      (Math.abs(gridData[1].Total - gridData2[1].Total) /
                        Math.abs(gridData[1].Total)) *
                      100
                    ).toFixed(2)}{' '}
                    %)
                  </>
                )}
              </Typography>
            )
          ) : (
            ''
          )}
        </Typography>
        <Typography component="div" className={classes.monthPerformance}>
          <Typography component="div" className={classes.dataTitles}>
            {gridData[2].type}
          </Typography>
          <Typography component="div" className={classes.dataValues}>
            <Dollar />
            {Math.abs(gridData[2].Total).toLocaleString()}
          </Typography>
          {compare && gridData2 && gridData2.length > 0 ? (
            parseInt(gridData[2].Total) < parseInt(gridData2[2].Total) ? (
              <Typography component="div" className={classes.compareRedValues}>
                {gridData[2].Total - gridData2[2].Total === 0 ? (
                  '-'
                ) : (
                  <>
                    - ${' '}
                    {Math.abs(gridData[2].Total - gridData2[2].Total).toLocaleString()} ({' '}
                    <DownArrow /> &nbsp;
                    {(
                      (Math.abs(gridData[2].Total - gridData2[2].Total) /
                        Math.abs(gridData[2].Total)) *
                      100
                    ).toFixed(2)}{' '}
                    %)
                  </>
                )}
              </Typography>
            ) : (
              <Typography component="div" className={classes.compareValues}>
                {gridData[2].Total - gridData2[2].Total === 0 ? (
                  '-'
                ) : (
                  <>
                    + ${' '}
                    {Math.abs(gridData[2].Total - gridData2[2].Total).toLocaleString()} ({' '}
                    <UpArrow /> &nbsp;
                    {(
                      (Math.abs(gridData[2].Total - gridData2[2].Total) /
                        Math.abs(gridData[2].Total)) *
                      100
                    ).toFixed(2)}{' '}
                    %)
                  </>
                )}
              </Typography>
            )
          ) : (
            ''
          )}
        </Typography>
        <Typography component="div" className={classes.monthPerformancePercent}>
          <Typography component="div" className={classes.dataTitles}>
            {gridData[3].type}
          </Typography>
          {gridData.length === 4 ? (
            <>
              <Typography component="div" className={classes.dataValues}>
                {gridData[3].Total.split('%')[0]}
                <Percent />
              </Typography>
              {compare && gridData2 && gridData2.length > 0 ? (
                parseInt(gridData[2].Total) < parseInt(gridData2[2].Total) ? (
                  <Typography component="div" className={classes.compareRedValues}>
                    {gridData[2].Total - gridData2[2].Total === 0 ? (
                      '-'
                    ) : (
                      <>
                        - ${' '}
                        {Math.abs(gridData[2].Total - gridData2[2].Total).toLocaleString()}{' '}
                        ( <DownArrow /> &nbsp;
                        {(
                          (Math.abs(gridData[2].Total - gridData2[2].Total) /
                            Math.abs(gridData[2].Total)) *
                          100
                        ).toFixed(2)}{' '}
                        %)
                      </>
                    )}
                  </Typography>
                ) : (
                  <Typography component="div" className={classes.compareValues}>
                    {gridData[2].Total - gridData2[2].Total === 0 ? (
                      '-'
                    ) : (
                      <>
                        + ${' '}
                        {Math.abs(gridData[2].Total - gridData2[2].Total).toLocaleString()}{' '}
                        ( <UpArrow /> &nbsp;
                        {(
                          (Math.abs(gridData[2].Total - gridData2[2].Total) /
                            Math.abs(gridData[2].Total)) *
                          100
                        ).toFixed(2)}{' '}
                        %)
                      </>
                    )}
                  </Typography>
                )
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              <Typography component="div" className={classes.dataValues}>
                <Dollar />
                {Math.abs(gridData[3].Total).toLocaleString()}
              </Typography>
              {compare && gridData2 && gridData2.length > 0 ? (
                parseInt(gridData[4].Total) < parseInt(gridData2[4].Total) ? (
                  <Typography component="div" className={classes.compareRedValues}>
                    {gridData[3].Total - gridData2[3].Total === 0 ? (
                      '-'
                    ) : (
                      <>
                        - ${' '}
                        {Math.abs(gridData[3].Total - gridData2[3].Total).toLocaleString()}{' '}
                        ( <DownArrow /> &nbsp;
                        {(
                          (Math.abs(gridData[3].Total - gridData2[3].Total) /
                            Math.abs(gridData[3].Total)) *
                          100
                        ).toFixed(2)}{' '}
                        %)
                      </>
                    )}
                  </Typography>
                ) : (
                  <Typography component="div" className={classes.compareValues}>
                    {gridData[3].Total - gridData2[3].Total === 0 ? (
                      '-'
                    ) : (
                      <>
                        + ${' '}
                        {Math.abs(gridData[3].Total - gridData2[3].Total).toLocaleString()}{' '}
                        ( <UpArrow /> &nbsp;
                        {(
                          (Math.abs(gridData[3].Total - gridData2[3].Total) /
                            Math.abs(gridData[3].Total)) *
                          100
                        ).toFixed(2)}{' '}
                        %)
                      </>
                    )}
                  </Typography>
                )
              ) : (
                ''
              )}
            </>
          )}
        </Typography>
        {gridData.length === 5 ? (
          <Typography component="div" className={classes.monthPerformancePercent}>
            <Typography component="div" className={classes.dataTitles}>
              {gridData[4].type}
            </Typography>
            <Typography component="div" className={classes.dataValues}>
              <>
                <Dollar /> {Math.abs(gridData && gridData[4].Total).toLocaleString()}
              </>
            </Typography>
            {compare && gridData2 && gridData2.length > 0 ? (
              parseInt(gridData[4].Total) < parseInt(gridData2[4].Total) ? (
                <Typography component="div" className={classes.compareRedValues}>
                  {gridData[4].Total - gridData2[4].Total === 0 ? (
                    '-'
                  ) : (
                    <>
                      - ${' '}
                      {Math.abs(gridData[4].Total - gridData2[4].Total).toLocaleString()}{' '}
                      ( <DownArrow /> &nbsp;
                      {(
                        (Math.abs(gridData[4].Total - gridData2[4].Total) /
                          Math.abs(gridData[4].Total)) *
                        100
                      ).toFixed(2)}{' '}
                      %)
                    </>
                  )}
                </Typography>
              ) : (
                <Typography component="div" className={classes.compareValues}>
                  {gridData[4].Total - gridData2[4].Total === 0 ? (
                    '-'
                  ) : (
                    <>
                      + ${' '}
                      {Math.abs(gridData[4].Total - gridData2[4].Total).toLocaleString()}{' '}
                      ( <UpArrow /> &nbsp;
                      {(
                        (Math.abs(gridData[4].Total - gridData2[4].Total) /
                          Math.abs(gridData[4].Total)) *
                        100
                      ).toFixed(2)}{' '}
                      %)
                    </>
                  )}
                </Typography>
              )
            ) : (
              ''
            )}
          </Typography>
        ) : (
          ''
        )}
      </Typography>
    </Typography>
  );
};

export default Yearly;
