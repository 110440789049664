import {
  FETCH_STATEMENT_REQUEST,
  FETCH_STATEMENT_SUCCESS,
  FETCH_STATEMENT_FAILURE,
} from '../../actions/statements/statementActionTypes';

const initialState = {
  loading: false,
  response: '',
  error: '',
  fetched: false,
};
interface Payload {
  Id: number;
  AffiliateName: string;
  DocumentFieldId: string;
  FileName: string;
  Month: string;
  Year: number;
}
interface Statements {
  payload: Payload;
  type: string;
}

const statementsReducer = (state = initialState, action: Statements ):any => {
  switch (action.type) {
    case FETCH_STATEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      };
    case FETCH_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: '',
        fetched: true,
      };
    case FETCH_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        response: '',
        error: action.payload,
        fetched: false,
      };
    default:
      return state;
  }
};

export default statementsReducer;
