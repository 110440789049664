import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Calendar from '../../../shared/Media/Svg/Calendar';
import ButtonPrevious from '../../../shared/Media/Svg/ButtonPrevious';
import ButtonNext from '../../../shared/Media/Svg/ButtonNext';
import Dollar from '../../../shared/Media/Svg/Dollar';
import Percent from '../../../shared/Media/Svg/Percent';
import { monthStyles } from './styles';
import { useAppSelector } from '../../../app/hooks';
import DownArrow from '../../../shared/Media/Svg/DownArrow';
import UpArrow from '../../../shared/Media/Svg/UpArrow';

interface GridData {
  type: string;
  Id: string;
  YearKey: number | never;
  WeekKey: number | never;
  BiRevlineNm: string | never;
  BiSyscodeTypeNm: string | never;
  MdlGrpNm: string | never;
  ModelType: string | never;
  SumOfJanAmt: number;
  SumOfFebAmt: number;
  SumOfMarAmt: number;
  SumOfAprAmt: number;
  SumOfMayAmt: number;
  SumOfJunAmt: number;
  SumOfJulAmt: number;
  SumOfAugAmt: number;
  SumOfSepAmt: number;
  SumOfOctAmt: number;
  SumOfNovAmt: number;
  SumOfDecAmt: number;
  Total: number;
}

interface MonthlyData {
  gridData: GridData;
  gridData2: GridData;
  gridData3: GridData;
  keys: Array<string>;
  keysCompare: Array<string>;
}

const Monthly: any = ({
  gridData,
  keys,
  gridData2,
  gridData3,
}: MonthlyData | never | any) => {
  const classes = monthStyles();
  const [tableIndex, setTableIndex] = useState(0);
  const compare = useAppSelector((store: any) => store.selectedValue.compare);

  const selectedYear = useAppSelector((store: any) => store.selectedValue.year);


  const Next: any = () => {
    setTableIndex(tableIndex + 1);
  };
  const Previous: any = () => {
    setTableIndex(tableIndex - 1);
  };
  const currentKey = keys && keys[tableIndex];

  return (
    <>
      <Typography component="div" className={classes.byMonthMob}>
        <Typography component="div" className={classes.byMonthMobTitle}>
          Monthly Revenue
        </Typography>
        <Typography component="div" className={classes.calendarMonth}>
          <Calendar />
          &nbsp;
          {currentKey.split('SumOf')[1].split('Amt')[0]}
          &nbsp; {selectedYear}
        </Typography>
        <Typography component="div" className={classes.monthValues}>
          <Typography component="div" className={classes.monthCurrent}>
            <Typography component="div" className={classes.dataTitles}>
              {gridData[0].type}
            </Typography>
            <Typography component="div" className={classes.dataValues}>
              <Dollar /> {Math.abs(gridData && gridData[0][currentKey]).toLocaleString()}
            </Typography>
            {compare && gridData2 && gridData2.length > 0 ? (
              parseInt(gridData[0][currentKey]) < parseInt(gridData2[0][currentKey]) ? (
                <Typography component="div" className={classes.compareRedValues}>
                  {gridData[0][currentKey] - gridData2[0][currentKey] === 0 ? (
                    '-'
                  ) : (
                    <>
                      - ${' '}
                      {Math.abs(gridData[0][currentKey] - gridData2[0][currentKey]).toLocaleString()}{' '}
                      ( <DownArrow /> &nbsp;
                      {(
                        (Math.abs(gridData[0][currentKey] - gridData2[0][currentKey]) /
                          Math.abs(gridData[0][currentKey])) *
                        100
                      ).toFixed(2)}{' '}
                      %)
                    </>
                  )}
                </Typography>
              ) : (
                <Typography component="div" className={classes.compareValues}>
                  {gridData[0][currentKey] - gridData2[0][currentKey] === 0 ? (
                    '-'
                  ) : (
                    <>
                      + ${' '}
                      {Math.abs(gridData[0][currentKey] - gridData2[0][currentKey]).toLocaleString()}{' '}
                      ( <UpArrow /> &nbsp;
                      {(
                        (Math.abs(gridData[0][currentKey] - gridData2[0][currentKey]) /
                          Math.abs(gridData[0][currentKey])) *
                        100
                      ).toFixed(2)}{' '}
                      %)
                    </>
                  )}
                </Typography>
              )
            ) : (
              ''
            )}
          </Typography>
          <Typography component="div" className={classes.monthBudgeted}>
            <Typography component="div" className={classes.dataTitles}>
              {gridData[1].type}
            </Typography>
            <Typography component="div" className={classes.dataValues}>
              {gridData[1].type === 'Adjustments' ? '(' : ''}
              <Dollar /> {Math.abs(gridData && gridData[1][currentKey]).toLocaleString()}
              {gridData[1].type === 'Adjustments' ? ')' : ''}
            </Typography>
            {compare && gridData2 && gridData2.length > 0 ? (
              parseInt(gridData[1][currentKey]) < parseInt(gridData2[1][currentKey]) ? (
                <Typography component="div" className={classes.compareRedValues}>
                  {gridData[1][currentKey] - gridData2[1][currentKey] === 0 ? (
                    '-'
                  ) : (
                    <>
                      - (${' '}
                      {Math.abs(gridData[1][currentKey] - gridData2[1][currentKey]).toLocaleString()}){' '}
                      ( <DownArrow /> &nbsp;
                      {(
                        (Math.abs(gridData[1][currentKey] - gridData2[1][currentKey]) /
                          Math.abs(gridData[1][currentKey])) *
                        100
                      ).toFixed(2)}{' '}
                      %)
                    </>
                  )}
                </Typography>
              ) : (
                <Typography component="div" className={classes.compareValues}>
                  {gridData[1][currentKey] - gridData2[1][currentKey] === 0 ? (
                    '-'
                  ) : (
                    <>
                      + ${' '}
                      {Math.abs(gridData[1][currentKey] - gridData2[1][currentKey]).toLocaleString()}{' '}
                      ( <UpArrow /> &nbsp;
                      {(
                        (Math.abs(gridData[1][currentKey] - gridData2[1][currentKey]) /
                          Math.abs(gridData[1][currentKey])) *
                        100
                      ).toFixed(2)}{' '}
                      %)
                    </>
                  )}
                </Typography>
              )
            ) : (
              ''
            )}
          </Typography>
          <Typography component="div" className={classes.monthPerformance}>
            <Typography component="div" className={classes.dataTitles}>
              {gridData[2].type}
            </Typography>
            <Typography component="div" className={classes.dataValues}>
              <Dollar /> {Math.abs(gridData && gridData[2][currentKey]).toLocaleString()}
            </Typography>
            {compare && gridData2 && gridData2.length > 0 ? (
              parseInt(gridData[2][currentKey]) < parseInt(gridData2[2][currentKey]) ? (
                <Typography component="div" className={classes.compareRedValues}>
                  {gridData[2][currentKey] - gridData2[2][currentKey] === 0 ? (
                    '-'
                  ) : (
                    <>
                      - ${' '}
                      {Math.abs(gridData[2][currentKey] - gridData2[2][currentKey]).toLocaleString()}{' '}
                      ( <DownArrow /> &nbsp;
                      {(
                        (Math.abs(gridData[2][currentKey] - gridData2[2][currentKey]) /
                          Math.abs(gridData[2][currentKey])) *
                        100
                      ).toFixed(2)}{' '}
                      %)
                    </>
                  )}
                </Typography>
              ) : (
                <Typography component="div" className={classes.compareValues}>
                  {gridData[2][currentKey] - gridData2[2][currentKey] === 0 ? (
                    '-'
                  ) : (
                    <>
                      + ${' '}
                      {Math.abs(gridData[2][currentKey] - gridData2[2][currentKey]).toLocaleString()}{' '}
                      ( <UpArrow /> &nbsp;
                      {(
                        (Math.abs(gridData[2][currentKey] - gridData2[2][currentKey]) /
                          Math.abs(gridData[2][currentKey])) *
                        100
                      ).toFixed(2)}{' '}
                      %)
                    </>
                  )}
                </Typography>
              )
            ) : (
              ''
            )}
          </Typography>
          <Typography component="div" className={classes.monthPerformancePercent}>
            <Typography component="div" className={classes.dataTitles}>
              {gridData[3].type}
            </Typography>
            {gridData.length === 4 ? (
              <>
                <Typography component="div" className={classes.dataValues}>
                  {gridData[3][currentKey].split('%')[0]}
                  <Percent />
                </Typography>
                {compare && gridData2 && gridData2.length > 0 ? (
                  parseInt(gridData[2][currentKey]) <
                    parseInt(gridData2[2][currentKey]) ? (
                    <Typography component="div" className={classes.compareRedValues}>
                      {gridData[2][currentKey] - gridData2[2][currentKey] === 0 ? (
                        '-'
                      ) : (
                        <>
                          - ${' '}
                          {Math.abs(gridData[2][currentKey] - gridData2[2][currentKey]).toLocaleString()}{' '}
                          ( <DownArrow /> &nbsp;
                          {(
                            (Math.abs(gridData[2][currentKey] - gridData2[2][currentKey]) /
                              Math.abs(gridData[2][currentKey])) *
                            100
                          ).toFixed(2)}{' '}
                          %)
                        </>
                      )}
                    </Typography>
                  ) : (
                    <Typography component="div" className={classes.compareValues}>
                      {gridData[2][currentKey] - gridData2[2][currentKey] === 0 ? (
                        '-'
                      ) : (
                        <>
                          + ${' '}
                          {Math.abs(gridData[2][currentKey] - gridData2[2][currentKey]).toLocaleString()}{' '}
                          ( <UpArrow /> &nbsp;
                          {(
                            (Math.abs(gridData[2][currentKey] - gridData2[2][currentKey]) /
                              Math.abs(gridData[2][currentKey])) *
                            100
                          ).toFixed(2)}{' '}
                          %)
                        </>
                      )}
                    </Typography>
                  )
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                <Typography component="div" className={classes.dataValues}>
                  <Dollar />
                  {Math.abs(gridData[3][currentKey]).toLocaleString()}
                </Typography>
                {compare && gridData2 && gridData2.length > 0 ? (
                  parseInt(gridData[3][currentKey]) <
                    parseInt(gridData2[3][currentKey]) ? (
                    <Typography component="div" className={classes.compareRedValues}>
                      {gridData[3][currentKey] - gridData2[3][currentKey] === 0 ? (
                        '-'
                      ) : (
                        <>
                          - ${' '}
                          {Math.abs(gridData[3][currentKey] - gridData2[3][currentKey]).toLocaleString()}{' '}
                          ( <DownArrow /> &nbsp;
                          {(
                            (Math.abs(gridData[3][currentKey] - gridData2[3][currentKey]) /
                              Math.abs(gridData[3][currentKey])) *
                            100
                          ).toFixed(2)}{' '}
                          %)
                        </>
                      )}
                    </Typography>
                  ) : (
                    <Typography component="div" className={classes.compareValues}>
                      {gridData[3][currentKey] - gridData2[3][currentKey] === 0 ? (
                        '-'
                      ) : (
                        <>
                          + $ {gridData3[3][currentKey].toLocaleString()} ( <UpArrow />{' '}
                          &nbsp;
                          {(
                            (Math.abs(gridData[3][currentKey] - gridData2[3][currentKey]) /
                              Math.abs(gridData[3][currentKey])) *
                            100
                          ).toFixed(2)}{' '}
                          %)
                        </>
                      )}
                    </Typography>
                  )
                ) : (
                  ''
                )}
              </>
            )}
          </Typography>
          <Typography component="div" className={classes.monthPerformancePercent}>
            {gridData.length === 5 ? (
              <>
                <Typography component="div" className={classes.dataTitles}>
                  {gridData[4].type}
                </Typography>
                <Typography component="div" className={classes.dataValues}>
                  <>
                    <Dollar />{' '}
                    {Math.abs(gridData && gridData[4][currentKey]).toLocaleString()}
                  </>
                </Typography>
                {compare && gridData2 && gridData2.length > 0 ? (
                  parseInt(gridData[4][currentKey]) <
                    parseInt(gridData2[4][currentKey]) ? (
                    <Typography component="div" className={classes.compareRedValues}>
                      {gridData[4][currentKey] - gridData2[4][currentKey] === 0 ? (
                        '-'
                      ) : (
                        <>
                          - ${' '}
                          {Math.abs(gridData[4][currentKey] - gridData2[4][currentKey]).toLocaleString()}{' '}
                          ( <DownArrow /> &nbsp;
                          {(
                            (Math.abs(gridData[4][currentKey] - gridData2[4][currentKey]) /
                              Math.abs(gridData[4][currentKey])) *
                            100
                          ).toFixed(2)}{' '}
                          %)
                        </>
                      )}
                    </Typography>
                  ) : (
                    <Typography component="div" className={classes.compareValues}>
                      {gridData[4][currentKey] - gridData2[4][currentKey] === 0 ? (
                        '-'
                      ) : (
                        <>
                          + $ {gridData3[4][currentKey].toLocaleString()} ( <UpArrow />{' '}
                          &nbsp;
                          {(
                            (Math.abs(gridData[4][currentKey] - gridData2[4][currentKey]) /
                              Math.abs(gridData[4][currentKey])) *
                            100
                          ).toFixed(2)}{' '}
                          %)
                        </>
                      )}
                    </Typography>
                  )
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </Typography>
          <Typography component="div" className={classes.buttonsByMonthMob}>
            {tableIndex === 0 ? (
              ''
            ) : (
              <Button
                variant="outlined"
                className={classes.previousButton}
                onClick={Previous}
              >
                <ButtonPrevious /> Previous
              </Button>
            )}

            {tableIndex === 11 ? (
              ''
            ) : (
              <Button variant="outlined" className={classes.nextButton} onClick={Next}>
                {' '}
                Next <ButtonNext />{' '}
              </Button>
            )}
          </Typography>
        </Typography>
      </Typography>
    </>
  );
};

export default Monthly;
