import {
  FETCH_SALES_MARKETS_REQUEST,
  FETCH_SALES_MARKETS_SUCCESS,
  FETCH_SALES_MARKETS_FAILURE,
} from '../../actions/performance/salesMarkets/salesMarketsActionTypes';

const initialState = {
  loading: false,
  salesMarkets: [],
  error: '',
  fetched: false,
};

const salesMarketsReducer = (state = initialState, action: any):any => {
  switch (action.type) {
    case FETCH_SALES_MARKETS_REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      };
    case FETCH_SALES_MARKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        salesMarkets: action.payload,
        fetched: true,
        error: '',
      };
    case FETCH_SALES_MARKETS_FAILURE:
      return {
        ...state,
        loading: false,
        weeks: [],
        error: action.payload,
        fetched: false,
      };
    default:
      return state;
  }
};

export default salesMarketsReducer;
