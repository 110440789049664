import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { SpectrumFooterLogo, HelpFooterLogo } from '../Media/Svg';
import { useStyles } from './styles';
import React from 'react';

const Footer = (): JSX.Element => {
  const classes = useStyles();
  const d = new Date();
const year = d.getFullYear();
  return (
    <Typography component="div" className={classes.footerMain}>
      <Typography component="div" className={classes.footerHelp}>
        <SpectrumFooterLogo />
        <Link to="/support" className={classes.helpFeedback}>
          <HelpFooterLogo />
        </Link>
      </Typography>

      <Typography component="div" className={classes.policyContainer}>

        <a
          href="https://www.spectrum.com/policies/your-privacy-rights"
          rel="noreferrer"
          target="_blank"
          className={classes.policies}
        >
          Your Privacy Rights
        </a>
        <a
          href="https://www.spectrum.com/policies/california"
          rel="noreferrer"
          target="_blank"
          className={classes.policies2}
        >
          California Consumer Privacy Rights
        </a>
        <a
          href="https://www.spectrum.com/policies/your-privacy-rights-opt-out"
          rel="noreferrer"
          target="_blank"
          className={classes.policies3}
        >
          Do Not Sell or Share My Personal Information/Opt-Out of Targeted Advertising
        </a>
        <a
          href="https://www.spectrum.com/policies/your-privacy-rights-opt-out"
          target="_blank"
          rel="noreferrer"
          className={classes.policies4}
        >
          California Consumer Limit the Use of My Sensitive Personal Information
        </a>
        <a
          href="https://www.spectrum.com/policies/your-privacy-rights-opt-out"
          target="_blank"
          rel="noreferrer"
          className={classes.policies2}
        >
          Do Not Sell My Information
        </a>
      </Typography>
      <Typography component="div" className={classes.brandName}>
        Developed by Spectrum Reach Enterprise Development Team (Version 1.0)
      </Typography>
      <Typography component="div" className={classes.brandName}>
        © {year} Spectrum Reach.
      </Typography>
    </Typography>
  );
};

export default Footer;
