export const FETCH_WEEKLY_REVENUES_REQUEST = 'FETCH_WEEKLY_REVENUES_REQUEST';
export const FETCH_WEEKLY_REVENUES_SUCCESS = 'FETCH_WEEKLY_REVENUES_SUCCESS';
export const FETCH_WEEKLY_REVENUES_FAILURE = 'FETCH_WEEKLY_REVENUES_FAILURE';

export const FETCH_WEEKLY_REVENUES_COMPARE_REQUEST =
  'FETCH_WEEKLY_REVENUES_COMPARE_REQUEST';
export const FETCH_WEEKLY_REVENUES_COMPARE_SUCCESS =
  'FETCH_WEEKLY_REVENUES_COMPARE_SUCCESS';
export const FETCH_WEEKLY_REVENUES_COMPARE_FAILURE =
  'FETCH_WEEKLY_REVENUES_COMPARE_FAILURE';
