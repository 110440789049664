import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
export const supportStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainDiv: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '128px!important',
      paddingBottom: '80px!important',

      [theme.breakpoints.up(2200)]: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto!important',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        height: 'auto!important',
      },
      [theme.breakpoints.between(1240, 1448)]: {
      },
      [theme.breakpoints.between(1023, 1025)]: {
        paddingTop: '20vh!important',
        paddingBottom: '130px!important',
      },

      [theme.breakpoints.between(920, 1240)]: {
        height: 'fit-content!important',
      },
      [theme.breakpoints.between(616, 911)]: {
        height: 'auto!important',
      },
      [theme.breakpoints.between(911, 920)]: {
        height: 'auto!important',
      },
    },
    supportContent: {
      display: 'flex',
      justifyContent: 'center',
      width: '1032px',
      [theme.breakpoints.up(2200)]: {
        width: '50% !important',
        alignItems: 'center',
      },
    },
    allContent: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down(920)]: {
        width: '70%',
      },
      [theme.breakpoints.between(400, 600)]: {
        width: '90%!important',
      },
      [theme.breakpoints.between(0, 400)]: {
        width: '90%!important',
      },
    },
    supportTitle: {
      fontSize: '39.81px!important',
      lineSeight: '48px',
      fontWeight: '350 !important' as any,
      color: '#001B33',
      marginBottom: '16px!important',
      [theme.breakpoints.up(2200)]: {
        fontSize: '69.81px!important',
        lineSeight: '48px',
      },
    },
    supportText: {
      marginBottom: '16px!important',
      color: '#001B33 !important',
      [theme.breakpoints.up(2200)]: {
        fontSize: '29.81px!important',
        lineSeight: '48px',
        marginBottom: '36px!important',
      },
    },
    subject: {
      width: '100%',
      marginBottom: '24px!important',
      [theme.breakpoints.up(2200)]: {
        marginBottom: '36px!important',
      },
    },
    contact: {
      marginBottom: '40px!important',
      [theme.breakpoints.down(920)]: {
        marginBottom: '32px!important',
      },
      [theme.breakpoints.up(2200)]: {
        marginBottom: '36px!important',
      },
    },
    contactMethod: {
      color: '#001B33!important',
      fontSize: '16px!important',
      [theme.breakpoints.up(2200)]: {
        fontSize: '23px!important',
      },
    },
    contactMethodSpan: {
      color: 'red',
    },
    email: {
      marginBottom: '16px!important',
      marginTop: '1.5rem !important',
      [theme.breakpoints.up(2200)]: {
        marginBottom: '26px!important',
      },
      [theme.breakpoints.down(616)]: {
        marginTop: '0.4rem !important',
      },
    },
    description: {
      marginBottom: '47px!important',
      borderColor: 'grey!important',
      borderRadius: '3px !important',
      padding: '10px',
      resize: 'vertical',
      color: 'rgba(0, 0, 0, 0.6) !important',
      fontWeight: '300 !important' as any,
      fontSize: '1rem !important',
      lineHeight: '1.4375em !important',
      letterSpacing: '0.00938em !important',
      '&:focused-visible': {
        outlineColor: 'white!important',
      },
      [theme.breakpoints.up(2200)]: {
        marginBottom: '77px!important',
      },
    },
    loginButton: {
      width: '100%',
      background: '#007DB3!important',
      borderRadius: '40px!important',
      textTransform: 'capitalize !important' as any,
      padding: '16px!important',
      marginBottom: '80px!important',
      [theme.breakpoints.up(2200)]: {
        height: '77px!important',
        fontSize: '20px!important',
      },
      [theme.breakpoints.between(0, 616)]: {
        marginBottom: '0px !important',
      },
    },
    links: {
      textDecoration: 'none !important',
      color: '#ffffff !important',
    },
  }),
);
