import {
  FETCH_EMAIL_REQUEST,
  FETCH_EMAIL_SUCCESS,
  FETCH_EMAIL_FAILURE,
  CHANGE_EMAIL_FETCHED_STATE,
} from './emailActionTypes';
import store from '../../store/store';
import axios from 'axios';
import { alertAction } from '../Alert/alertActions';
import { Dispatch } from 'redux';
import { loaderAction } from '../loader/loaderActions';

interface EMAIL {
  Success: boolean;
}
interface RequestHeader {
  Subject: string;
  Body: string;
  From: string;
}

const fetchEmailRequest:any = () => {
  return {
    type: FETCH_EMAIL_REQUEST,
  };
};

const fetchEmailSuccess:any = (EMAIL: EMAIL) => {
  return {
    type: FETCH_EMAIL_SUCCESS,
    payload: EMAIL,
  };
};

const fetchEmailFailure:any = (error: string) => {
  return {
    type: FETCH_EMAIL_FAILURE,
    payload: error,
  };
};
const changeStateSupport:any = () => {
  return {
    type: CHANGE_EMAIL_FETCHED_STATE,
  };
};

export const sendEmail:any = (requestHeader: RequestHeader) => {
  const token = store.getState().user.token;
  return function (dispatch: Dispatch) {
    dispatch(fetchEmailRequest());
    dispatch(loaderAction(true) as any);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND}api/Users/EmailHelp`,
      headers: {
        EmailHelpRequest: JSON.stringify(requestHeader),
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const EMAIL = response.data;
        dispatch(alertAction('Email Sent ...', true, 'success') as any);
        dispatch(fetchEmailSuccess(EMAIL));
        dispatch(loaderAction(false) as any);
      })
      .catch((error) => {
        dispatch(alertAction(`${error.response.data.Message}`, true, 'error') as any);
        dispatch(fetchEmailFailure(error.response.data.Message));
        dispatch(loaderAction(false) as any);
      });
  };
};
export const changeFetchedStateSupport = () => {
  return function (dispatch: Dispatch) {
    dispatch(changeStateSupport());
  };
};
