import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import './DatePopover.css';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { getUniqueYears } from '../../../components/Performance/helperFunctions';
import {
  changeDateType,
  setSelectedWeekRedux,
  setSelectedYearRedux,
} from '../../../redux';
import YearWeekSelect from './YearWeekSelect';

export default function DatePopOver(): any {
  const yearWeeks:any = useAppSelector((store) => store.user.user.YearWeeks);
  const weeks = useAppSelector((store) => store.weeks.weeks);
  const [years] = useState(getUniqueYears(yearWeeks));

  const show = 'week';

  const [selectedYear, setSelectedYear] = useState(
    yearWeeks ? yearWeeks[0].Year.value : -1,
  );
  let yearCounter = 0;
  const [selectedWeek, setSelectedWeek] = useState(
    yearWeeks ? yearWeeks[0].Week.value : -1,
  );
 


  const dispatch = useAppDispatch();

  useEffect(() => {
    if (years.length > 0) {
      setSelectedYear(years[0].value);
    }
  }, [years, setSelectedYear]);

  

  useEffect(() => {
    if (weeks.length > 0) {
      setSelectedWeek(weeks[0].value);
    }
  }, [weeks, setSelectedWeek]);
  

  useEffect(() => {
    if (yearCounter === 0) {
      yearCounter = yearCounter + 1;
      dispatch(setSelectedYearRedux(selectedYear));
    }
  }, [selectedYear]);

  useEffect(() => {
    dispatch(setSelectedWeekRedux(selectedWeek));
  }, [selectedWeek]);

  useEffect(() => {
    dispatch(changeDateType(show));
  }, [show]);


  



  return (
    <>
      
    
        <Typography sx={{ pr: 3, display:'flex', width:'32.6%', justifyContent:'space-between' }}>
            <YearWeekSelect
              data={years}
              label_select={'Year'}
              selectedData={selectedYear}
              setSelectedData={setSelectedYear}
            />
            
                <YearWeekSelect
                  data={weeks}
                  label_select={'Week'}
                  selectedData={selectedWeek}
                  setSelectedData={setSelectedWeek}
                />
             
              
           
        </Typography>
    </>
  );
}
