import React, { useState, useEffect } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Download from '../../shared/Media/Svg/Download';
import ComboBox from '../../shared/Filters/AffiliateSalesFilter/AffiliateSalesFilter';
import MultiSelect from '../../shared/Filters/ReportTypeFilter/MultiSelect';
import DatePopOver from '../../shared/Filters/DateFilter/DatePopover';
import Salesfilter from '../../shared/Filters/SalesMarketFilter/SalesMarketFilter';
import { getUniqueAffiliates } from './helperFunctions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchRevenueBy,
  fetchWeeklyRevenues,
  getSalesMarkets,
  setSelectedRevenueTypeRedux,
  applyDataChange,
} from '../../redux';
import { performanceStyles } from './styles';
import { InfoIcon } from '../../shared/Media/Svg';

import {
  createPartnerPaymentGrid,
  createPartnerRevenueGrid,
  getPercentage,
  getPercentageForPartnerPayment,
  sumObjectsByKey,
} from './PerformanceComponets/TableFunctions';
import Tables from './PerformanceComponets/Tables';
import Graph from './PerformanceComponets/Graph';
import Monthly from './PerformanceComponets/Monthly';
import Yearly from './PerformanceComponets/Yearly';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIconModals from '../../shared/Media/Svg/CloseIconModals';
import { exportData } from '../../redux';
import ExportAffiliateBox from './Export/ExportAffiliateBox';
import ExportSalesBox from './Export/ExportSalesBox';
import ExportDate from './Export/ExportDate';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
 import AccordionArrow from '../../shared/Media/Svg/AccordionArrow';

const Performance:any = () => {
  const classes = performanceStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(920));
  const [value, setValue] = useState('1');
  const [affiliateDropDown, setAffiliateDropDown] = useState([]);
  let revenueCounter = 0;

  const [applyChange, setApplyChange] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedRevenueType, setSelectedRevenueType] = useState('Partner Revenue ');

  const revenues: any = useAppSelector((store: any) => store.revenueBy.revenueBy);
  useEffect(() => {
    setSelectedRevenueType(revenues.length > 1 && revenues[0].ID ? revenues[0].ID : 'Partner Revenue');
  }, [revenues]);

  const dispatch: any = useAppDispatch();

  const salesMarkets = useAppSelector((store: any) => store.salesMarkets.salesMarkets);
  const selectedYear = useAppSelector((store: any) => store.selectedValue.year);

  const fetchSalesMarkets:any = () => {
    const affiliatesArray = getUniqueAffiliates(affiliateDropDown);
    if (affiliatesArray.length > 0) {
      dispatch(getSalesMarkets(affiliatesArray));
    }
  };

  useEffect(() => {
    dispatch(setSelectedRevenueTypeRedux(selectedRevenueType));
  }, [selectedRevenueType]);

  useEffect(() => {
    fetchSalesMarkets();
  }, [affiliateDropDown]);

  const selectedData = useAppSelector((store: any) => store.selectedValue);
  const dataChanged = selectedData.changed;
  const dataFetched = selectedData.fetched;

  useEffect(() => {
    if (dataChanged && applyChange && selectedData.fetched) {
      dispatch(fetchWeeklyRevenues());
      dispatch(applyDataChange(false));
      setApplyChange(false);
    }
  }, [dataChanged, dataFetched]);

  const Apply:any = () => {
    if (dataChanged) {
      dispatch(fetchWeeklyRevenues());
      dispatch(applyDataChange(false));
      setApplyChange(false);
    }
  };

  const revenueData = useAppSelector((store: any) => store.revenueBy.revenueBy);
  const weeklyRevenuesFetched = useAppSelector((state: any) => state.weeklyRevenues.fetched);

  useEffect(() => {
    if (revenueCounter === 0) {
      revenueCounter = revenueCounter + 1;
      dispatch(fetchRevenueBy());
    }
  }, [revenueCounter]);

  const weeklyRevenuesData = useAppSelector((state: any) => state.weeklyRevenues.weeklyRevenues);
  const compareData = useAppSelector((state: any) => state.weeklyRevenues.weeklyRevenuesCompare);
  const [data, setdata] = useState(weeklyRevenuesData);
  const [data2, setdata2] = useState(compareData);
  const [data3, setdata3] = useState(compareData);

  // // =============================FROM Total Core And Total Politcal In Affiliate Summary===================================
  const [totalCorePartnerRevenue, setTotalCorePartnerRevenue] = useState<any[]>([]);
  const [totalCorePartnerPayment, setTotalCorePartnerPayment] = useState<any[]>([]);
  const [totalPoliticalPartnerPayment, setTotalPoliticalPartnerPayment] = useState<any[]>([]);
  const [totalPoliticalPartnerRevenue, setTotalPoliticalPartnerRevenue] = useState<any[]>([]);

  // // =============================FROM PREVIOUS PORTAL===================================
  const [totalMarketPartner, setTotalMarketPartner] = useState([]);
  const [totalMarketRevenue, setTotalMarketRevenue] = useState<any[]>([]);
  const [totalInterconnectPartner, setTotalInterconnectPartner] = useState([]);
  const [totalInterconnectRevenue, setTotalInterconnectRevenue] = useState<any[]>([]);
  const [totalZonePartner, setTotalZonePartner] = useState([]);
  const [totalZoneRevenue, setTotalZoneRevenue] = useState<any[]>([]);
  const [coreInterconnectPartner, setCoreInterconnectPartner] = useState([]);
  const [coreInterconnectRevenue, setCoreInterconnectRevenue] = useState<any[]>([]);
  const [politicalInterconnectPartner, setPoliticalInterconnectPartner] =
    useState([]);
  const [politicalInterconnectRevenue, setPoliticalInterconnectRevenue] = useState<any[]>([]);
    const [coreZonePartner, setCoreZonePartner] = useState([]);
    const [coreZoneRevenue, setCoreZoneRevenue] = useState<any[]>([]);
    const [politicalZonePartner, setPoliticalZonePartner] = useState([]);
    const [politicalZoneRevenue, setPoliticalZoneRevenue] = useState<any[]>([]);
  ////2nd time period
  const [totalMarketPartner2, setTotalMarketPartner2] = useState([]);
  const [totalMarketRevenue2, setTotalMarketRevenue2] = useState([]);
  const [totalInterconnectPartner2, setTotalInterconnectPartner2] = useState([]);
  const [totalInterconnectRevenue2, setTotalInterconnectRevenue2] = useState([]);
  const [totalZonePartner2, setTotalZonePartner2] = useState([]);
  const [totalZoneRevenue2, setTotalZoneRevenue2] = useState([]);
  const [coreInterconnectPartner2, setCoreInterconnectPartner2] = useState([]);
  const [coreInterconnectRevenue2, setCoreInterconnectRevenue2] = useState([]);
  const [politicalInterconnectPartner2, setPoliticalInterconnectPartner2] =
    useState([]);
  const [politicalInterconnectRevenue2, setPoliticalInterconnectRevenue2] =
    useState([]);
    const [coreZonePartner2, setCoreZonePartner2] = useState([]);
    const [coreZoneRevenue2, setCoreZoneRevenue2] = useState([]);
    const [politicalZonePartner2, setPoliticalZonePartner2] = useState([]);
    const [politicalZoneRevenue2, setPoliticalZoneRevenue2] = useState([]);
  /// Difference data
  const [totalMarketPartner3, setTotalMarketPartner3] = useState([]);
  const [totalMarketRevenue3, setTotalMarketRevenue3] = useState([]);
  const [totalInterconnectPartner3, setTotalInterconnectPartner3] = useState([]);
  const [totalInterconnectRevenue3, setTotalInterconnectRevenue3] = useState([]);
  const [totalZonePartner3, setTotalZonePartner3] = useState([]);
  const [totalZoneRevenue3, setTotalZoneRevenue3] = useState([]);
  const [coreInterconnectPartner3, setCoreInterconnectPartner3] = useState([]);
  const [coreInterconnectRevenue3, setCoreInterconnectRevenue3] = useState([]);
  const [politicalInterconnectPartner3, setPoliticalInterconnectPartner3] =
    useState([]);
  const [politicalInterconnectRevenue3, setPoliticalInterconnectRevenue3] =
    useState([]);
    const [coreZonePartner3, setCoreZonePartner3] = useState([]);
    const [coreZoneRevenue3, setCoreZoneRevenue3] = useState([]);
    const [politicalZonePartner3, setPoliticalZonePartner3] = useState([]);
    const [politicalZoneRevenue3, setPoliticalZoneRevenue3] = useState([]);

  const compare = useAppSelector((store: any) => store.selectedValue.compare);

  useEffect(() => {
    if (weeklyRevenuesData.length > 1) {
      setdata(weeklyRevenuesData);
    }
    if (Object && Object.keys(compareData).length > 0) {
      setdata2(compareData.secondTimePeriod);
      setdata3(compareData.differenceBetweenPeriods);
    }
  }, [weeklyRevenuesData, compareData]);

  let arr = data[0] && Object.keys(data[0]);
  if (arr && arr.length > 0) {
    arr = arr && arr.slice(7);
  }
  let arrCompare = data3[0] && Object.keys(data3[0]);
  if (arrCompare && arrCompare.length > 0) {
    arrCompare = arrCompare && arrCompare.slice(7);
  }
  const keysCompare: any = arrCompare && arrCompare.slice(13, 26);

  useEffect(() => {
    //////////////////////////////    CORE INTERCONNECT START /////////////////////////////////////////
    const coreInterconnect = data.filter(
      (dataTable: { BiSyscodeTypeNm: string; MdlGrpNm: string }) =>dataTable.BiSyscodeTypeNm === 'Interconnect' && dataTable.MdlGrpNm === 'CORE');
    //////////////////// CORE  INTERCONNECT PARTNER PAYMENT  START///////////////////////////
    const coreInterconnectPartnerPayment = coreInterconnect.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

    let coreInterconnect2019PartnerPaymentArray: any = createPartnerPaymentGrid(coreInterconnectPartnerPayment, arr);
    coreInterconnect2019PartnerPaymentArray = getPercentageForPartnerPayment(coreInterconnect2019PartnerPaymentArray, arr);

    ////////////////////  CORE INTERCONNECT PARTNER PAYMENT  END ///////////////////////////

    //////////////////// CORE INTERCONNECT REVENUE  START///////////////////////////

    const CoreInterconnectPartnerRevenue = coreInterconnect.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

    const coreInterconnectPartnerRevenue: any = createPartnerRevenueGrid(CoreInterconnectPartnerRevenue, arr, selectedRevenueType);
    //////////////////// CORE INTERCONNECT REVENUE   END///////////////////////////

    //////////////////////////////    CORE INTERCONNECT END /////////////////////////////////////////

    ////////////////////////////////// POLITICAL INTERCONNECT START /////////////////////////////////////////

    /////////////////////////////////  POLITICAL INTERCONNECT PARTNER PAYMENT START ////////////////////////////

    const politicalInterconnect = data.filter(
      (dataTable2: { BiSyscodeTypeNm: string; MdlGrpNm: string }) =>dataTable2.BiSyscodeTypeNm === 'Interconnect' && dataTable2.MdlGrpNm === 'POLITICAL');
    const politicalInterconnectPartnerPayment = politicalInterconnect.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

    let PoliticalInterconnect2019PartnerPaymentArray: any = createPartnerPaymentGrid(politicalInterconnectPartnerPayment, arr);
    PoliticalInterconnect2019PartnerPaymentArray = getPercentageForPartnerPayment(PoliticalInterconnect2019PartnerPaymentArray, arr);
    
    /////////////////////////////////  POLITICAL INTERCONNECT PARTNER PAYMENT END ////////////////////////////

    /////////////////////////////////// POLITICAL INTERCONNECT  REVENUE  START///////////////////////////

    const PoliticalInterconnectPartnerRevenue = politicalInterconnect.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

    const politicalInterconnectPartnerRevenue: any = createPartnerRevenueGrid(PoliticalInterconnectPartnerRevenue, arr, selectedRevenueType);
    /////////////////////////////////// POLITICAL INTERCONNECT  REVENUE  END///////////////////////////

    ////////////////////////////////// POLITICAL INTERCONNECT END /////////////////////////////////////////

    ////////////////////////////////// CORE ZONE START /////////////////////////////////////////

    const coreZone = data.filter((dataTable3: { BiSyscodeTypeNm: string; MdlGrpNm: string }) =>dataTable3.BiSyscodeTypeNm === 'Zone' && dataTable3.MdlGrpNm === 'CORE');

    ////////////////////////////////// CORE ZONE PARTNER PAYMENT START //////////////////////////////
    const coreZonePartnerPayment = coreZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

    const coreZone2019PartnerPaymentArray: any = createPartnerPaymentGrid(coreZonePartnerPayment, arr);

    ////////////////////////////////// CORE ZONE PARTNER PAYMENT END //////////////////////////////

    ////////////////////////////////// CORE ZONE REVENUE START //////////////////////////////
    const CoreZonePartnerRevenue = coreZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

    const coreZonePartnerRevenue: any = createPartnerRevenueGrid(CoreZonePartnerRevenue, arr, selectedRevenueType);

    ////////////////////////////////// CORE ZONE END /////////////////////////////////////////

    ////////////////////////////////// POLITICAL ZONE START /////////////////////////////////////////

    const politicalZone = data.filter((dataTable4: { BiSyscodeTypeNm: string; MdlGrpNm: string }) =>dataTable4.BiSyscodeTypeNm === 'Zone' && dataTable4.MdlGrpNm === 'POLITICAL');

    //////////////////////////////////  POLITICAL ZONE PARTNER PAYMENT ///////////////////////////////////

    const politicalZonePartnerPayment = politicalZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

    const politicalZone2019PartnerPaymentArray: any = createPartnerPaymentGrid(politicalZonePartnerPayment, arr);

    //////////////////////////////// POLITICAL ZONE  REVENUE START //////////////////////
    const PoliticalZonePartnerRevenue = politicalZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

    const politicalZonePartnerRevenue: any = createPartnerRevenueGrid(PoliticalZonePartnerRevenue, arr, selectedRevenueType);

    ////////////////////////////////// POLITICAL ZONE END /////////////////////////////////////////

    /////////////////////////////////// TOTAL  START ///////////////////////////////////

    let totalInterconnectPartnerPayment: any = sumObjectsByKey(...coreInterconnect2019PartnerPaymentArray, ...PoliticalInterconnect2019PartnerPaymentArray);
    totalInterconnectPartnerPayment = getPercentageForPartnerPayment(totalInterconnectPartnerPayment, arr);
    let totalZonePartnerPayment: any = sumObjectsByKey(...coreZone2019PartnerPaymentArray, ...politicalZone2019PartnerPaymentArray);
    totalZonePartnerPayment = getPercentageForPartnerPayment(totalZonePartnerPayment, arr);
    let totalMarketPartnerPayment: any = sumObjectsByKey(...totalInterconnectPartnerPayment, ...totalZonePartnerPayment);
    totalMarketPartnerPayment = getPercentageForPartnerPayment(totalMarketPartnerPayment, arr);
    let totalInterconnectPartnerRevenue: any = sumObjectsByKey(...coreInterconnectPartnerRevenue,  ...politicalInterconnectPartnerRevenue);

    totalInterconnectPartnerRevenue = getPercentage(totalInterconnectPartnerRevenue, arr);

    let totalZonePartnerRevenue: any = sumObjectsByKey(...coreZonePartnerRevenue, ...politicalZonePartnerRevenue);
    totalZonePartnerRevenue = getPercentage(totalZonePartnerRevenue, arr);

    let totalMarketPartnerRevenue: any = sumObjectsByKey(...totalInterconnectPartnerRevenue, ...totalZonePartnerRevenue);

    totalMarketPartnerRevenue = getPercentage(totalMarketPartnerRevenue, arr);

    let totalCorePartnerRevenueData: any = sumObjectsByKey(...coreZonePartnerRevenue, ...CoreInterconnectPartnerRevenue);
    
    totalCorePartnerRevenueData = getPercentage(totalCorePartnerRevenueData, arr);

    let totalCorePartnerPaymentData: any = sumObjectsByKey(...coreInterconnect2019PartnerPaymentArray, ...coreZone2019PartnerPaymentArray);

    totalCorePartnerPaymentData = getPercentageForPartnerPayment(totalCorePartnerPaymentData, arr);

    let totalPoliticalPartnerPaymentData: any = sumObjectsByKey(...politicalZone2019PartnerPaymentArray, ...PoliticalInterconnect2019PartnerPaymentArray);

    totalPoliticalPartnerPaymentData = getPercentageForPartnerPayment(totalPoliticalPartnerPaymentData, arr);

    let totalPoliticalPartnerRevenueData: any = sumObjectsByKey(...politicalZonePartnerRevenue, ...politicalInterconnectPartnerRevenue);

    totalPoliticalPartnerRevenueData = getPercentage(totalPoliticalPartnerRevenueData, arr);
    setTotalMarketPartner(totalMarketPartnerPayment);
    setTotalMarketRevenue(totalMarketPartnerRevenue);
    setTotalInterconnectPartner(totalInterconnectPartnerPayment);
    setTotalInterconnectRevenue(totalInterconnectPartnerRevenue);
    setTotalZonePartner(totalZonePartnerPayment);
    setTotalZoneRevenue(totalZonePartnerRevenue);
    setCoreInterconnectPartner(coreInterconnect2019PartnerPaymentArray);
    setCoreInterconnectRevenue(coreInterconnectPartnerRevenue);
    setPoliticalInterconnectPartner(PoliticalInterconnect2019PartnerPaymentArray);
    setPoliticalInterconnectRevenue(politicalInterconnectPartnerRevenue);
    setCoreZonePartner(coreZone2019PartnerPaymentArray);
    setCoreZoneRevenue(coreZonePartnerRevenue);
    setPoliticalZonePartner(politicalZone2019PartnerPaymentArray);
    setPoliticalZoneRevenue(politicalZonePartnerRevenue);
    setTotalCorePartnerRevenue(totalCorePartnerRevenueData);
    setTotalCorePartnerPayment(totalCorePartnerPaymentData);
    setTotalPoliticalPartnerPayment(totalPoliticalPartnerPaymentData);
    setTotalPoliticalPartnerRevenue(totalPoliticalPartnerRevenueData);

    setLoading(false);
  }, [data]);

  useEffect(() => {
    if (data2 && data2.length > 0) {
      const coreInterconnect = data2.filter(
        (dataTableForm: { BiSyscodeTypeNm: string; MdlGrpNm: string }) => dataTableForm.BiSyscodeTypeNm === 'Interconnect' && dataTableForm.MdlGrpNm === 'CORE');
      //////////////////// CORE  INTERCONNECT PARTNER PAYMENT  START///////////////////////////
      const coreInterconnectPartnerPayment = coreInterconnect.filter( (obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

      const coreInterconnect2019PartnerPaymentArray: any = createPartnerPaymentGrid(coreInterconnectPartnerPayment, arr);

      ////////////////////  CORE INTERCONNECT PARTNER PAYMENT  END ///////////////////////////

      //////////////////// CORE INTERCONNECT REVENUE  START///////////////////////////

      const CoreInterconnectPartnerRevenue = coreInterconnect.filter( (obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

      const coreInterconnectPartnerRevenue: any = createPartnerRevenueGrid(CoreInterconnectPartnerRevenue, arr, selectedRevenueType);
      //////////////////// CORE INTERCONNECT REVENUE   END///////////////////////////

      //////////////////////////////    CORE INTERCONNECT END /////////////////////////////////////////

      ////////////////////////////////// POLITICAL INTERCONNECT START /////////////////////////////////////////

      /////////////////////////////////  POLITICAL INTERCONNECT PARTNER PAYMENT START ////////////////////////////

      const politicalInterconnect = data2.filter((dataTableForm2: { BiSyscodeTypeNm: string; MdlGrpNm: string }) => dataTableForm2.BiSyscodeTypeNm === 'Interconnect' && dataTableForm2.MdlGrpNm === 'POLITICAL');
      const politicalInterconnectPartnerPayment = politicalInterconnect.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

      const PoliticalInterconnect2019PartnerPaymentArray: any = createPartnerPaymentGrid(politicalInterconnectPartnerPayment, arr);

      /////////////////////////////////  POLITICAL INTERCONNECT PARTNER PAYMENT END ////////////////////////////

      /////////////////////////////////// POLITICAL INTERCONNECT  REVENUE  START///////////////////////////

      const PoliticalInterconnectPartnerRevenue = politicalInterconnect.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

      const politicalInterconnectPartnerRevenue: any = createPartnerRevenueGrid(PoliticalInterconnectPartnerRevenue, arr, selectedRevenueType);
      /////////////////////////////////// POLITICAL INTERCONNECT  REVENUE  END///////////////////////////

      ////////////////////////////////// POLITICAL INTERCONNECT END /////////////////////////////////////////

      ////////////////////////////////// CORE ZONE START /////////////////////////////////////////

      const coreZone = data2.filter((dataTableForm2: { BiSyscodeTypeNm: string; MdlGrpNm: string }) => dataTableForm2.BiSyscodeTypeNm === 'Zone' && dataTableForm2.MdlGrpNm === 'CORE');

      ////////////////////////////////// CORE ZONE PARTNER PAYMENT START //////////////////////////////
      const coreZonePartnerPayment = coreZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

      const coreZone2019PartnerPaymentArray: any = createPartnerPaymentGrid( coreZonePartnerPayment, arr);

      ////////////////////////////////// CORE ZONE PARTNER PAYMENT END //////////////////////////////

      ////////////////////////////////// CORE ZONE REVENUE START //////////////////////////////
      const CoreZonePartnerRevenue = coreZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

      const coreZonePartnerRevenue: any = createPartnerRevenueGrid( CoreZonePartnerRevenue, arr, selectedRevenueType);

      ////////////////////////////////// CORE ZONE END /////////////////////////////////////////

      ////////////////////////////////// POLITICAL ZONE START /////////////////////////////////////////

      const politicalZone = data2.filter( (dataTableForm3: { BiSyscodeTypeNm: string; MdlGrpNm: string }) => dataTableForm3.BiSyscodeTypeNm === 'Zone' && dataTableForm3.MdlGrpNm === 'POLITICAL');

      //////////////////////////////////  POLITICAL ZONE PARTNER PAYMENT ///////////////////////////////////

      const politicalZonePartnerPayment = politicalZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

      const politicalZone2019PartnerPaymentArray: any = createPartnerPaymentGrid(politicalZonePartnerPayment, arr);

      //////////////////////////////// POLITICAL ZONE  REVENUE START //////////////////////
      const PoliticalZonePartnerRevenue = politicalZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

      const politicalZonePartnerRevenue: any = createPartnerRevenueGrid(PoliticalZonePartnerRevenue, arr, selectedRevenueType);

      ////////////////////////////////// POLITICAL ZONE END /////////////////////////////////////////

      /////////////////////////////////// TOTAL  START ///////////////////////////////////

      const totalInterconnectPartnerPayment: any = sumObjectsByKey(...coreInterconnect2019PartnerPaymentArray, ...PoliticalInterconnect2019PartnerPaymentArray);

      const totalZonePartnerPayment: any = sumObjectsByKey(...coreZone2019PartnerPaymentArray, ...politicalZone2019PartnerPaymentArray);

      const totalMarketPartnerPayment: any = sumObjectsByKey(...totalInterconnectPartnerPayment, ...totalZonePartnerPayment);

      let totalInterconnectPartnerRevenue: any = sumObjectsByKey(...coreInterconnectPartnerRevenue, ...politicalInterconnectPartnerRevenue);

      totalInterconnectPartnerRevenue = getPercentage(totalInterconnectPartnerRevenue, arr);

      let totalZonePartnerRevenue: any = sumObjectsByKey(...coreZonePartnerRevenue, ...politicalZonePartnerRevenue);
      totalZonePartnerRevenue = getPercentage(totalZonePartnerRevenue, arr);

      let totalMarketPartnerRevenue: any = sumObjectsByKey(...totalInterconnectPartnerRevenue, ...totalZonePartnerRevenue);

      totalMarketPartnerRevenue = getPercentage(totalMarketPartnerRevenue, arr);
      setTotalMarketPartner2(totalMarketPartnerPayment);
      setTotalMarketRevenue2(totalMarketPartnerRevenue);
      setTotalInterconnectPartner2(totalInterconnectPartnerPayment);
      setTotalInterconnectRevenue2(totalInterconnectPartnerRevenue);
      setTotalZonePartner2(totalZonePartnerPayment);
      setTotalZoneRevenue2(totalZonePartnerRevenue);
      setCoreInterconnectPartner2(coreInterconnect2019PartnerPaymentArray);
      setCoreInterconnectRevenue2(coreInterconnectPartnerRevenue);
      setPoliticalInterconnectPartner2(PoliticalInterconnect2019PartnerPaymentArray);
      setPoliticalInterconnectRevenue2(politicalInterconnectPartnerRevenue);
      setCoreZonePartner2(coreZone2019PartnerPaymentArray);
      setCoreZoneRevenue2(coreZonePartnerRevenue);
      setPoliticalZonePartner2(politicalZone2019PartnerPaymentArray);
      setPoliticalZoneRevenue2(politicalZonePartnerRevenue);

      setLoading(false);
    }
    //////////////////////////////    CORE INTERCONNECT START /////////////////////////////////////////
  }, [data2]);
  useEffect(() => {
    if (data3 && data3.length > 0) {
      const coreInterconnect = data3.filter(
        (dataTableForm: { BiSyscodeTypeNm: string; MdlGrpNm: string }) => dataTableForm.BiSyscodeTypeNm === 'Interconnect' && dataTableForm.MdlGrpNm === 'CORE');
      //////////////////// CORE  INTERCONNECT PARTNER PAYMENT  START///////////////////////////
      const coreInterconnectPartnerPayment = coreInterconnect.filter( (obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

      const coreInterconnect2019PartnerPaymentArray: any = createPartnerPaymentGrid(coreInterconnectPartnerPayment, arr);

      ////////////////////  CORE INTERCONNECT PARTNER PAYMENT  END ///////////////////////////

      //////////////////// CORE INTERCONNECT REVENUE  START///////////////////////////

      const CoreInterconnectPartnerRevenue = coreInterconnect.filter( (obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

      const coreInterconnectPartnerRevenue: any = createPartnerRevenueGrid(CoreInterconnectPartnerRevenue, arr, selectedRevenueType);
      //////////////////// CORE INTERCONNECT REVENUE   END///////////////////////////

      //////////////////////////////    CORE INTERCONNECT END /////////////////////////////////////////

      ////////////////////////////////// POLITICAL INTERCONNECT START /////////////////////////////////////////

      /////////////////////////////////  POLITICAL INTERCONNECT PARTNER PAYMENT START ////////////////////////////

      const politicalInterconnect = data3.filter((dataTableForm2: { BiSyscodeTypeNm: string; MdlGrpNm: string }) => dataTableForm2.BiSyscodeTypeNm === 'Interconnect' && dataTableForm2.MdlGrpNm === 'POLITICAL');
      const politicalInterconnectPartnerPayment = politicalInterconnect.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

      const PoliticalInterconnect2019PartnerPaymentArray: any = createPartnerPaymentGrid(politicalInterconnectPartnerPayment, arr);

      /////////////////////////////////  POLITICAL INTERCONNECT PARTNER PAYMENT END ////////////////////////////

      /////////////////////////////////// POLITICAL INTERCONNECT  REVENUE  START///////////////////////////

      const PoliticalInterconnectPartnerRevenue = politicalInterconnect.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

      const politicalInterconnectPartnerRevenue: any = createPartnerRevenueGrid(PoliticalInterconnectPartnerRevenue, arr, selectedRevenueType);
      /////////////////////////////////// POLITICAL INTERCONNECT  REVENUE  END///////////////////////////

      ////////////////////////////////// POLITICAL INTERCONNECT END /////////////////////////////////////////

      ////////////////////////////////// CORE ZONE START /////////////////////////////////////////

      const coreZone = data3.filter((dataTableForm2: { BiSyscodeTypeNm: string; MdlGrpNm: string }) => dataTableForm2.BiSyscodeTypeNm === 'Zone' && dataTableForm2.MdlGrpNm === 'CORE');

      ////////////////////////////////// CORE ZONE PARTNER PAYMENT START //////////////////////////////
      const coreZonePartnerPayment = coreZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

      const coreZone2019PartnerPaymentArray: any = createPartnerPaymentGrid( coreZonePartnerPayment, arr);

      ////////////////////////////////// CORE ZONE PARTNER PAYMENT END //////////////////////////////

      ////////////////////////////////// CORE ZONE REVENUE START //////////////////////////////
      const CoreZonePartnerRevenue = coreZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

      const coreZonePartnerRevenue: any = createPartnerRevenueGrid( CoreZonePartnerRevenue, arr, selectedRevenueType);

      ////////////////////////////////// CORE ZONE END /////////////////////////////////////////

      ////////////////////////////////// POLITICAL ZONE START /////////////////////////////////////////

      const politicalZone = data3.filter( (dataTableForm3: { BiSyscodeTypeNm: string; MdlGrpNm: string }) => dataTableForm3.BiSyscodeTypeNm === 'Zone' && dataTableForm3.MdlGrpNm === 'POLITICAL');

      //////////////////////////////////  POLITICAL ZONE PARTNER PAYMENT ///////////////////////////////////

      const politicalZonePartnerPayment = politicalZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm !== selectedRevenueType.trim());

      const politicalZone2019PartnerPaymentArray: any = createPartnerPaymentGrid(politicalZonePartnerPayment, arr);

      //////////////////////////////// POLITICAL ZONE  REVENUE START //////////////////////
      const PoliticalZonePartnerRevenue = politicalZone.filter((obj: { BiRevlineNm: any }) => obj.BiRevlineNm === selectedRevenueType.trim());

      const politicalZonePartnerRevenue: any = createPartnerRevenueGrid(PoliticalZonePartnerRevenue, arr, selectedRevenueType);

      ////////////////////////////////// POLITICAL ZONE END /////////////////////////////////////////

      /////////////////////////////////// TOTAL  START ///////////////////////////////////

      const totalInterconnectPartnerPayment: any = sumObjectsByKey(...coreInterconnect2019PartnerPaymentArray, ...PoliticalInterconnect2019PartnerPaymentArray);

      const totalZonePartnerPayment: any = sumObjectsByKey(...coreZone2019PartnerPaymentArray, ...politicalZone2019PartnerPaymentArray);

      const totalMarketPartnerPayment: any = sumObjectsByKey(...totalInterconnectPartnerPayment, ...totalZonePartnerPayment);

      let totalInterconnectPartnerRevenue: any = sumObjectsByKey(...coreInterconnectPartnerRevenue, ...politicalInterconnectPartnerRevenue);

      totalInterconnectPartnerRevenue = getPercentage(totalInterconnectPartnerRevenue, arr);

      let totalZonePartnerRevenue: any = sumObjectsByKey(...coreZonePartnerRevenue, ...politicalZonePartnerRevenue);
      totalZonePartnerRevenue = getPercentage(totalZonePartnerRevenue, arr);

      let totalMarketPartnerRevenue: any = sumObjectsByKey(...totalInterconnectPartnerRevenue, ...totalZonePartnerRevenue);


      /////////////////////////////////// TOTAL  START ///////////////////////////////////
      totalMarketPartnerRevenue = getPercentage(totalMarketPartnerRevenue, arrCompare);
      setTotalMarketPartner3(totalMarketPartnerPayment);
      setTotalMarketRevenue3(totalMarketPartnerRevenue);
      setTotalInterconnectPartner3(totalInterconnectPartnerPayment);
      setTotalInterconnectRevenue3(totalInterconnectPartnerRevenue);
      setTotalZonePartner3(totalZonePartnerPayment);
      setTotalZoneRevenue3(totalZonePartnerRevenue);
      setCoreInterconnectPartner3(coreInterconnect2019PartnerPaymentArray);
      setCoreInterconnectRevenue3(coreInterconnectPartnerRevenue);
      setPoliticalInterconnectPartner3(PoliticalInterconnect2019PartnerPaymentArray);
      setPoliticalInterconnectRevenue3(politicalInterconnectPartnerRevenue);
      setCoreZonePartner3(coreZone2019PartnerPaymentArray);
      setCoreZoneRevenue3(coreZonePartnerRevenue);
      setPoliticalZonePartner3(politicalZone2019PartnerPaymentArray);
      setPoliticalZoneRevenue3(politicalZonePartnerRevenue);
      setLoading(false);
    }
  }, [data3]);

  // =========================================EXPORT==================================
  const [openExport, setOpenExport] = React.useState(false);
  

  const handleOpenExport:any = () => {
    setOpenExport(true);
  };

  const handleCloseExport:any = () => {
    setOpenExport(false);
  };
  const exportPerformanceData:any = () => {
    dispatch(exportData());
    setOpenExport(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    setValue(newValue);
  };

  return (
    <>
      <Typography component="div" className={classes.mainDiv}>
        <Typography component="div" className={classes.contentDiv}>
          <Typography component="h1" className={classes.pageTitle}>
            Performance Dashboard
          </Typography>
          <Typography component="div" className={classes.dropdownBox}>
            <>
              <Typography component="div" className={classes.filtersTitle}>
                Filters
              </Typography>
              <Typography component="div" style={{ display:'flex', flexWrap:'wrap' }}>

              <DatePopOver />
              {revenueData.length > 0 ? (
                <MultiSelect
                  data={revenueData}
                  label_select={'Revenue Type'}
                  selectedData={selectedRevenueType}
                  setSelectedData={setSelectedRevenueType}
                  exportComponent={false}
                />
              ) : (
                'Fetching Report data'
              )}

              <ComboBox
                label="Affiliate"
                placeholder="Placeholder for textbox"
                selectAllLabel="Select all"
                setAffiliateDropDown={setAffiliateDropDown}
                component='Performance'
              />

              {salesMarkets !== null ? (
                <Salesfilter
                  label="Sales Market"
                  placeholder="Placeholder for textbox"
                  selectAllLabel="Select all"
                />
              ) : (
                'Fetching Sales markets'
              )}

              <Button
                variant="contained"
                fullWidth={true}
                className={classes.applyButton}
                onClick={() => Apply()}
              >
                Apply
              </Button>
              </Typography>


              {dataChanged ? (
                <Typography component="div" className={classes.snack}>
                  <InfoIcon />
                  <Typography component="div">
                    You have unsaved changes.Please click{' '}
                    <Typography className={classes.applySpan} component="span">
                      “Apply”
                    </Typography>{' '}
                    to continue.
                  </Typography>
                </Typography>
              ) : null}
            </>
          </Typography>
        </Typography>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext  value={value}>
            <Box>
              <TabList
              onChange={handleChange}
                className={classes.tabList}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons={false}
              >
                <Tab label="Affiliate Summary" className={classes.tabList} value='1' />
                <Tab label="Interconnect" className={classes.tabList} value='2'
                />
                <Tab label="Zone" className={classes.tabList} value='3'
                />
              </TabList>
            </Box>
            {/* ==================================AFFILIATE SUMMARY======================================== */}
            <TabPanel value='1' className={classes.tabPanel}>
              <Typography 
                component="h1" 
                className={classes.affiliateSummaryTitle}
              >
                Affiliate Summary
              </Typography>
              <Accordion defaultExpanded={true} className={classes.MainAccordion}>
                <AccordionSummary
                  expandIcon={<AccordionArrow />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className= {classes.Accordion}
                >
                  <Typography component="h1" className={classes.totalMarketTitle}>
                  Full Year {selectedYear}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography component="h1" className={classes.revenueType}>
                {selectedRevenueType}
              </Typography>
              <>
                {weeklyRevenuesFetched && !loading ? (
                  <Graph
                    gridData={totalMarketRevenue}
                    keys={arr}
                    gridData2={totalMarketRevenue2}
                    compareFor={totalMarketRevenue[1].type}
                  />
                ) : (
                  ''
                )}

                {!isMatch ? (
                  <>
                    {weeklyRevenuesFetched && !loading ? (
                      <Tables
                        gridData={totalMarketRevenue}
                        keys={arr}
                        heading={selectedRevenueType.trim()}
                      />
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    {totalMarketRevenue.length > 3 ? (
                      <>
                        <Monthly
                          gridData={totalMarketRevenue}
                          gridData2={totalMarketRevenue2}
                          gridData3={totalMarketRevenue3}
                          keys={arr}
                          keysCompare={keysCompare}
                        />
                        <Yearly
                          gridData={totalMarketRevenue}
                          gridData2={totalMarketRevenue2}
                          gridData3={totalMarketRevenue3}
                          keys={arr}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>

              <Typography component="h1" className={classes.partnerPaymentTitle}>
                Partner Payment{' '}
              </Typography>
              <>
                {weeklyRevenuesFetched && !loading ? (
                  <Graph
                    gridData={totalMarketPartner}
                    gridData2={totalMarketPartner2}
                    keys={arr}
                    compareFor='Budgeted Affiliate Rev Share'

                  />
                ) : (
                  ''
                )}
                {!isMatch && !loading ? (
                  <>
                    <Tables
                      gridData={totalMarketPartner}
                      keys={arr}
                      heading={selectedRevenueType.trim()}
                    />
                  </>
                ) : (
                  <>
                    {totalMarketPartner.length > 4 ? (
                      <>
                        <Monthly
                          gridData={totalMarketPartner}
                          gridData2={totalMarketPartner2}
                          gridData3={totalMarketPartner3}
                          keys={arr}
                          keysCompare={keysCompare}
                        />
                        <Yearly
                          gridData={totalMarketPartner}
                          gridData2={totalMarketPartner2}
                          gridData3={totalMarketPartner3}
                          keys={arr}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
                </AccordionDetails>
              </Accordion>
                      {/* Total Core */}
              <Accordion  className={classes.MainAccordion}>
              <AccordionSummary
                expandIcon={<AccordionArrow/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className= {classes.Accordion}
              >
                <Typography component="h1" className={classes.totalMarketTitle}>
                  Total Core {selectedYear}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography component="h1" className={classes.revenueType}>
                {selectedRevenueType}
              </Typography>
              <>
                {weeklyRevenuesFetched && !loading ? (
                  <Graph
                    gridData={totalCorePartnerRevenue}
                    gridData2={totalCorePartnerRevenue}
                    keys={arr}
                    compareFor={totalCorePartnerRevenue[1].type}
                    
                  />
                ) : (
                  ''
                )}
                {!isMatch ? (
                  <>
                    {weeklyRevenuesFetched && !loading ? (
                      <Tables
                        gridData={totalCorePartnerRevenue}
                        keys={arr}
                        heading={selectedRevenueType.trim()}
                      />
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
              </>

              <Typography component="h1" className={classes.partnerPaymentTitle}>
                Partner Payment{' '}
              </Typography>
              <>
                <Graph
                  gridData={totalCorePartnerPayment}
                  gridData2={totalCorePartnerPayment}
                  keys={arr}
                  compareFor='Budgeted Affiliate Rev Share'

                />
                {!isMatch && weeklyRevenuesFetched && !loading ? (
                  <>
                    <Tables
                      gridData={totalCorePartnerPayment}
                      keys={arr}
                      heading={'Partner Payment'}
                    />
                  </>
                ) : (
                  ''
                )}
              </>
              </AccordionDetails>
              </Accordion>
                      {/* Total Zone */}
              <Accordion  className={classes.MainAccordion}>
              <AccordionSummary
                expandIcon={<AccordionArrow/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className= {classes.Accordion}
              >
                <Typography component="h1" className={classes.totalMarketTitle}>
                  Total Political {selectedYear}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography component="h1" className={classes.revenueType}>
                {selectedRevenueType}
              </Typography>
              <>
                {weeklyRevenuesFetched && !loading ? (
                  <Graph
                    gridData={totalPoliticalPartnerRevenue}
                    gridData2={totalPoliticalPartnerRevenue}
                    keys={arr}
                    compareFor={totalPoliticalPartnerRevenue[1].type}
                    
                  />
                ) : (
                  ''
                )}
                {!isMatch ? (
                  <>
                    {weeklyRevenuesFetched && !loading ? (
                      <Tables
                        gridData={totalPoliticalPartnerRevenue}
                        keys={arr}
                        heading={selectedRevenueType.trim()}
                      />
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
              </>

              <Typography component="h1" className={classes.partnerPaymentTitle}>
                Partner Payment{' '}
              </Typography>
              <>
                <Graph
                  gridData={totalPoliticalPartnerPayment}
                  gridData2={totalPoliticalPartnerPayment}
                  keys={arr}
                  compareFor='Budgeted Affiliate Rev Share'

                />
                {!isMatch && weeklyRevenuesFetched && !loading ? (
                  <>
                    <Tables
                      gridData={totalPoliticalPartnerPayment}
                      keys={arr}
                      heading={'Partner Payment'}
                    />
                  </>
                ) : (
                  ''
                )}
              </>
              </AccordionDetails>
              </Accordion>
              
            </TabPanel>

            {/* ==================================INTERCONNECT======================================== */}
            <TabPanel value='2' className={classes.tabPanel}>
              <Typography component="h1" className={classes.affiliateSummaryTitle}>
                Interconnect
              </Typography>
              <Accordion defaultExpanded={true} className={classes.MainAccordion}>
              <AccordionSummary
                expandIcon={<AccordionArrow/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className= {classes.Accordion}
              >
                <Typography component="h1" className={classes.totalMarketTitle}>
                  Total Interconnect {selectedYear}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography component="h1" className={classes.revenueType}>
                {selectedRevenueType}
              </Typography>
              <>
                {weeklyRevenuesFetched && !loading ? (
                  <Graph
                    gridData={totalInterconnectRevenue}
                    gridData2={totalInterconnectRevenue2}
                    keys={arr}
                    compareFor={totalInterconnectRevenue[1].type}
                  />
                ) : (
                  ''
                )}
                {!isMatch ? (
                  <>
                    {weeklyRevenuesFetched && !loading ? (
                      <Tables
                        gridData={totalInterconnectRevenue}
                        keys={arr}
                        heading={selectedRevenueType.trim()}
                      />
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    {totalInterconnectRevenue.length > 3 ? (
                      compare ? (
                        totalInterconnectRevenue3 &&
                        totalInterconnectRevenue3.length > 3 ? (
                            <>
                              <Monthly
                                gridData={totalInterconnectRevenue}
                                gridData2={totalInterconnectRevenue2}
                                gridData3={totalInterconnectRevenue3}
                                keys={arr}
                                keysCompare={keysCompare}
                              />
                              <Yearly
                                gridData={totalInterconnectRevenue}
                                gridData2={totalInterconnectRevenue2}
                                gridData3={totalInterconnectRevenue3}
                                keys={arr}
                              />
                            </>
                          ) : (
                            ''
                          )
                      ) : (
                        <>
                          <Monthly
                            gridData={totalInterconnectRevenue}
                            gridData2={[]}
                            keys={arr}
                            keysCompare={keysCompare}
                          />
                          <Yearly
                            gridData={totalInterconnectRevenue}
                            gridData2={[]}
                            keys={arr}
                          />
                        </>
                      )
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>

              <Typography component="h1" className={classes.partnerPaymentTitle}>
                Partner Payment{' '}
              </Typography>
              <>
                <Graph
                  gridData={totalInterconnectPartner}
                  gridData2={totalInterconnectPartner2}
                  keys={arr}
                  compareFor='Budgeted Affiliate Rev Share'

                />
                {!isMatch && weeklyRevenuesFetched && !loading ? (
                  <>
                    <Tables
                      gridData={totalInterconnectPartner}
                      keys={arr}
                      heading={'Partner Payment'}
                    />
                  </>
                ) : (
                  <>
                    {totalMarketPartner.length > 4 ? (
                      <>
                        <Monthly
                          gridData={totalInterconnectPartner}
                          gridData2={totalInterconnectPartner2}
                          gridData3={totalInterconnectPartner3}
                          keys={arr}
                          keysCompare={keysCompare}
                        />
                        <Yearly
                          gridData={totalInterconnectPartner}
                          gridData2={totalInterconnectPartner2}
                          gridData3={totalInterconnectPartner3}
                          keys={arr}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
              </AccordionDetails>
              </Accordion>

              {/* CORE  */}
              <Accordion  className={classes.MainAccordion}>
              <AccordionSummary
                expandIcon={<AccordionArrow/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className= {classes.Accordion}
              >
                <Typography component="h1" className={classes.totalMarketTitle}>
                  Core Interconnect {selectedYear}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography component="h1" className={classes.revenueType}>
                {selectedRevenueType}
              </Typography>
              <>
                {weeklyRevenuesFetched && !loading ? (
                  <Graph
                    gridData={coreInterconnectRevenue}
                    gridData2={coreInterconnectRevenue2}
                    keys={arr}
                    compareFor={coreInterconnectRevenue[1].type}
                    
                  />
                ) : (
                  ''
                )}
                {!isMatch ? (
                  <>
                    {weeklyRevenuesFetched && !loading ? (
                      <Tables
                        gridData={coreInterconnectRevenue}
                        keys={arr}
                        heading={selectedRevenueType.trim()}
                      />
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    {coreInterconnectRevenue.length > 3 ? (
                      compare ? (
                        coreInterconnectRevenue3 &&
                        coreInterconnectRevenue3.length > 3 ? (
                            <>
                              <Monthly
                                gridData={coreInterconnectRevenue}
                                gridData2={coreInterconnectRevenue2}
                                gridData3={coreInterconnectRevenue3}
                                keys={arr}
                                keysCompare={keysCompare}
                              />
                              <Yearly
                                gridData={coreInterconnectRevenue}
                                gridData2={coreInterconnectRevenue2}
                                gridData3={coreInterconnectRevenue3}
                                keys={arr}
                              />
                            </>
                          ) : (
                            ''
                          )
                      ) : (
                        <>
                          <Monthly
                            gridData={coreInterconnectRevenue}
                            gridData2={[]}
                            keys={arr}
                            keysCompare={keysCompare}
                          />
                          <Yearly
                            gridData={coreInterconnectRevenue}
                            gridData2={[]}
                            keys={arr}
                          />
                        </>
                      )
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>

              <Typography component="h1" className={classes.partnerPaymentTitle}>
                Partner Payment{' '}
              </Typography>
              <>
                <Graph
                  gridData={coreInterconnectPartner}
                  gridData2={coreInterconnectPartner2}
                  keys={arr}
                  compareFor='Budgeted Affiliate Rev Share'

                />
                {!isMatch && weeklyRevenuesFetched && !loading ? (
                  <>
                    <Tables
                      gridData={coreInterconnectPartner}
                      keys={arr}
                      heading={'Partner Payment'}
                    />
                  </>
                ) : (
                  <>
                    {coreInterconnectPartner.length > 4 ? (
                      <>
                        <Monthly
                          gridData={coreInterconnectPartner}
                          gridData2={coreInterconnectPartner2}
                          gridData3={coreInterconnectPartner3}
                          keys={arr}
                          keysCompare={keysCompare}
                        />
                        <Yearly
                          gridData={coreInterconnectPartner}
                          gridData2={coreInterconnectPartner2}
                          gridData3={coreInterconnectPartner3}
                          keys={arr}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
              </AccordionDetails>
              </Accordion>

              {/* InterConnect  */}
              <Accordion  className={classes.MainAccordion}>
              <AccordionSummary
                expandIcon={<AccordionArrow/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className= {classes.Accordion}
              >
                <Typography component="h1" className={classes.totalMarketTitle}>
                  Political Interconnect {selectedYear}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography component="h1" className={classes.revenueType}>
                {selectedRevenueType}
              </Typography>
              <>
                {weeklyRevenuesFetched && !loading ? (
                  <Graph
                    gridData={politicalInterconnectRevenue}
                    gridData2={politicalInterconnectRevenue2}
                    keys={arr}
                    compareFor={politicalInterconnectRevenue[1].type}

                  />
                ) : (
                  ''
                )}
                {!isMatch ? (
                  <>
                    {weeklyRevenuesFetched && !loading ? (
                      <Tables
                        gridData={politicalInterconnectRevenue}
                        keys={arr}
                        heading={selectedRevenueType.trim()}
                      />
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    {politicalInterconnectRevenue.length > 3 ? (
                      compare ? (
                        politicalInterconnectRevenue3 &&
                        politicalInterconnectRevenue3.length > 3 ? (
                            <>
                              <Monthly
                                gridData={politicalInterconnectRevenue}
                                gridData2={politicalInterconnectRevenue2}
                                gridData3={politicalInterconnectRevenue3}
                                keys={arr}
                                keysCompare={keysCompare}
                              />
                              <Yearly
                                gridData={politicalInterconnectRevenue}
                                gridData2={politicalInterconnectRevenue2}
                                gridData3={politicalInterconnectRevenue3}
                                keys={arr}
                              />
                            </>
                          ) : (
                            ''
                          )
                      ) : (
                        <>
                          <Monthly
                            gridData={politicalInterconnectRevenue}
                            gridData2={[]}
                            keys={arr}
                            keysCompare={keysCompare}
                          />
                          <Yearly
                            gridData={politicalInterconnectRevenue}
                            gridData2={[]}
                            keys={arr}
                          />
                        </>
                      )
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>

              <Typography component="h1" className={classes.partnerPaymentTitle}>
                Partner Payment{' '}
              </Typography>
              <>
                <Graph
                  gridData={politicalInterconnectPartner}
                  gridData2={politicalInterconnectPartner2}
                  keys={arr}
                  compareFor='Budgeted Affiliate Rev Share'

                />
                {!isMatch && weeklyRevenuesFetched && !loading ? (
                  <>
                    <Tables
                      gridData={politicalInterconnectPartner}
                      keys={arr}
                      heading={'Partner Payment'}
                    />
                  </>
                ) : (
                  <>
                    {politicalInterconnectPartner.length > 4 ? (
                      <>
                        <Monthly
                          gridData={politicalInterconnectPartner}
                          gridData2={politicalInterconnectPartner2}
                          gridData3={politicalInterconnectPartner3}
                          keys={arr}
                          keysCompare={keysCompare}
                        />
                        <Yearly
                          gridData={politicalInterconnectPartner}
                          gridData2={politicalInterconnectPartner2}
                          gridData3={politicalInterconnectPartner3}
                          keys={arr}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
              </AccordionDetails>
              </Accordion>
              
            </TabPanel>

            {/* ==================================ZONE======================================== */}
            <TabPanel value='3' className={classes.tabPanel}>
              <Typography component="h1" className={classes.affiliateSummaryTitle}>
                Zone
              </Typography>
              <Accordion defaultExpanded={true} className={classes.MainAccordion}>
              <AccordionSummary
                expandIcon={<AccordionArrow />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.Accordion}
              >
                <Typography component="h1" className={classes.totalMarketTitle}>
                  Total Zone {selectedYear}
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography component="h1" className={classes.revenueType}>
                {selectedRevenueType}
              </Typography>
              <>
                {weeklyRevenuesFetched && !loading ? (
                  <Graph
                    gridData={totalZoneRevenue}
                    gridData2={totalZoneRevenue2}
                    keys={arr}
                    compareFor={totalZoneRevenue[1].type}

                  />
                ) : (
                  ''
                )}
                {!isMatch ? (
                  <>
                    {weeklyRevenuesFetched && !loading ? (
                      <Tables
                        gridData={totalZoneRevenue}
                        keys={arr}
                        heading={selectedRevenueType.trim()}
                      />
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    {totalZoneRevenue.length > 3 ? (
                      <>
                        <Monthly
                          gridData={totalZoneRevenue}
                          gridData2={totalZoneRevenue2}
                          gridData3={totalZoneRevenue3}
                          keys={arr}
                          keysCompare={keysCompare}
                        />
                        <Yearly
                          gridData={totalZoneRevenue}
                          gridData2={totalZoneRevenue2}
                          gridData3={totalZoneRevenue3}
                          keys={arr}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>

              <Typography component="h1" className={classes.partnerPaymentTitle}>
                Partner Payment{' '}
              </Typography>
              <>
                {weeklyRevenuesFetched && !loading ? (
                  <Graph
                    gridData={totalZonePartner}
                    gridData2={totalZonePartner2}
                    keys={arr}
                    compareFor='Budgeted Affiliate Rev Share'

                  />
                ) : (
                  ''
                )}
                {!isMatch && weeklyRevenuesFetched && !loading ? (
                  <>
                    <Tables
                      gridData={totalZonePartner}
                      keys={arr}
                      heading={selectedRevenueType.trim()}
                    />
                  </>
                ) : (
                  <>
                    {totalZonePartner.length > 4 ? (
                      <>
                        <Monthly
                          gridData={totalZonePartner}
                          gridData2={totalZonePartner2}
                          gridData3={totalZonePartner3}
                          keys={arr}
                          keysCompare={keysCompare}
                        />
                        <Yearly
                          gridData={totalZonePartner}
                          gridData2={totalZonePartner2}
                          gridData3={totalZonePartner3}
                          keys={arr}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
                </AccordionDetails>
              </Accordion>

              {/* CORE  */}
              <Accordion className={classes.MainAccordion}>
              <AccordionSummary
                expandIcon={<AccordionArrow/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className= {classes.Accordion}
              >
                <Typography component="h1" className={classes.totalMarketTitle}>
                  Core Zone {selectedYear}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography component="h1" className={classes.revenueType}>
                {selectedRevenueType}
              </Typography>
              <>
                {weeklyRevenuesFetched && !loading ? (
                  <Graph
                    gridData={coreZoneRevenue}
                    gridData2={coreZoneRevenue2}
                    keys={arr}
                    compareFor={coreZoneRevenue[1].type}

                  />
                ) : (
                  ''
                )}
                {!isMatch ? (
                  <>
                    {weeklyRevenuesFetched && !loading ? (
                      <Tables
                        gridData={coreZoneRevenue}
                        keys={arr}
                        heading={selectedRevenueType.trim()}
                      />
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    {coreZoneRevenue.length > 3 ? (
                      compare ? (
                        coreZoneRevenue3 &&
                        coreZoneRevenue3.length > 3 ? (
                            <>
                              <Monthly
                                gridData={coreZoneRevenue}
                                gridData2={coreZoneRevenue2}
                                gridData3={coreZoneRevenue3}
                                keys={arr}
                                keysCompare={keysCompare}
                              />
                              <Yearly
                                gridData={coreZoneRevenue}
                                gridData2={coreZoneRevenue2}
                                gridData3={coreZoneRevenue3}
                                keys={arr}
                              />
                            </>
                          ) : (
                            ''
                          )
                      ) : (
                        <>
                          <Monthly
                            gridData={coreZoneRevenue}
                            gridData2={[]}
                            keys={arr}
                            keysCompare={keysCompare}
                          />
                          <Yearly
                            gridData={coreZoneRevenue}
                            gridData2={[]}
                            keys={arr}
                          />
                        </>
                      )
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>

              <Typography component="h1" className={classes.partnerPaymentTitle}>
                Partner Payment{' '}
              </Typography>
              <>
                <Graph
                  gridData={coreZonePartner}
                  gridData2={coreZonePartner2}
                  keys={arr}
                  compareFor='Budgeted Affiliate Rev Share'

                />
                {!isMatch && weeklyRevenuesFetched && !loading ? (
                  <>
                    <Tables
                      gridData={coreZonePartner}
                      keys={arr}
                      heading={'Partner Payment'}
                    />
                  </>
                ) : (
                  <>
                    {coreZonePartner.length > 4 ? (
                      <>
                        <Monthly
                          gridData={coreZonePartner}
                          gridData2={coreZonePartner2}
                          gridData3={coreZonePartner3}
                          keys={arr}
                          keysCompare={keysCompare}
                        />
                        <Yearly
                          gridData={coreZonePartner}
                          gridData2={coreZonePartner2}
                          gridData3={coreZonePartner3}
                          keys={arr}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
              </AccordionDetails>
              </Accordion>

              {/* Political  */}
              <Accordion className={classes.MainAccordion}>
              <AccordionSummary
                expandIcon={<AccordionArrow/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className= {classes.Accordion}
              >
                <Typography component="h1" className={classes.totalMarketTitle}>
                  Political Zone {selectedYear}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography component="h1" className={classes.revenueType}>
                {selectedRevenueType}
              </Typography>
              <>
                {weeklyRevenuesFetched && !loading ? (
                  <Graph
                    gridData={politicalZoneRevenue}
                    gridData2={politicalZoneRevenue2}
                    keys={arr}
                    compareFor={politicalZoneRevenue[1].type}

                  />
                ) : (
                  ''
                )}
                {!isMatch ? (
                  <>
                    {weeklyRevenuesFetched && !loading ? (
                      <Tables
                        gridData={politicalZoneRevenue}
                        keys={arr}
                        heading={selectedRevenueType.trim()}
                      />
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    {politicalZoneRevenue.length > 3 ? (
                      compare ? (
                        politicalZoneRevenue3 &&
                        politicalZoneRevenue3.length > 3 ? (
                            <>
                              <Monthly
                                gridData={politicalZoneRevenue}
                                gridData2={politicalZoneRevenue2}
                                gridData3={politicalZoneRevenue3}
                                keys={arr}
                                keysCompare={keysCompare}
                              />
                              <Yearly
                                gridData={politicalZoneRevenue}
                                gridData2={politicalZoneRevenue2}
                                gridData3={politicalZoneRevenue3}
                                keys={arr}
                              />
                            </>
                          ) : (
                            ''
                          )
                      ) : (
                        <>
                          <Monthly
                            gridData={politicalZoneRevenue}
                            gridData2={[]}
                            keys={arr}
                            keysCompare={keysCompare}
                          />
                          <Yearly
                            gridData={politicalZoneRevenue}
                            gridData2={[]}
                            keys={arr}
                          />
                        </>
                      )
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>

              <Typography component="h1" className={classes.partnerPaymentTitle}>
                Partner Payment{' '}
              </Typography>
              <>
                <Graph
                  gridData={politicalZonePartner}
                  gridData2={politicalZonePartner2}
                  keys={arr}
                  compareFor='Budgeted Affiliate Rev Share'

                />
                {!isMatch && weeklyRevenuesFetched && !loading ? (
                  <>
                    <Tables
                      gridData={politicalZonePartner}
                      keys={arr}
                      heading={'Partner Payment'}
                    />
                  </>
                ) : (
                  <>
                    {politicalZonePartner.length > 4 ? (
                      <>
                        <Monthly
                          gridData={politicalZonePartner}
                          gridData2={politicalZonePartner2}
                          gridData3={politicalZonePartner3}
                          keys={arr}
                          keysCompare={keysCompare}
                        />
                        <Yearly
                          gridData={politicalZonePartner}
                          gridData2={politicalZonePartner2}
                          gridData3={politicalZonePartner3}
                          keys={arr}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
              </AccordionDetails>
              </Accordion>

            </TabPanel>
          </TabContext>
        </Box>
      </Typography>
      <Typography component="div" className={classes.download}>
        <Button
          variant="contained"
          className={classes.downloadButton}
          onClick={handleOpenExport}
        >
          <Download />
          Download report
        </Button>
        <Dialog
          open={openExport}
          onClose={handleCloseExport}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Typography className={classes.closeExport}>
            <CloseIconModals setSnackClose={handleCloseExport} />
          </Typography>
          <DialogTitle id="alert-dialog-title" className={classes.exportTitle}>
            {'Export Report'}
          </DialogTitle>
          <DialogContent className={classes.exportDailogue}>
            <DialogContentText id="alert-dialog-description">
              <Typography className={classes.exportYearWeeks}>
                <ExportDate />
                {revenueData.length > 0 ? (
                  <MultiSelect
                    data={revenueData}
                    label_select={'Revenue Type'}
                    selectedData={selectedRevenueType}
                    setSelectedData={setSelectedRevenueType}
                    exportComponent={true}
                  />
                ) : (
                  'Fetching Report data'
                )}
                <ExportAffiliateBox 
                 label="Affiliate"
                 placeholder="Affiliates"
                 selectAllLabel="Select all"
                 setAffiliateDropDown={setAffiliateDropDown}
                 />
                <ExportSalesBox label="Sales Market"
                  placeholder="Placeholder for textbox"
                  selectAllLabel="Select all" />
              </Typography>
            </DialogContentText>
            <Button
              variant="contained"
              fullWidth={true}
              className={classes.ExportConfirmButton}
              onClick={exportPerformanceData}
              autoFocus
            >
              Confirm Export
            </Button>
          </DialogContent>
        </Dialog>
      </Typography>
    </>
  );
};

export default Performance;
