import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { addUserStyles } from './styles';
import { useMediaQuery, useTheme } from '@mui/material';
import CloseIconModals from '../../Media/Svg/CloseIconModals';
import { TextField, Typography } from '@mui/material';
import '../../../components/Login/Login.css';
import { addAffiliate } from '../../../redux';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useEffect } from 'react';
import ComboBox from '../../Filters/AffiliateSalesFilter/AffiliateSalesFilter';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { validate, res } from '../emailValidate';

interface Headervalue {
  UserName: string;
  Email: string;
  UserEmail: string;
  Password: string;
  IsNewPassword: boolean;
  AffiliateName: Array<string>;
}

export default function AddUserModal(): any {
  const [open, setOpen] = useState(false);
  const [passCopied, setPassCopied] = useState(false);
  const [password, setPassword] = useState({});
  const handleOpen: any = () => setOpen(true);
  const [selectedAffiliates, setSelectedAffiliates] = useState([]);
  const [addFormData, setAddFormData] = useState({
    username: '',
    email: '',
  });
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passCreated, setPassCreated] = useState(false);

  const classes = addUserStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(616));
  const dispatch = useAppDispatch();
  const affiliates = useAppSelector((state) => state.support.affiliateArray);
  const { handleSubmit } = useForm();

  const affArray: any = [
    {
      id: 'All',
      value: '*',
      label: 'All',
    },
  ];

  useEffect(() => {
    affiliates.forEach((obj: any) => {
      affArray.push({
        id: obj.ID,
        value: obj.AffiliateName,
        label: obj.AffiliateName,
      });
    });
  }, []);

  const handleCloseAdd: any = () => {
    setOpen(false);
    setPassword('');
    setAddFormData({ username: '', email: '' });
    setEmailError(false);
    setPassCreated(false);
    setPassCopied(false);

  };

  const validateEmail = ():any => {
    // Regular expression pattern for email validation
    validate(email); 
    if (res) {
      // Email is valid
      setEmailError(false);
    } else {
      // Email is invalid
      setEmailError(true);
    }
  };

  useEffect(() => {
    validateEmail();
  }, [email]);

  const handleAddFormChange: any = (event: any) => {
    const fieldName: string = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData: any = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
    setEmail(event.target.value);
    validateEmail();
  };


  const handleAddFormSubmit: any = () => {
    const affiliateNames: any = [];
    selectedAffiliates.forEach((obj: any) => {
      affiliateNames.push(obj.value);
    });

    if (affiliateNames[0] === '*') {
      affiliateNames.shift();
    }

    const headervalue: Headervalue = {
      UserName: addFormData.username,
      Email: addFormData.email,
      Password: password.toString(),
      IsNewPassword: true,
      AffiliateName: affiliateNames,
      UserEmail: addFormData.email,
    };
    dispatch(addAffiliate(headervalue));
    handleCloseAdd();
    setPassCreated(false);
    setPassCopied(false);
    setPassword({});
    setAddFormData({ username: '', email: '' });

  };

  ////////////////////////////// Generate Password Code /////////////////////////////////
  

  const generatePassword: any = () => {
    // Define the length of the password
    const passwordLength = 8;

    // Create a random password
    const randomPassword =
      Math.random().toString(36).substring(2, passwordLength + 2);

    // Set the generated password as state
    setPassword(randomPassword);

    // Copy the password to the clipboard
    navigator.clipboard.writeText(randomPassword);
    setPassCreated(true);
  };

  const copyPassword: any = () => {
    setPassCopied(true);
  };
  /////////////////////////////////// GENERATE PASSWORD END /////////////////////////////////////

  return (
    <div>
      <Button
        onClick={handleOpen}
        className={classes.addUser}
        variant='contained'
      >
        {!isMatch ? ' + Add User' : '+'}
      </Button>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleCloseAdd}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.modal}>
            <CloseIconModals setSnackClose={handleCloseAdd} />

            <Typography
              id='transition-modal-title'
              variant='h6'
              component='h2'
              className={classes.modalTitle}
            >
              Add New User
            </Typography>
            <form action='' onSubmit={handleSubmit(handleAddFormSubmit)}>

              <TextField
                required
                className={` red-asterik ${classes.textFields}`}
                size='small'
                id='outlined-basic'
                name='username'
                label='Username'
                variant='outlined'
                onChange={handleAddFormChange}
              />
              <ComboBox
                label='Affiliate'
                placeholder='Placeholder for textbox'
                selectAllLabel='Select all'
                setAffiliateDropDown={setSelectedAffiliates}
                component='AddUser'
              />
              <TextField
                required
                className={`red-asterik ${classes.textFields}`}
                size="small"
                id="outlined-basic"
                name="email"
                label="Email Address"
                variant="outlined"
                onInput={handleAddFormChange}
                error={emailError}
                helperText={emailError && 'Invalid email address'}
              />

              <div>
                {passCreated ? (
                  <div className='password-input'>
                    <TextField
                      id='outlined-basic'
                      label='Temporary Password'
                      variant='outlined'
                      size='small'
                      value={password}
                      className={classes.textFields}
                    />

                    {passCopied ? (
                      ''
                    ) : (
                      <Button
                        className={classes.copyButton}
                        variant='contained'
                        onClick={copyPassword}
                      >
                        Copy
                      </Button>
                    )}
                  </div>
                ) : (
                  ''
                )}

                {passCopied ? (
                  ''
                ) : (
                  addFormData?.username?.length >= 1 && addFormData?.email?.length >= 1 && !emailError ? (
                    <Button
                      variant='contained'
                      className={classes.updateButton}
                      onClick={() =>{validateEmail(); generatePassword();}}
                    >
                      Create Password
                    </Button>
                  ) : (
                    <Button
                      variant='contained'
                      className={classes.updateButton}
                      onClick={() => generatePassword()}
                      disabled
                    >
                      Create Password
                    </Button>
                  )
                )}
              </div>
              {passCopied &&  (
              <Button
              variant='contained'
              className={classes.updateButton}
              disabled={emailError}
              onClick={handleSubmit(handleAddFormSubmit)}
              >
              Add Profile
              </Button>
              )}
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
