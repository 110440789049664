import { Dispatch } from 'redux';
import { LOGOUT } from '../../rootReducer/constants';
import secureLocalStorage from 'react-secure-storage';

const logoutUser:any = () => {
  return {
    type: LOGOUT,
  };
};

export const logout:any = () => {
  secureLocalStorage.clear();
  return function (dispatch: Dispatch) {
    dispatch(logoutUser());
  };
};