import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const affiliateFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterMain: {
      minWidth: '16.3%',
      width: '16.3%',

      [theme.breakpoints.down(920)]: {
        width: '100%',
        marginRight: '0px!important',
      },
    },
  }),
);

export const affiliateFilterStylesUSer = makeStyles((theme: Theme) =>
  createStyles({
    filterMain: {
      minWidth: '16.3%',
      width: '100%',

      [theme.breakpoints.down(920)]: {
        marginRight: '0px!important',
      },
    },
  }),
);

export const dateFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePopover: {
      color: '#73797e!important',
      marginBottom: '8px!important',
      justifyContent: 'flex-start !important',
      backgroundColor: ' #E6E9EB!important',
      border: '1px solid #5c5d5e70 !important',
      textTransform: 'capitalize !important' as any,
      boxShadow: 'none!important',

      width: '100%!important',

      [theme.breakpoints.down(920)]: {
        marginRight: '0px!important',
        width: '100%!important',
      },
    },
    popoverContent: {
      width: '74vw!important',
      [theme.breakpoints.up(2200)]: {
        width: '61.5vw!important',
      },
      [theme.breakpoints.between(1448, 2200)]: {
        width: '74vw!important',
      },
      [theme.breakpoints.between(1280, 1448)]: {
        width: '62vw!important',
      },
      [theme.breakpoints.between(0, 616)]: {
        width: '65vw!important',
      },
    },
  }),
);

export const reportTypeFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    ReportType: {
      minWidth: '16.3%',
      width: '16.3%!important',
      [theme.breakpoints.down(920)]: {
        width: '100% !important',
        marginLeft: '0px!important',
      },
    },
  }),
);

export const reportTypeFilterStylesExport = makeStyles((theme: Theme) =>
  createStyles({
    ReportType: {
      minWidth: '16.3%',
      width: '100%!important',
      [theme.breakpoints.down(920)]: {
        width: '100% !important',
        marginLeft: '0px!important',
      },
    },
  }),
);

export const salesMarketFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterMain: {
      minWidth: '16.3%',
      width: '16.3%!important',
      [theme.breakpoints.down(920)]: {
        width: '100%',
        marginLeft: '0px!important',
      },
    },
  }),
);
export const sortFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '17vw!important',
      marginLeft: '8px!important',
      backgroundColor: '#E6E9EB!important',
      borderRadius: '4px!important',
      [theme.breakpoints.between(616, 920)]: {
        width: '45.5vw!important',
        marginLeft: '0px!important',
      },
      [theme.breakpoints.between(0, 616)]: {
        width: '93vw!important',
        marginLeft: '0px!important',
        marginBottom: '8px!important',
      },
    },
  }),
);

export const filterAllStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '11vw!important',
      marginLeft: '.5em!important',
      backgroundColor: '#E6E9EB!important',
      borderRadius: '4px!important',
      [theme.breakpoints.between(616, 920)]: {
        width: '45.5vw!important',
      },
      [theme.breakpoints.between(0, 616)]: {
        width: '93vw!important',
        marginLeft: '0px!important',
      },
    },
  }),
);
