import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  footerMain: {
    backgroundColor: '#001B33',
    padding: ' 24px 24px 32px',
    color: 'white',
    height: '27%',

    fontFamily: 'sans-serif',
    [theme.breakpoints.between(601, 920)]: {
      height: '27%',
    },
    [theme.breakpoints.down(600)]: {
      height: '31%',
    },
    [theme.breakpoints.down(450)]: {
      height: '31%',
    },
  },
  footerHelp: {
    paddingBottom: '10px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
  },
  helpFeedback: {
    display: 'inline-block',
    float: 'right',
    cursor: 'pointer',
  },
  help: {
    marginRight: '17.63px',
  },
  policyContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '16px!important',
  },
  policies: {
    width: '120px',
    margin: '20px 10px 10px 0 !important',
    lineHeight: '.5em!important',
    fontSize: '13.3px !important',
    textAlign: 'left',
    color: '#ffffff!important',
    textDecoration: 'none!important',
    display: 'flex',
    alignItems: 'center',
  },
  policies2: {
    width: '220px',
    margin: '20px 10px 10px 0 !important',
    lineHeight: '.5em !important',
    fontSize: '13.3px !important',
    textAlign: 'left',
    color: '#ffffff!important',
    textDecoration: 'none!important',
    display: 'flex',
    alignItems: 'center',
  },
  policies3: {
    width: 'auto',
    margin: '20px 10px 10px 0 !important',
    lineHeight: '1.5em !important',
    fontSize: '13.3px !important',
    textAlign: 'left',
    color: '#ffffff!important',
    textDecoration: 'none!important',
    display: 'flex',
    alignItems: 'center',
  },
  policies4: {
    width: '440px',
    margin: '20px 10px 10px 0 !important',
    lineHeight: '1.5em !important',
    fontSize: '13.3px !important',
    textAlign: 'left',
    color: '#ffffff!important',
    textDecoration: 'none!important',
    display: 'flex',
    alignItems: 'center',
  },
  brandName: {
    fontSize: '11.33px !important',
    lineHeight: '16px !important',
    color: 'rgba(255, 255, 255, 0.6)',
    marginTop: '16px!important',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}));
