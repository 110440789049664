import React from 'react';

const InfoUpload: React.FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99967 0.333496C3.31967 0.333496 0.333008 3.32016 0.333008 7.00016C0.333008 10.6802 3.31967 13.6668 6.99967 13.6668C10.6797 13.6668 13.6663 10.6802 13.6663 7.00016C13.6663 3.32016 10.6797 0.333496 6.99967 0.333496ZM7.66634 10.3335H6.33301V6.3335H7.66634V10.3335ZM7.66634 5.00016H6.33301V3.66683H7.66634V5.00016Z"
        fill="#667685"
      />
    </svg>
  );
};

export default InfoUpload;
