import {
  EDIT_AFFILIATE_REQUEST,
  EDIT_AFFILIATE_SUCCESS,
  EDIT_AFFILIATE_FAILURE,
} from '../../actions/adminAffiliates/adminAffliatesActionType';

const initialState = {
  loading: false,
  affiliate: [],
  error: '',
  fetched: false,
};

interface Affiliates {
  AffiliateID: number;
  AffiliateName: string;
  SalesMarkets: Array<string>;
}
interface YearWeek {
  value: number;
  label: string;
}
interface YearWeeks {
  Year: YearWeek;
  Week: YearWeek;
}
interface Payload {
  Affiliates: Affiliates;
  CurrentTokenExpiry: string;
  Email: string;
  IsAdmin: boolean;
  IsNewPassword: boolean;
  Name: string;
  Password: any;
  Roles: Array<string>;
  Token: string;
  YearWeeks: YearWeeks;
}
interface AdminEditAction {
  payload: Payload;
  type: string;
}

const editAffiliateReducer = ( state = initialState, action: AdminEditAction):any => {
  switch (action.type) {
    case EDIT_AFFILIATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_AFFILIATE_SUCCESS:
      return {
        ...state,
        loading: false,
        affiliate: action.payload,
        error: '',
        fetched: true,
      };
    case EDIT_AFFILIATE_FAILURE:
      return {
        ...state,
        loading: false,
        affiliate: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default editAffiliateReducer;
