import React, { useEffect, useState } from 'react';
import { Typography, InputAdornment } from '@mui/material';
import { changePasswordStyles } from './styles';
import { BackIcon, InfoIcon, CloseIcon } from '../../shared/Media/Svg';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { resetPassword, alertAction, loaderAction } from '../../redux';
import secureLocalStorage from 'react-secure-storage';

const ChangePassword: any = () => {
  const [snackClose, setSnackClose] = useState(true);
  const [passwords, setPasswords] = useState({ oldPassword: '', newPassword: '', newConfirmPassword: '' });

  type ShowState = {
    showOldPass: boolean;
    showNewPass: boolean;
    showConfirmPass: boolean;
  };

  const [show, setShow] = useState<ShowState>({
    showOldPass: false,
    showNewPass: false,
    showConfirmPass: false,
  });

  const dispatch: any = useAppDispatch();

  const togglePasswordVisiblity: any = (type: string) => {
    if (type === 'old') {
      setShow({
        showOldPass: show.showOldPass ? false : true,
        showNewPass: false,
        showConfirmPass: false,
      });
    } else if (type === 'new') {
      setShow({
        showOldPass: false,
        showNewPass: show.showNewPass ? false : true,
        showConfirmPass: false,
      });
    } else {
      setShow({
        showOldPass: false,
        showNewPass: false,
        showConfirmPass: show.showConfirmPass ? false : true,
      });
      
    }
  };

  const navigates = ['/changePassword', '/performance'];

  const navigate = useNavigate();
  const user = useAppSelector((state: any) => state.user.user);
  useEffect(() => {
    if (user.IsNewPassword) {
      dispatch(loaderAction(false));
      navigate(navigates[0]);
    }
  }, []);
  const fetched = useAppSelector((state: any) => state.resetPassword.fetched);
  const isNewPassword = useAppSelector((store: any) => store.user.user.IsNewPassword);
  const handleClick: any = async () => {
    const loginDto = {
      Username: user.Email,
      OldPassword: passwords.oldPassword,
      NewPassword: passwords.newPassword,
    };


    if (passwords.newPassword.length < 8 || passwords.newConfirmPassword.length < 8) {
      dispatch(alertAction('Atleast 8 Characters required', true, 'error'));

    } else if (passwords.newPassword !== passwords.newConfirmPassword) {
      dispatch(alertAction('New Password & Confirm New Password are not same', true, 'error'));
    } else {
      dispatch(resetPassword(loginDto, isNewPassword));
      navigate(navigates[1]);
    }
  };

  const newPass = secureLocalStorage.getItem('newsessionPassword');
  const oldPass = secureLocalStorage.getItem('sessionPassword');
  useEffect(() => {
    if (fetched && newPass !== oldPass) {
      secureLocalStorage.setItem('sessionUsername', user.Email);
      secureLocalStorage.setItem('sessionPassword', passwords.newPassword);
      navigate(navigates[1]);
    }
  }, [fetched]);
  const classes = changePasswordStyles();
  const handleKeyDown: any = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleClick();
    }
  };

  const handleChange = (event: any): any => {
    setPasswords((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  
  };

  return (
    <Typography component="div" className={classes.mainDiv}>
      <Typography component="div" className={classes.subMainDiv}>
        <Link to="/" className={classes.returnToSignIn}>
          <BackIcon />
          {isNewPassword ? 'Return to Sign In' : 'Return to Performance Dashboard'}
        </Link>

        <Typography component="div" className={classes.resetHeading}>
          Change Password
        </Typography>

        {snackClose ? (
          <Typography component="div" className={classes.snack}>
            <div style={{ display: 'flex' }}>
              <InfoIcon />
              <Typography component="div" className={classes.textSnack}>
                Please change your temporary password to something easy to remember using
                the fields on this page.
              </Typography>
            </div>

            <CloseIcon setSnackClose={setSnackClose} />
          </Typography>
        ) : null}

        <TextField
          required
          label="Old Password"
          autoComplete="current-password"
          className={`${classes.inputField} red-asterik`}
          name='oldPassword'
          value={passwords.oldPassword}
          type={show.showOldPass ? 'text' : 'password'}
          onChange={(e) => handleChange(e)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => togglePasswordVisiblity('old')}
                  >
                    {show.showOldPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              </>
            ),
          }}
        />
        <TextField
          required
          label="New Password"
          autoComplete="current-password"
          className={`${classes.inputField} red-asterik`}
          name='newPassword'
          value={passwords.newPassword}
          type={show.showNewPass ? 'text' : 'password'}
          onChange={(e) => handleChange(e)}

          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => togglePasswordVisiblity('new')}
                  >
                    {show.showNewPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              </>
            ),
          }}
        />

        <TextField
          required
          label="Confirm Password"
          autoComplete="current-password"
          className={`${classes.inputField} red-asterik`}
          name='newConfirmPassword'
          value={passwords.newConfirmPassword}
          type={show.showConfirmPass ? 'text' : 'password'}
          onChange={(e) => handleChange(e)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => togglePasswordVisiblity('confirm')}
                  >
                    {show.showConfirmPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              </>
            ),
          }}
        />

        <div
          style={{
            fontWeight: 'bold',
            color: 'red',
          }}
        >
        </div>

        <Stack direction="row">
          {passwords.oldPassword.length > 0 &&
            passwords.newPassword.length > 0 &&
            passwords.newConfirmPassword.length > 0 ? (
            <Button
              type="submit"
              variant="contained"
              className={classes.loginButton}
              onClick={handleClick}
            >
              Update Password
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              className={classes.loginButton}
              onClick={handleClick}
              disabled
            >
              Update Password
            </Button>
          )}
        </Stack>
      </Typography>

      {/* ==========================================HELPTEXT============================================= */}
      <Typography component="div" className={classes.resetHelpDiv}>
        <Typography component="div" className={classes.helpTitle}>
          Help
        </Typography>
        <Typography component="p" className={classes.helpContext}>
          It may take several minutes to process your request. Please check your spam
          folder. If you do not recieve an email within one hour, please{' '}
          <Link to="/support" className={classes.helpLinks}>
            contact us.
          </Link>
        </Typography>

        <Typography component="p" className={classes.helpContext}>
          Need to create a new account or recover your email address? Please reach out on
          our{' '}
          <Link to="/support" className={classes.helpLinks}>
            support page.
          </Link>
        </Typography>
      </Typography>
      {/* </Typography> */}
    </Typography>
  );
};

export default ChangePassword;
