import { buttonPrevious } from './styles';
import React from 'react';
const ButtonPrevious: React.FC = () => {
  const classes = buttonPrevious();
  return (
    <svg
      className={classes.buttonPrevious}
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.27325 0.94L4.33325 0L0.333252 4L4.33325 8L5.27325 7.06L2.21992 4L5.27325 0.94Z"
        fill="#001B33"
      />
    </svg>
  );
};

export default ButtonPrevious;
