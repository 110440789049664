import {
  FETCH_SUPPORT_REQUEST,
  FETCH_SUPPORT_SUCCESS,
  FETCH_SUPPORT_FAILURE,
} from './supportActionType';
import axios from 'axios';
import { store } from '../../../app/store';
import { loaderAction } from '../loader/loaderActions';
import { alertAction } from '../Alert/alertActions';

interface Support {
  AffiliateName: string;
  ID: number;
}

const fetchSupportRequest: any = () => {
  return {
    type: FETCH_SUPPORT_REQUEST,
  };
};

const fetchSupportSuccess: any = (SUPPORT: Support) => {
  return {
    type: FETCH_SUPPORT_SUCCESS,
    payload: SUPPORT,
  };
};

const fetchSupportFailure: any = (error: any) => {
  return {
    type: FETCH_SUPPORT_FAILURE,
    payload: error,
  };
};

export const fetchAffiliates: any = () => {
  const token = store.getState().user.token;
  return function (dispatch: any) {
    dispatch(fetchSupportRequest());
    dispatch(loaderAction(true) as any);
    axios
      .get(`${process.env.REACT_APP_BACKEND}api/UserAffiliateManage/GetAffiliates`, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((response) => {
        const SUPPORT = response.data;
        dispatch(fetchSupportSuccess(SUPPORT));
        dispatch(loaderAction(false) as any);
      })
      .catch((error) => {
        dispatch(alertAction(`${error.response.data.Message}`, true, 'error'));
        dispatch(fetchSupportFailure(error.response.data.Message));
        dispatch(loaderAction(false) as any);
      });
  };
};
