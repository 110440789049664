import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  CHANGE_FETCHED_STATE,
} from './forgotPasswordActionTypes';
import axios from 'axios';
import { alertAction } from '../Alert/alertActions';
import { loaderAction } from '../loader/loaderActions';
import { Dispatch } from 'redux';
import secureLocalStorage from 'react-secure-storage';

const forgotPasswordRequest:any = () => {
  return {
    type: FORGOT_PASSWORD_REQUEST,
  };
};

const forgotPasswordSuccess:any = (USER: { success: boolean }) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: USER,
  };
};

const forgotPasswordFailure:any = (error: string) => {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload: error,
  };
};
const changeState:any = () => {
  return {
    type: CHANGE_FETCHED_STATE,
  };
};

export const forgotPasswordRequestSend = (Email: string) => {
  return function (dispatch: Dispatch) {
    dispatch(forgotPasswordRequest());
    dispatch(loaderAction(true) as any);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND}api/Users/EmailNewPassword`,
      data: JSON.stringify(Email),
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json-patch+json',
        Authorization: 'Bearer ',
      },
    })
      .then((response) => {
        dispatch(alertAction('Email Sent', true, 'success') as any);

        const USER = response.data;
        if ('Token' in USER) {
          secureLocalStorage.setItem('sessionToken', JSON.stringify(USER.Token));
        }
        dispatch(forgotPasswordSuccess(USER));
        dispatch(loaderAction(false) as any);
      })
      .catch((error) => {
        dispatch(alertAction(`${error.response.data.Message}`, true, 'error') as any);
        dispatch(forgotPasswordFailure(error.response.data.Message));
        dispatch(loaderAction(false) as any);
      });
  };
};

export const changeFetchedState = () => {
  return function (dispatch: Dispatch) {
    dispatch(changeState());
  };
};
