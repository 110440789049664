import * as React from 'react';
import { useState } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { TextField, Typography } from '@mui/material';
import Options from '../../Media/Svg/Options';
import { OptionsModalStyles } from './styles';
import CloseIconModals from '../../Media/Svg/CloseIconModals';
import EditIcon from '../../Media/Svg/EditIcon';
import DeleteIcon from '../../Media/Svg/DeleteIcon';
import './OptionsModal.css';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { deleteAffiliate, editAffiliate } from '../../../redux';
import { validate, res } from '../emailValidate';
import ComboBox2 from '../../Filters/AffiliateSalesEditFilter/AffiliateSalesEditFilter';


interface OptionsModalType {
  row: {
    AppUserID: number;
    AffiliateName: Array<string>;
    UserName: string;
    Email: string;
    Detail: any;
  };
  setCurrentPage: (a: number) => void;
}

export default function OptionsModal({ row }: OptionsModalType): any {
  const classes = OptionsModalStyles();
  const dispatch = useAppDispatch();
  const affiliates = useAppSelector((store: any) => store.user.user.Affiliates);
  // Map affiliates to have label and value
  const allAffiliates = affiliates.map((affiliate: any) => ({
    AffiliateName: affiliate.AffiliateName,
    label: affiliate.AffiliateName,
    value: affiliate.AffiliateName,
  }));


  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);

  const [passwordEdit, setPasswordEdit] = useState('');
  const [passCreateEdit, setPassCreateEdit] = useState(false);
  const [passCopiedEdit, setPassCopiedEdit] = useState(false);
  const [username, setUsername] = useState(row.UserName);
  const [email, setEmail] = useState(row.Email);


  const [emailError, setEmailError] = useState(false);
  const validateEmail = ():any => {
    validate(email); 
    if (res) {
      // Email is valid
      setEmailError(false);
    } else {
      // Email is invalid
      setEmailError(true);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick: any = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose: any = () => {
    setAnchorEl(null);
  };

  const handleOpenModal: any = () => {
    setOpenModal(true);
  };
  const handleCloseModal: any = () => {
    setOpenModal(false);
    setPassCreateEdit(false);
    setPassCopiedEdit(false);
    setEmailError(false);
  };


  const handleOpenModalDelete: any = () => setOpenModalDelete(true);
  const handleCloseModalResetDelete: any = () => setOpenModalDelete(false);



  ////////////////////////////DELETE USER////////////////////
  const deleteRow = async (): Promise<void> => {
    const deleteId = row.AppUserID.toString();
    dispatch(deleteAffiliate(deleteId));
    handleCloseModalResetDelete();
    handleCloseModal();
    handleClose();
  };

  ////////////////////////////// Generate Password Code Edit/////////////////////////////////


  const generatePasswordEditModal: any = () => {
    // Define the length of the password
    const passwordLength = 8;
    const randomPasswordEdit = Math.random().toString(36).substring(2, passwordLength + 2);

    // Set the generated password as state
    setPasswordEdit(randomPasswordEdit);

    // Copy the password to the clipboard
    navigator.clipboard.writeText(randomPasswordEdit);
    setPassCreateEdit(true);
    $('.edit-password-input').css('display', 'block');
  };

  const copyPasswordEdit: any = () => {
    setPassCopiedEdit(true);

    $('.passwordCopyBtn').css('display', 'none');
  };

  ////////////////////EDIT USER/////////////////////////////////////
  const trimmedRowAffiliateNames = row.AffiliateName
  ? row.AffiliateName.map((name: string) => name.trim()) : [];

  const [selectedOptions, setSelectedOptions] = useState<any>(
    allAffiliates.filter((affiliate: any) =>
      trimmedRowAffiliateNames.includes(affiliate.AffiliateName.trim())));

  
  React.useEffect(() => {
    setUsername(row.UserName);
    setEmail(row.Email);
    setSelectedOptions(allAffiliates.filter((affiliate: any) =>
      trimmedRowAffiliateNames.includes(affiliate.AffiliateName.trim())));
  }, [row]);
  
  const editData = (): void => {
    const EdittedAffiliates = selectedOptions.map((object: any) => {
      return object.AffiliateName;
    });
    const headervalue = {
      UserID: row.AppUserID,
      UserName: username,
      Email: email,
      Password: passwordEdit,
      IsNewPassword: passwordEdit && passwordEdit.length > 0 ? true : false,
      AffiliateName: EdittedAffiliates,
    };
    dispatch(editAffiliate(headervalue));
    handleCloseModal();
    handleClose();
    setEmailError(false);
  };
  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        className={classes.optionsButton}
        onClick={handleClick}
      >
        <Options />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={`modalPopover muiPopupBox ${classes.popoverContent}`}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div>
          <Button onClick={handleOpenModal} className={classes.EditButtonPopover}>
            <EditIcon /> Edit User Details
          </Button>
          <Modal
            keepMounted
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box className={classes.modal}>
              <CloseIconModals setSnackClose={handleCloseModal} />
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                className={classes.modalTitle}
              >
                Edit User Details
              </Typography>
              <TextField
                required
                className={` red-asterik ${classes.textFields}`}
                size="small"
                id="outlined-basic"
                name="username"
                value={username}
                label="Username"
                variant="outlined"
                onChange={(e) => setUsername(e.target.value)}
              />
               <ComboBox2
                label='Affiliate'
                placeholder='Placeholder for textbox'
                selectAllLabel='Select all'
                setAffiliateDropDown={setSelectedOptions}
                component='OptionsModal'
                optionsData={allAffiliates} // optionsData now includes AffiliateID
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
              />
              <TextField
                required
                className={` red-asterik ${classes.textFields}`}
                size="small"
                id="outlined-basic"
                name="email"
                value={email}
                label="Email Address"
                variant="outlined"
                onChange={(e) => {setEmail(e.target.value);validateEmail();}}
                error={emailError}
                helperText={emailError && 'Invalid email address'}
              />
              <>
              {passCreateEdit ? (
                <div className="edit-password-input">
                  <TextField
                    id="outlined-basic"
                    label="Temporary Password"
                    variant="outlined"
                    size="small"
                    value={passwordEdit}
                    className={classes.textFields}
                    onChange={(e) => setPasswordEdit(e.target.value)}
                  />
                  {passCopiedEdit ? (
                    ''
                  ) : (
                    <Button
                      className={classes.copyButton}
                      variant="contained"
                      onClick={copyPasswordEdit}
                    >
                      Copy
                    </Button>
                  )}
                </div>
              ) : (
                ''
              )}
              {passCreateEdit ? (
                ''
              ) : (
                <Button
                  variant="contained"
                  className={classes.updateButton}
                  onClick={() => {validateEmail();generatePasswordEditModal();}}
                >
                  Reset Password
                </Button>
              )}
              &nbsp;
              {!emailError && username.length >= 1 && email.length >= 1 && (
                <Button
                type="submit"
                variant="contained"
                className={classes.updateButton}
                disabled={emailError && username.length === 0 && email.length === 0}
                onClick={editData}
              >
                Update Profile
              </Button>
              ) }
              </>
              
            </Box>
          </Modal>
        </div>

        <div>
          <Button onClick={handleOpenModalDelete} className={classes.DeleteButtonPopover}>
            <DeleteIcon /> Remove User
          </Button>
          <Modal
            keepMounted
            open={openModalDelete}
            onClose={handleCloseModalResetDelete}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box className={classes.modal}>
              <CloseIconModals setSnackClose={handleCloseModalResetDelete} />
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                className={classes.modalTitle}
              >
                Are you sure you want to delete this user?
                <i>{row.Email}</i>
              </Typography>

              <Button
                variant="contained"
                className={classes.updateButton}
                onClick={() => deleteRow()}
              >
                Delete User
              </Button>
              <Button variant="outlined" className={classes.cancelButton} onClick={handleCloseModalResetDelete}>
                Cancel
              </Button>
            </Box>
          </Modal>
        </div>
      </Popover>
    </div>
  );
}
