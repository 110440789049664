import { helpFooterLogo } from './styles';
import React from 'react';
const HelpFooterLogo: React.FC = () => {
  const classes = helpFooterLogo();
  return (
    <svg
      className={classes.help}
      width="49"
      height="16"
      viewBox="0 0 49 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99992 1.33325C4.31992 1.33325 1.33325 4.31992 1.33325 7.99992C1.33325 11.6799 4.31992 14.6666 7.99992 14.6666C11.6799 14.6666 14.6666 11.6799 14.6666 7.99992C14.6666 4.31992 11.6799 1.33325 7.99992 1.33325ZM7.99992 3.33325C9.10659 3.33325 9.99992 4.22659 9.99992 5.33325C9.99992 6.43992 9.10659 7.33325 7.99992 7.33325C6.89325 7.33325 5.99992 6.43992 5.99992 5.33325C5.99992 4.22659 6.89325 3.33325 7.99992 3.33325ZM7.99992 12.7999C6.33325 12.7999 4.85992 11.9466 3.99992 10.6533C4.01992 9.32659 6.66659 8.59992 7.99992 8.59992C9.32659 8.59992 11.9799 9.32659 11.9999 10.6533C11.1399 11.9466 9.66659 12.7999 7.99992 12.7999Z"
        fill="white"
      />
      <path
        d="M21.6382 13V3.669H22.8112V7.668H28.0766V3.669H29.2496V13H28.0766V8.7344H22.8112V13H21.6382ZM34.5699 13.1733C33.85 13.1733 33.2191 13.0267 32.677 12.7334C32.1438 12.4401 31.7306 12.0269 31.4373 11.4937C31.144 10.9516 30.9974 10.3207 30.9974 9.60085C30.9974 8.88992 31.1352 8.26341 31.4106 7.72132C31.6861 7.17923 32.0683 6.75712 32.557 6.45497C33.0458 6.14394 33.6057 5.98842 34.2366 5.98842C34.8142 5.98842 35.3208 6.12172 35.7562 6.38832C36.1917 6.64603 36.5294 7.01483 36.7693 7.49471C37.0092 7.9657 37.1292 8.51668 37.1292 9.14763V9.86745H32.1171C32.1793 10.5962 32.4326 11.1649 32.8769 11.5737C33.3213 11.9736 33.9122 12.1735 34.6498 12.1735C35.0142 12.1735 35.3652 12.1291 35.7029 12.0402C36.0495 11.9425 36.3605 11.8003 36.636 11.6137L36.8493 12.5601C36.2006 12.9689 35.4408 13.1733 34.5699 13.1733ZM34.1966 6.93485C33.6368 6.93485 33.1747 7.12147 32.8103 7.49471C32.4459 7.86795 32.2238 8.3656 32.1438 8.98767H36.0495C36.0406 8.37449 35.8673 7.88128 35.5296 7.50804C35.1919 7.12591 34.7476 6.93485 34.1966 6.93485ZM38.8192 13V3.1358H39.9523V13H38.8192ZM42.0866 15.7993V6.16171H43.113L43.1264 7.22811C43.3574 6.8371 43.6685 6.53495 44.0595 6.32167C44.4594 6.0995 44.9126 5.98842 45.4191 5.98842C46.0234 5.98842 46.5477 6.13505 46.9921 6.42831C47.4453 6.72157 47.7963 7.13036 48.0451 7.65467C48.294 8.1701 48.4184 8.77439 48.4184 9.46755C48.4184 10.214 48.2673 10.8672 47.9652 11.4271C47.663 11.978 47.2409 12.409 46.6988 12.7201C46.1567 13.0222 45.5213 13.1733 44.7926 13.1733C44.2416 13.1733 43.7173 13.1155 43.2197 13V15.7993H42.0866ZM43.193 9.70749V11.9603C43.4418 12.0402 43.6951 12.098 43.9528 12.1335C44.2105 12.1691 44.4771 12.1869 44.7526 12.1869C45.5347 12.1869 46.1478 11.9469 46.5922 11.467C47.0365 10.9783 47.2587 10.3162 47.2587 9.48088C47.2587 8.71663 47.0809 8.11233 46.7255 7.668C46.37 7.22367 45.8812 7.0015 45.2592 7.0015C44.6282 7.0015 44.1261 7.24588 43.7529 7.73465C43.3796 8.21453 43.193 8.87214 43.193 9.70749Z"
        fill="white"
      />
    </svg>
  );
};

export default HelpFooterLogo;
