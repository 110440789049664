import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { forgotPasswordRequestSend } from '../../redux';
import { BackIcon, InfoIcon, CloseIcon } from '../../shared/Media/Svg';
import './ResetPassword.css';
import { useStyles } from './styles';
import { alertAction } from '../../redux';

const ResetPassword: any = () => {
  const [snackClose, setSnackClose] = useState(true);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');



  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();


  const isSuccessfull = useAppSelector((store) => store.forgotPassword.fetched);

  useEffect(() => {
    if (isSuccessfull) {
      navigate('/confirmPage', {
        state: {
          heading: 'Password Reset',
          message:
            'Your password has successfully been reset. Please check your email for the next steps.',
        },
      });
    }
  }, [isSuccessfull]);


  function validateEmail(emails: any): any {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emails);
  }

  const sendPassword: any = () => {
    if (validateEmail(email)) {
      dispatch(forgotPasswordRequestSend(email));
    } else {
      setError('');
      dispatch(alertAction('Please enter a valid email address.', true, 'error'));
    }
  };
  const handleKeyDown: any = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (validateEmail(email)) {
        sendPassword(e);
      } else {
        setError('');
        dispatch(alertAction('Please enter a valid email address.', true, 'error'));
      }
    }
  };
  return (
    <Typography component='div' className={classes.mainDiv}>
      <Typography component='div' className={classes.contentDiv}>
        <Typography component='div' className={classes.resetDiv}>
          <Link to='/' className={classes.returnToSignIn}>
            <BackIcon />
            Return to Sign In
          </Link>

          <Typography component='div' className={classes.resetHeading}>
            Reset Password
          </Typography>

          {snackClose ? (
            <Typography component='div' className={classes.snack}>
              <InfoIcon />
              <Typography component='div'>
                If you have an existing Client Portal account, you can use the
                same details to sign in to the Affiliate Portal.
              </Typography>
              <CloseIcon setSnackClose={setSnackClose} />
            </Typography>
          ) : null}

          <TextField
            required
            type='email'
            label='Email Address'
            placeholder='example@example.com'
            className={`${classes.inputField} red-asterik`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            helperText={error}
          />

          <div
            style={{
              fontWeight: 'bold',
              color: 'red',
            }}
          >

          </div>
          <Stack direction='row'>
            <Button
              type='submit'
              variant='contained'
              className={classes.loginButton}
              onClick={sendPassword}
            >
              Send link to email
            </Button>
          </Stack>
        </Typography>

        {/* ==========================================HELPTEXT============================================= */}
        <Typography component='div' className={classes.resetHelpDiv}>
          <Typography component='div' className={classes.helpTitle}>
            Help
          </Typography>
          <Typography component='p' className={classes.helpContext}>
            It may take several minutes to process your request. Please check
            your spam folder. If you do not recieve an email within one hour,
            please{' '}
            <Link to='/support' className={classes.helpLinks}>
              contact us.
            </Link>
          </Typography>

          <Typography component='p' className={classes.helpContext}>
            Need to create a new account or recover your email address? Please
            reach out on our{' '}
            <Link to='/support' className={classes.helpLinks}>
              support page.
            </Link>
          </Typography>
          {/* <ComboBox /> */}
        </Typography>
      </Typography>
    </Typography>
  );
};

export default ResetPassword;
