import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const OptionsModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
      [theme.breakpoints.between(0, 920)]: {
        width: '70%',
      },
      backgroundColor: '#ffffff!important',
      borderRadius: '4px!important',
      padding: '10px 16px 32px!important',
    },
    optionsButton: {
      backgroundColor: '#ffffff!important',
      boxShadow: 'none!important',
      width: '10px!important',
      minWidth: '37px!important',
      paddingTop: '20px!important',

      [theme.breakpoints.between(0, 920)]: {
        backgroundColor: '#ffffff!important',
        boxShadow: 'none!important',
        width: '10px!important',
        minWidth: '0px!important',
        padding: '0!important',
        paddingTop: '20px!important',
      },
    },

    popoverContent: {
    },

    modalTitle: {
      marginTop: '6px!important',
      marginBottom: '16px!important',
      fontSize: '27.65px!important' as any,
      wordWrap: 'break-word!important' as any,
    },
    textFields: {
      width: '100%!important',
      marginBottom: '16px!important',
    },
    updateButton: {
      width: '100%!important',
      textTransform: 'capitalize!important' as any,
      borderRadius: '40px!important',
      backgroundColor: '#007DB3!important',
      padding: '10px 24px!important',
    },
    copyButton: {
      width: '100%!important',
      textTransform: 'capitalize!important' as any,
      borderRadius: '40px!important',
      backgroundColor: '#007DB3!important',
      padding: '10px 24px!important',
      marginBottom: '8px!important',
    },
    DeleteButtonPopover: {
      borderTop: '1px solid #CCD1D6!important',
      width: '100%',
      display: ' flex!important',
      justifyContent: 'flex-start!important',
      textTransform: 'capitalize!important' as any,
      color: '#001B33!important',
      padding: '10px 14px 20px 18px !important',
    },
    EditButtonPopover: {
      width: '100%',
      display: ' flex!important',
      justifyContent: 'flex-start!important',
      textTransform: 'capitalize!important' as any,
      color: '#001B33!important',
      padding: '18px!important',
    },
    ResetButtonPopover: {
      width: '100%',
      display: ' flex!important',
      justifyContent: 'flex-start!important',
      textTransform: 'capitalize!important' as any,
      color: '#001B33!important',
      padding: '0px 18px 18px 18px!important',
    },
    cancelButton: {
      width: '100%!important',
      textTransform: 'capitalize!important' as any,
      borderRadius: '40px!important',
      backgroundColor: '#ffffff!important',
      padding: '10px 24px!important',
      border: '1px solid #001B3399!important',
      color: '#001B3399 !important',
      marginTop: '16px !important',
    },
  }),
);
