import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { HelpLogo, Profilelogo, DashboardLogo, SpectrumLogo } from '../Media/Svg';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import { navbarStyles } from './styles';
import HamMenu from './HamMenu';

const Header: React.FC = () => {
  const [tabValue, setTabValue] = useState('/performance');

  const allTabs = ['/performance', '/statement', '/admin'];

  const classes = navbarStyles();

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(920));

  const isLoggedIn: any = useAppSelector((store: any) => store.user.isLoggedIn);
  const isAdmin = useAppSelector((store) => store.user.user.IsAdmin);

  // ======================================DROPDOWN======================================
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick: any = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName.length >= 1) {
      allTabs.forEach((x) => {
        if (pathName === '/changePassword' || pathName === '/support') {
          setTabValue('/profile');
        } else if (x === pathName) {
          setTabValue(pathName);
        } else if (isLoggedIn && pathName === '/') {
          setTabValue('/performance');
        }
         
      });
    }
  }, [isLoggedIn]);

  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    setTabValue(newValue);
  };  
  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.Toolbar}>
        <Typography component="div" className={classes.spectrumLogo}>
          <Link to="/">
            <SpectrumLogo />
            <DashboardLogo />
          </Link>
        </Typography>

        {isMatch ? (
          isLoggedIn ? (
            <>
              <Typography component="div" className={classes.profileAndHamMenu}>
                <Tab
                  icon={<Profilelogo />}
                  label=""
                  className={`${classes.tab} ${classes.tabMobileProfile}`}
                  aria-expanded={open ? 'true' : undefined}
                  aria-controls={open ? 'basic-menu' : undefined}
                  onClick={handleClick}
                />
                <ProfileDropdown anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                {/* <DrawerComponent /> */}
                <HamMenu />
              </Typography>
            </>
          ) : (
            <Link className={`${classes.tab} ${classes.tabMobileHelp}`} to="/support">
              <HelpLogo />
            </Link>
          )
        ) : isLoggedIn ? (
          <>
            <Tabs
              sx={{ marginLeft: 'auto' }}
              textColor="inherit"
              value={tabValue}
              onChange={handleChange}
              className={classes.tabs}
            >
              <Tab
                value="/performance"
                component={Link}
                to={allTabs[0]}
                icon={<StackedLineChartIcon className={classes.TabIcon} />}
                label="Performance Dashboard"
                className={classes.tab}
              />
              <Tab
                value="/statement"
                component={Link}
                to={allTabs[1]}
                icon={<FileOpenIcon className={classes.TabIcon} />}
                label="Statements"
                className={classes.tab}
              />

              {isAdmin ? (
                <Tab
                  value="/admin"
                  component={Link}
                  to={allTabs[2]}
                  icon={<ManageAccountsIcon className={classes.TabIcon} />}
                  label="Admin"
                  className={classes.tab}
                />
              ) : (
                ''
              )}

              <Tab
                icon={<Profilelogo />}
                value="/profile"
                label=""
                className={classes.tab}
                aria-expanded={open ? 'true' : undefined}
                aria-controls={open ? 'basic-menu' : undefined}
                onClick={handleClick}
              />
            </Tabs>
            <ProfileDropdown anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
          </>
        ) : (
          <>
            <Link className={`${classes.helpIcon}`} to="/support">
              <HelpLogo />
            </Link>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
