import {
  SET_SELECTED_YEAR,
  SET_SELECTED_WEEK,
  SET_SELECTED_MONTH,
  SET_SELECTED_QUARTER,
  SET_SELECTED_AFFILIATES,
  SET_SELECTED_REVENUE_TYPE,
  SET_SELECTED_SALES_MARKETS,
  SET_CHANGED,
  SET_COMPARE,
  SET_DATE_TYPE,
} from '../../actions/performance/selectedValue/selectedValueActionTypes';

const initialState = {
  loading: false,
  year: '',
  week: '',
  month: '',
  quarter: '',
  revenueType: '',
  affiliates: [{}],
  affiliatesArray: [],
  salesMarkets: [{}],
  salesMarketsArray: [],
  error: '',
  fetched: false,
  changed: false,
  compare: false,
  dateType: '',
};

const selectedValueReducer = (state = initialState, action: any):any => {
  switch (action.type) {
    case SET_SELECTED_YEAR:
      return {
        ...state,
        year: action.payload,
      };
    case SET_SELECTED_WEEK:
      return {
        ...state,
        loading: false,
        week: action.payload,
        error: '',
      };
    case SET_SELECTED_MONTH:
      return {
        ...state,
        loading: false,
        month: action.payload,
        error: '',
      };
    case SET_SELECTED_QUARTER:
      return {
        ...state,
        loading: false,
        quarter: action.payload,
        error: '',
      };
    case SET_SELECTED_REVENUE_TYPE:
      return {
        ...state,
        loading: false,
        revenueType: action.payload,
        error: '',
      };
    case SET_SELECTED_AFFILIATES:
      const affiliatesArr: any = [];
      action.payload.map((x: any): any => affiliatesArr.push(x.value));
      return {
        ...state,
        loading: false,
        affiliates: action.payload,
        affiliatesArray: affiliatesArr,
        error: '',
      };
    case SET_SELECTED_SALES_MARKETS:
      const salesMarketsArr: any = [];
      action.payload.map((x: any): any => salesMarketsArr.push(x.value));
      return {
        ...state,
        loading: false,
        salesMarkets: action.payload,
        salesMarketsArray: salesMarketsArr,
        error: '',
        fetched: true,
        changed: true,
      };
    case SET_CHANGED:
      return {
        ...state,
        loading: false,
        changed: action.payload,
        error: '',
      };
    case SET_COMPARE:
      return {
        ...state,
        loading: false,
        compare: action.payload,
        error: '',
      };
    case SET_DATE_TYPE:
      return {
        ...state,
        dateType: action.payload,
      };
    default:
      return state;
  }
};

export default selectedValueReducer;
