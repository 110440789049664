import React from 'react';
import { dateLogo } from './styles';

const DateIcon: React.FC = () => {
  const classes = dateLogo();

  return (
    <svg
      className={classes.dateicon}
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6.33325H2.66667V7.66659H4V6.33325ZM6.66667 6.33325H5.33333V7.66659H6.66667V6.33325ZM9.33333 6.33325H8V7.66659H9.33333V6.33325ZM10.6667 1.66659H10V0.333252H8.66667V1.66659H3.33333V0.333252H2V1.66659H1.33333C0.593333 1.66659 0.00666666 2.26659 0.00666666 2.99992L0 12.3333C0 13.0666 0.593333 13.6666 1.33333 13.6666H10.6667C11.4 13.6666 12 13.0666 12 12.3333V2.99992C12 2.26659 11.4 1.66659 10.6667 1.66659ZM10.6667 12.3333H1.33333V4.99992H10.6667V12.3333Z"
        fill="#001B33"
      />
    </svg>
  );
};

export default DateIcon;
