/* eslint-disable array-callback-return */
/////////////////////////////////////////////// PAYMENT PARTNER GRID FUNCTION ///////////////////////////////////////////
export const createPartnerPaymentGrid: any = (data: any[], arr: any[]) => {
  const row1: any = data.filter((x) => x.BiRevlineNm === 'Affiliate Rev Share' && x.ModelType === 'On the books');
  if (row1 && row1.length > 0) {
    row1[0].type = 'Payment Subtotal';
  } else {
    row1.push({});
    arr?.map((x) => {
      row1[0][x] = 0;
      row1[0].type = 'Payment Subtotal';
      row1[0].Id = (Math.random() * 1e32).toString(36);
    });
  }

  const row2: any = data.filter((x) => x.BiRevlineNm === 'Billing Adjustments' && x.ModelType === 'On the books');
  if (row2 && row2.length > 0) { row2[0].type = 'Adjustments'; } else {
    row2.push({});
    arr?.map((x) => {
      row2[0][x] = 0;
      row2[0].type = 'Adjustments';
      row2[0].Id = (Math.random() * 1e32).toString(36);
    });
  }

  const row4: any = data.filter((x) => x.BiRevlineNm === 'Affiliate Rev Share' && x.ModelType === 'Budget');

  if (row4 && row4.length > 0) {
    row4[0].type = 'Budgeted Affiliate Rev Share';
  } else {
    row4.push({});
    arr?.map((x) => {
      row4[0][x] = 0;
      row4[0].type = 'Budgeted Affiliate Rev Share';
      row4[0].Id = (Math.random() * 1e32).toString(36);
    });
  }

  const row3: any = [
    {
      type: 'Payment Total',
      Id: (Math.random() * 1e32).toString(36),
    },
  ];

  const row5: any = [
    {
      type: 'Performance to Budget B/(W)',
      Id: (Math.random() * 1e32).toString(36),
    },
  ];
  const row6: any = [
    {
      type: 'Performance to Budget % B/(W)',
      Id: (Math.random() * 1e32).toString(36),
    },
  ];

  arr?.map((ele) => {
    if (ele.toString() !== 'type') {
      row3[0][ele] = row1[0][ele] + row2[0][ele];
      row5[0][ele] = row4[0][ele] - row3[0][ele];
      row6[0][ele] = ((row3[0][ele] / row4[0][ele]) * 100).toFixed(2) + '%' === 'Infinity%' ? '0.0%' : ((row4[0][ele] / row3[0][ele]) * 100).toFixed(2) + '%';
    }
  });
  const finalArray = [row1[0], row2[0], row3[0], row4[0], row5[0], row6[0]];

  return finalArray;
};

/////////////////////////////////////////////// REVENUE GRID FUNCTION ///////////////////////////////////////////
export const createPartnerRevenueGrid: any = (
  data: any[],
  arr: any[],
  selectedRevenue: string,
) => {
  let revenueType = '';
  if (selectedRevenue === 'Partner Revenue ' || selectedRevenue === 'Partner Revenue') {
    revenueType = selectedRevenue.split(' ')[0];
  } else {
    revenueType = selectedRevenue.split(' ')[1];
  }
  const row1 = data.filter((x) => x.BiRevlineNm === selectedRevenue.trim() && x.ModelType === 'On the books');
  if (row1 && row1.length > 0) {
    row1[0].type = 'Current Total Bookings';
  } else {
    row1.push({});
    arr?.map((x) => {
      row1[0][x] = 0;
      row1[0].type = 'Current Total Bookings';
      row1[0].Id = (Math.random() * 1e32).toString(36);
    });
  }

  const row2 = data.filter((x) => x.BiRevlineNm === selectedRevenue.trim() && x.ModelType === 'Budget');
  if (row2 && row2.length > 0) {
    row2[0].type = `Budgeted ${revenueType} Revenue`;
  } else {
    row2.push({});
    arr?.map((x) => {
      row2[0][x] = 0;
      row2[0].type = `Budgeted ${revenueType} Revenue`;
      row2[0].Id = (Math.random() * 1e32).toString(36);
    });
  }

  const row3: any = [
    {
      type: 'Performance to Budget B/(W)',
      Id: (Math.random() * 1e32).toString(36),
    },
  ];

  const row4: any = [
    {
      type: 'Performance to Budget % B/(W)',
      Id: (Math.random() * 1e32).toString(36),
    },
  ];

  arr?.map((ele) => {
    if (ele.toString() !== 'type') {
      row3[0][ele] = row2[0][ele] - row1[0][ele];
    }
  });

  arr?.map((ele) => {
    if (ele.toString() !== 'type') {
      row4[0][ele] = ((row1[0][ele] / row2[0][ele]) * 100).toFixed(2) + '%';
    }
  });

  const finalArray = [row1[0], row2[0], row3[0], row4[0]];

  return finalArray;
};

/////////////////////////////////  ADD TWO TABLE FUNCTION //////////////////////////////////////////
export const sumObjectsByKey: any = (...objs: any[]) =>
  Object.values(
    objs.reduce((a, e) => {
      a[e.type] = a[e.type] || { type: e.type };

      for (const k in e) {
        if (k !== 'type') {
          a[e.type][k] = a[e.type][k] ? a[e.type][k] + e[k] : e[k];
        }
      }

      return a;
    }, {}),
  );

////////////// SUB TWO TABLE FUNCTION //////////////////////
export const subObjectsByKey: any = (...objs: any[]) =>
  Object.values(
    objs.reduce((a, e) => {
      a[e.type] = a[e.type] || { type: e.type };

      for (const k in e) {
        if (k !== 'type') {
          a[e.type][k] = a[e.type][k] ? a[e.type][k] - e[k] : e[k];
        }
      } return a;
    }, {}),
  );

////////////////////////////// CALCULATE PERCENTAGE FUNCTION /////////////////////////////////////////////
export const getPercentage: any = (data: any, arr: any) => {
  if (data[1].Total === 0) {
    arr?.map((x: string | number) => {
      if (x.toString() !== 'type') {
        data[3][x] = '0%';
      }
    });
  } else {
    arr?.map((x: string | number) => {
      if (x.toString() !== 'type') {
        data[3][x] = ((data[0][x] / data[1][x]) * 100).toFixed(2) + '%';
      }
    });
  }

  return data;
};

export const getPercentageForPartnerPayment: any = (data: any, arr: any) => {
  if (data[3].Total === 0) {
    arr?.map((x: string | number) => {
      if (x.toString() !== 'type') {
        data[5][x] = '0%';
      }
    });
  } else if (data[2].Total === 0) {
    arr?.map((x: string | number) => {
      if (x.toString() !== 'type') {
        data[5][x] = '0%';
      }
    });
  } else {
    arr?.map((x: string | number) => {
      if (x.toString() !== 'type') {
        data[5][x] = ((data[2][x] / data[3][x]) * 100).toFixed(2) + '%';
      }
    });
  }
  return data;
};
