import React from 'react';
import 'rc-pagination/assets/index.css';
import { statementStyles } from '../../components/Statements/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Pagination from 'rc-pagination';
import localeInfo from '../../components/Statements/locale/en_US';



const Paginations: any = ({ updatePage, updatePage1, currentPage, countPerPage, filteredData, updatePage2 }:any) => {
  const classes: any = statementStyles();
  return (
    <Stack spacing={2} className={classes.stackPagination}>

    <div className={classes.pagination}>
      <svg
        className={classes.firstBtn}
        width="29"
        height="28"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        
        onClick={updatePage1}
        style={{ pointerEvents: filteredData.length < 10 ? 'none' : 'auto' }}
      >
        <rect width="32" height="32" rx="2" fill="white" />
        <path
          d="M22.41 11.41L21 10L15 16L21 22L22.41 20.59L17.83 16L22.41 11.41ZM16.41 11.41L15 10L9 16L15 22L16.41 20.59L11.83 16L16.41 11.41Z"
          fill={currentPage > 1 ? '#667685' : '#B3BBC2'}
        />
        <rect
          x="0.5"
          y="0.5"
          width="31"
          height="31"
          rx="1.5"
          stroke="#001B33"
          strokeOpacity="0.2"
        />
      </svg>
      <Pagination
        pageSize={countPerPage}
        onChange={updatePage}
        current={currentPage}
        total={filteredData.length}
        showLessItems
        locale={localeInfo}
        
        className="pagination paginationNum paginationBorder"
      />
      <svg
        className={classes.lastBtn}
        width="29"
        height="28"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={updatePage2}
        style={{ pointerEvents: filteredData.length < 10 ? 'none' : 'auto' }}
      >
        <rect width="32" height="32" rx="2" fill="white" />
        <path
          d="M9.58984 11.41L10.9998 10L16.9998 16L10.9998 22L9.58984 20.59L14.1698 16L9.58984 11.41ZM15.5898 11.41L16.9998 10L22.9998 16L16.9998 22L15.5898 20.59L20.1698 16L15.5898 11.41Z"
          fill={
            currentPage <
              Math.ceil((filteredData.length / 10) % 10 === 0 ? filteredData.length / 10 : filteredData.length / 10 ) ? '#667685' : '#B3BBC2'}
        />
        <rect
          x="0.5"
          y="0.5"
          width="31"
          height="31"
          rx="1.5"
          stroke="#001B33"
          strokeOpacity="0.2"
        />
      </svg>
    </div>

    <Typography className={classes.paginationResult1}>
      Showing {filteredData.length} results
    </Typography>
    <Typography className={classes.paginationResult2}>
      Page {filteredData.length === 0 ?  currentPage - 1 : currentPage} of{' '}
      {Math.ceil((filteredData.length / 10) % 10 === 0 ? filteredData.length / 10 : filteredData.length / 10 )}
    </Typography>
  </Stack>
  );
};
export default Paginations;
