import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  SET_IS_NEW_PASSWORD_FALSE,
  USER_LOGOUT,
  USER_LOGIN,
} from './userActionType';
import axios from 'axios';
import { alertAction } from '../Alert/alertActions';
import { loaderAction } from '../loader/loaderActions';
import { Dispatch } from 'redux';
import secureLocalStorage from 'react-secure-storage';

interface ResponseProps {
  loading: boolean;
  error: string;
  token: string;
  isLoggedIn: boolean;
  failedAttempts: number;
  user: any;
}

interface UserPass {
  userName: string;
  password: string;
}

const fetchUserRequest = (): any => {
  return {
    type: FETCH_USER_REQUEST,
  };
};

const fetchUserSuccess = (USER: ResponseProps): any => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: USER,
  };
};

const setIsnewPasswordFalse = (userState: any): any => {
  return {
    type: SET_IS_NEW_PASSWORD_FALSE,
    payload: userState,
  };
};

const userLogout = (): any => {
  return {
    type: USER_LOGOUT,
  };
};
const userLogin = (): any => {
  return {
    type: USER_LOGIN,
  };
};

export const fetchUser = ({ userName, password }: UserPass): any => {
  const userData = {
    username: '',
    password: '',
  };
  const username = 'username';
  const passwords = 'password';



  const sessionUsername = secureLocalStorage.getItem(username)?.toString();
  const sessionpassword = secureLocalStorage.getItem(passwords)?.toString();

  if ((userName && userName.length > 0) || (password && password.length > 0)) {
    userData.username = userName;
    userData.password = password;
  } else if (
    sessionUsername &&
    sessionUsername.length > 0 &&
    sessionpassword &&
    sessionpassword.length > 0
  ) {
    userData.username = sessionUsername;
    userData.password = sessionpassword;
  }
  return function (dispatch: Dispatch) {
    dispatch(fetchUserRequest());
    dispatch(loaderAction(true) as any);
    axios
      .post(`${process.env.REACT_APP_BACKEND}api/Users/Token`, {
        userName: userData.username,
        password: userData.password,
      })
      .then((response) => {
        const USER = response.data;
        if ('Token' in USER) {
          secureLocalStorage.setItem(username, userData.username);
          secureLocalStorage.setItem(passwords, userData.password);
          secureLocalStorage.setItem('sessionToken', JSON.stringify(USER.Token));
        }
        dispatch(fetchUserSuccess(USER));
        dispatch(loaderAction(false) as any);

        secureLocalStorage.setItem('failedAttempts', '0');
      })
      .catch(() => {
        const attempts = secureLocalStorage.getItem('failedAttempts')?.toString();
        if (attempts) {
          secureLocalStorage.setItem('failedAttempts', (parseInt(attempts) + 1).toString());
        } else {
          secureLocalStorage.setItem('failedAttempts', '1');
        }
        secureLocalStorage.removeItem(username);
        secureLocalStorage.removeItem(passwords);
        secureLocalStorage.removeItem('sessionToken');
        dispatch(loaderAction(false) as any);
        dispatch(alertAction('Can not find any user for this Username and Password', true, 'error') as any);
        dispatch(loaderAction(false) as any);

      });
  };
};

export const setIsNewPassword = (userState: any) => {
  return function (dispatch: Dispatch) {
    dispatch(setIsnewPasswordFalse(userState));
  };
};

export const logout = (): any => {
  secureLocalStorage.clear();
  window.location.href = window.location.origin;
  return function (dispatch: Dispatch) {
    dispatch(userLogout());
  };
};

export const login = () => {
  return function (dispatch: Dispatch) {
    dispatch(userLogin());
  };
};