import {
  ADD_AFFILIATE_REQUEST,
  ADD_AFFILIATE_SUCCESS,
  ADD_AFFILIATE_FAILURE,
} from '../../actions/adminAffiliates/adminAffliatesActionType';

const initialState = {
  loading: false,
  affiliate: [],
  error: '',
  fetched: false,
};
interface Payload {
  AppUserID: number;
  AffiliateName: Array<string>;
  UserName: string;
  Email: string;
  Detail: any;
}
interface AddAction {
  type: string;
  payload: Payload;
}
const addAffiliateReducer = ( state = initialState, action: AddAction):any => {
  switch (action.type) {
    case ADD_AFFILIATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_AFFILIATE_SUCCESS:
      return {
        ...state,
        loading: false,
        affiliate: action.payload,
        error: '',
        fetched: true,
      };
    case ADD_AFFILIATE_FAILURE:
      return {
        ...state,
        loading: false,
        affiliate: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default addAffiliateReducer;
