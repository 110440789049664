import {
  FETCH_EXPORT_REQUEST,
  FETCH_EXPORT_SUCCESS,
  FETCH_EXPORT_FAILURE,
} from '../../actions/export/exportActionTypes';

const initialState = {
  loading: false,
  response: '',
  error: '',
};

const exportReducer = ( state = initialState, action:any):any => {
  switch (action.type) {
    case FETCH_EXPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: '',
      };
    case FETCH_EXPORT_FAILURE:
      return {
        ...state,
        loading: false,
        affiliateArray: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default exportReducer;
