import { SHOW_LOADER, HIDE_LOADER } from './loaderActionTypes';

import { Dispatch } from 'redux';

const showLoader:any = () => {
  return {
    type: SHOW_LOADER,
  };
};

const hideLoader:any = () => {
  return {
    type: HIDE_LOADER,
  };
};

export const loaderAction = (show: boolean) => {
  return function (dispatch: Dispatch) {
    if (show) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  };
};
