import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { sendEmail } from '../../redux';
import './Support.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { changeFetchedStateSupport } from '../../redux';
import { supportStyles } from './styles';
import { fetchAffiliates } from '../../redux';
import { loaderAction } from '../../redux';
import { alertAction } from '../../redux';

interface RequestHeader {
  Subject: string;
  Body: string;
  From: string;
}

const Support: any = () => {
  const classes = supportStyles();
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [subject, setSubject] = useState('');
  const [emailValid, setEmailValid] = useState(''); // create state for email input value
  const [updatedEmailValid, updatedSetEmailValid] = useState(false); // create state for email input value

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((store) => store.user.isLoggedIn);
  const isNewPassword = useAppSelector((store) => store.user.user.IsNewPassword);
  const isAdmin = useAppSelector((store) => store.user.user.IsAdmin);
  const Affiliates: any = useAppSelector((store) => store.user.user.Affiliates);
  const loading = useAppSelector((store) => store.user.loading);
  let affiliatesCounter = 0;

  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = today.getMonth() + 1;
  const dd = today.getDate();

  let month = mm.toString();
  let date = dd.toString();

  if (dd < 10) date = '0' + date;
  if (mm < 10) month = '0' + month;

  const newDate = month + '_' + date + '_' + yyyy;

  const sendEmailRequest: any = () => {
    const requestHeader = {
      Subject: newDate,
      Body: description,
      From: email,
    } as RequestHeader;
    dispatch(sendEmail(requestHeader));
  };

  const isSuccessfull = useAppSelector((store) => store.email.fetched);

  useEffect(() => {
    if (isSuccessfull) {
      navigate('/confirmPage', {
        state: {
          heading: 'Confirmed',
          message:
            'Thank you for contacting our support team. A representative will respond to your request within 24 hours.',
        },
      });
    }
  }, [isSuccessfull]);

  useEffect(() => {
    if (loading) {
      dispatch(loaderAction(true));
    } else {
      dispatch(loaderAction(false));
    }
    dispatch(changeFetchedStateSupport());
    if (isLoggedIn && isAdmin && affiliatesCounter === 0) {
      affiliatesCounter = affiliatesCounter + 1;
      dispatch(fetchAffiliates());
    }
  }, [isLoggedIn]);

  const affArray = useAppSelector((state) => state.support.affiliateArray);

  useEffect(() => {
    let affiliateArray = '';
    if (isAdmin === true) {
      affArray?.forEach((obj: any) => {
        affiliateArray += `${obj.AffiliateName}_`;
      });
      setSubject(affiliateArray + newDate);

    } else {

      Affiliates?.forEach((obj: any) => {
        affiliateArray += `${obj.AffiliateName}_`;
      });
      setSubject(affiliateArray + newDate);
    }

  }, [affArray]);

  const sendEmailSupportRequest: any = () => {
    const requestHeader = {
      Subject: subject,
      Body: description,
      From: email,
    } as RequestHeader;
    dispatch(sendEmail(requestHeader));
  };





  function handleEmailChange(e: any): any {
    const emailValue = e.target.value; // get email value from input

    // regex pattern to match email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(emailValue)) { // if email doesn't match regex pattern
      dispatch(alertAction('Please enter a valid email address.', true, 'error'));
    }
    setEmailValid(emailValue); // update email state with input value
    setEmail(e.target.value);
    updatedSetEmailValid(emailRegex.test(emailValue));
  }
  const handleKeyDown: any = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isLoggedIn) {
        sendEmailSupportRequest();
      } else {
        sendEmailRequest();
      }
    }
  };

  return (
    <Typography component='div' className={classes.mainDiv}>
      <Typography component='div' className={classes.supportContent}>
        <Typography component='div' className={classes.allContent}>
          {/* ---------------------------------------------start of the content--------------------------------- */}
          <Typography component='h1' className={classes.supportTitle}>
            Support
          </Typography>
          <Typography component='p' className={classes.supportText}>
            A representative will respond to your request within 2 business
            hours.
          </Typography>
          {/* <form > */}

          {isLoggedIn && !isNewPassword ? (
            isAdmin === true ? (
              <TextField
                type='text'
                id='outlined-basic'
                label='Subject '
                required
                placeholder='Enter Subject'
                value={subject}
                variant='outlined'
                className={`${classes.subject} redasterisk`}
                inputProps={{ readOnly: true }}
              />
            ) : (
              <TextField
                type='text'
                id='outlined-basic'
                label='Subject '
                required
                placeholder='Enter Subject'
                value={subject}
                variant='outlined'
                className={`${classes.subject} redasterisk`}
                inputProps={{ readOnly: true }}
              />
            )

          ) : (
            <TextField
              type='text'
              id='outlined-basic'
              label='Subject '
              required
              placeholder='Enter Suject'
              defaultValue={newDate}
              variant='outlined'
              className={`${classes.subject} redasterisk`}
              inputProps={{ readOnly: true }}
            />
          )}

          <FormControl className={classes.contact}>
            <FormLabel className={classes.contactMethod}>
              Preferred method of contact:{' '}
              <span className={classes.contactMethodSpan}>*</span>
            </FormLabel>
            <TextField
              required
              id='outlined-basic'
              label='Email Address'
              variant='outlined'
              className={`${classes.email} redasterisk`}
              placeholder='example@example.com'
              value={emailValid}
              onChange={handleEmailChange}
              type='email'
            />
          </FormControl>

          <TextField
            id='outlined-multiline-static'
            label='Description'
            required
            multiline
            placeholder='Please describe the situation you need help with...'
            rows={4}
            className={`${classes.description} redasterisk`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDescription(e.target.value)
            }
            onKeyDown={handleKeyDown}
          />

          <Stack direction='row'>
            {isLoggedIn ? (
              description.length === 0 || updatedEmailValid === false ? (
                <Button
                  variant='contained'
                  onClick={sendEmailSupportRequest}
                  className={classes.loginButton}
                  disabled
                  type='submit'

                >
                  Send Link To Email
                </Button>
              ) : (<Button
                variant='contained'
                type='submit'
                onClick={sendEmailSupportRequest}
                className={classes.loginButton}
              >
                Send Link To Email
              </Button>)

            ) : (
              description.length === 0 || updatedEmailValid === false ? (
                <Button
                  variant='contained'
                  type='submit'
                  onClick={sendEmailRequest}
                  className={classes.loginButton}
                  disabled
                >
                  Send Link To Email
                </Button>
              ) : (<Button
                variant='contained'
                type='submit'
                onClick={sendEmailRequest}
                className={classes.loginButton}
              >
                Send Link To Email
              </Button>)

            )}
          </Stack>
        </Typography>
      </Typography>
    </Typography>
  );
};

export default Support;
