import { dollar } from './styles';
import React from 'react';
const Dollar: React.FC = () => {
  const classes = dollar();

  return (
    <svg
      className={classes.dollar}
      width="11"
      height="15"
      viewBox="0 0 11 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.76928 21.5344L4.09184 18.9078C3.43136 18.831 2.8016 18.6851 2.20256 18.4701C1.60352 18.255 1.08128 17.9862 0.63584 17.6637L1.00448 16.3504C1.88 17.0262 2.96288 17.4563 4.25312 17.6406L5.05952 11.1203C3.66176 10.7363 2.63264 10.2141 1.97216 9.5536C1.32704 8.89312 1.00448 8.06368 1.00448 7.06528C1.00448 6.22048 1.21184 5.4832 1.62656 4.85344C2.05664 4.22368 2.648 3.73984 3.40064 3.40192C4.16864 3.04864 5.05184 2.872 6.05024 2.872L6.3728 0.337598H7.61696L7.2944 2.9872C7.78592 3.07936 8.27744 3.2176 8.76896 3.40192C9.26048 3.58624 9.69824 3.80128 10.0822 4.04704L9.73664 5.26816C9.368 5.0224 8.96096 4.81504 8.51552 4.64608C8.07008 4.47712 7.60928 4.34656 7.13312 4.2544L6.41888 10.0374C9.15296 10.8208 10.52 12.3338 10.52 14.5763C10.52 15.928 10.0515 17.0032 9.11456 17.8019C8.1776 18.6006 6.91808 19 5.336 19L5.01344 21.5344H3.76928ZM2.456 6.97312C2.456 7.6336 2.67104 8.17888 3.10112 8.60896C3.5312 9.03904 4.23776 9.4 5.2208 9.69184L5.912 4.16224C4.8368 4.1776 3.992 4.43872 3.3776 4.9456C2.7632 5.43712 2.456 6.11296 2.456 6.97312ZM9.04544 14.5763C9.04544 13.071 8.11616 12.0266 6.2576 11.4429L5.47424 17.7098C6.59552 17.679 7.47104 17.3872 8.1008 16.8342C8.73056 16.2659 9.04544 15.5133 9.04544 14.5763Z"
        fill="#0099D8"
      />
    </svg>
  );
};

export default Dollar;
