import { backIcon } from './styles';
import React from 'react';
const BackIcon: React.FC = () => {
  const classes = backIcon();

  return (
    <svg
      className={classes.returnToSignInSVG}
      width="4"
      height="6"
      viewBox="0 0 4 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.705 0.705L3 0L0 3L3 6L3.705 5.295L1.415 3L3.705 0.705Z"
        fill="#001B33"
      />
    </svg>
  );
};

export default BackIcon;
