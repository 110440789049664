import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { uploadStatementStyles } from './styles';
import { useMediaQuery, useTheme, TextField, Typography, Grid } from '@mui/material';
import CloseIconModals from '../../Media/Svg/CloseIconModals';
import '../../../components/Login/Login.css';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useEffect, useState } from 'react';
import FileAttatchment from '../../Media/Svg/FileAttatchment';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
// import Affiliate from '../../Media/Svg/Affiliate';
import CalendarEdit from '../../Media/Svg/CalendarEdit';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import './UploadStatements.css';
import InfoUpload from '../../Media/Svg/InfoUpload';
// import StatementName from '../../Media/Svg/StatmentName';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import StepConnector from '@mui/material/StepConnector/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import Check from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import StepLabel from '@mui/material/StepLabel';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { uploadStatementRedux, fetchAffiliates } from '../../../redux';
// import ComboBox from '../../Filters/StatementAffiliateFilter/Autocomplete';

const steps = ['', '', ''];
 


const UploadStatement: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [complete, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [files, setFiles] = useState<null | any>('');
  const [fileType, setFileType] = useState('single');
  const dispatch = useAppDispatch();

  const formData: any = new FormData();
  // const affiliates: any = useAppSelector((state: any) => state.support.affiliateArray);

  // const [selectedAffiliate, setSelectedAffiliate] = useState(affiliates[0]);

  const uploadedStatement = useAppSelector((store: any) => store.uploadStatement);
  const uploadedStatementFailure = useAppSelector((store: any) => store.uploadStatement.error);
  const [dateData, setDateData] = React.useState<Dayjs | null | any>(dayjs());

  const classes = uploadStatementStyles();
  const themes = useTheme();
  const isMatch = useMediaQuery(themes.breakpoints.down(616));

  let affiliatesCounter = 0;

  useEffect(() => {
    if (affiliatesCounter === 0) {
      affiliatesCounter = affiliatesCounter + 1;
      dispatch(fetchAffiliates());
    }
  }, [affiliatesCounter]);


  const handleChange = (newDate: Dayjs | null): void => {
    if (newDate) {
      setDateData(newDate);
    }
  };
  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  // // =============== Calendar ==================================

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#007DB3',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#007DB3',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled('div')<{
    ownerState: { active?: boolean };
  }>(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#007DB3',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#007DB3',
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));

  function QontoStepIcon(props: StepIconProps):any {
    const { active, completed, className } = props;
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className='QontoStepIcon-completedIcon' />
        ) : (
          <div className='QontoStepIcon-circle' />
        )}
      </QontoStepIconRoot>
    );
  }

  const totalSteps = (): number => {
    return steps.length;
  };

  const completedSteps = (): number => {
    return Object.keys(complete).length;
  };

  const isLastStep = (): boolean => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = (): boolean => {
    return completedSteps() === totalSteps();
  };

  const handleNext = (): void => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in complete))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };
  const modifiedDate = dateData.$d.toString().split(' ');


  const uploadStatementRequest: any = () => {
    if (fileType === 'single') {
      const file = files;
      const fileNameParts = file?.name.split('_'); // Split the file name into parts
      let cleanedFileName = ''; // Initialize the cleaned file name

      // Regular expression to match the date pattern (YYYYMonDD) after the affiliate name
      const datePattern =
        /^[0-9]{4}(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[0-9]{2}$/;

      // Check if the second part (after the affiliate name) matches the date pattern
      if (fileNameParts[1] && datePattern.test(fileNameParts[1])) {
        // If the second part is a date, remove the first two parts (affiliate and date)
        cleanedFileName = fileNameParts.slice(2).join('_');
      } else {
        // If there is no date, start from the first part (affiliate)
        cleanedFileName = fileNameParts.join('_');
      }
      const myRenamedFile = new File(
        [file],
        `${''}_${modifiedDate[3]}${modifiedDate[1]}${modifiedDate[2]}_${cleanedFileName}`,
        {
          type: file.type,
          lastModified: file.lastModifiedDate,
        },
      );
      formData.append('file', myRenamedFile);
      dispatch(uploadStatementRedux(formData, modifiedDate, 'single'));
      const interval = setInterval(() => {
        if (
          !uploadedStatement.loading &&
          uploadedStatement.response.success &&
          uploadedStatement.fetched
        ) {
          handleNext();
          clearInterval(interval);
        }
      }, 1000);
    }
    if (fileType === 'multiple') {
      // Loop through the files array
    files.forEach((file : null | any) => {
      // Create a new File object with the desired file name
      const fileNameParts = file?.name.split('_'); // Split the file name into parts
      let cleanedFileName = ''; // Initialize the cleaned file name
      
      // Regular expression to match the date pattern (YYYYMonDD) after the affiliate name
      const datePattern = /^[0-9]{4}(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[0-9]{2}$/;
      
      // Check if the second part (after the affiliate name) matches the date pattern
      if (fileNameParts[1] && datePattern.test(fileNameParts[1])) {
        // If the second part is a date, remove the first two parts (affiliate and date)
        cleanedFileName = fileNameParts.slice(2).join('_');
      } else {
        // If there is no date, start from the first part (affiliate)
        cleanedFileName = fileNameParts.join('_');
      }
      const myRenamedFile = new File(
          [file],
         `${''}_${modifiedDate[3]}${modifiedDate[1]}${modifiedDate[2]}_${cleanedFileName}`,
          {
              type: file.type,
              lastModified: file.lastModifiedDate,
          },
      );

      // Append the renamed file to the FormData object
      formData.append('files', myRenamedFile);
  });

  dispatch(uploadStatementRedux(formData, modifiedDate, 'multiple'));
  const interval = setInterval(() => {
    if (
        !uploadedStatement.loading &&
        uploadedStatement.response.success &&
        uploadedStatement.fetched
    ) {
        handleNext();
        clearInterval(interval);
    }
}, 1000);

    }

    return;
  };


  const checkUpload = async (): Promise<boolean> => {
    const areAllStepsCompleted = completedSteps() === totalSteps() - 1;
    return areAllStepsCompleted ;
  };

  const handleComplete = async (): Promise<any> => {
    if (completedSteps() === totalSteps() - 1) {
      await uploadStatementRequest();
      const statementUploaded = await checkUpload();
      if (statementUploaded) {
        const newCompleted = complete;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
      }
    } else {
      const newCompleted = complete;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    }
  };

  const handleReset = (): void => {
    handleClose();
    setActiveStep(0);
    setCompleted({});
    setFiles('');
  };

  const handleOpenUpload = (event: any): any => {
    const totalFilesToUpload = event.target.files.length;
    if (totalFilesToUpload < 2) {
      setFiles(event.target.files[0]);
      setFileType('single');
    } else {
      const filesToUpload: any = [];
      for (let i = 0; i < totalFilesToUpload; i++) {
        formData.append('files', event.target.files[i]);
        filesToUpload.push(event.target.files[i]);
      }
      setFiles(filesToUpload);
      setFileType('multiple');
    }
  };



  return (
    <div>
      <Button onClick={handleOpen} className={classes.addUser} variant="contained">
        {!isMatch ? ' + Add Statement' : '+'}
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleReset}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.modal}>
            <CloseIconModals setSnackClose={handleReset} />

            <Box sx={{ width: '100%' }}>
              <Stepper
                className={classes.stepper}
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                {steps.map((label, index) => (
                  <Step key={index} completed={complete[index]}>
                    <StepLabel
                      StepIconComponent={QontoStepIcon}
                      onClick={handleStep(index)}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                {allStepsCompleted() ? (
                  <div>
                    <Typography
                      sx={{ mt: 2, mb: 1 }}
                      className={classes.uploadCompleteText}
                    >
                      {uploadedStatementFailure
                        ? 'Your File has not Been Uploaded!'
                        : 'Your File has Been Uploaded!'}
                    </Typography>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      Your statement has
                      {uploadedStatementFailure ? ' not ' : ' '}
                      been added.
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleReset} className={classes.updateButton}>
                        Reset
                      </Button>
                    </Box>
                  </div>
                ) : (
                  <div>
                    <Typography sx={{ mt: 2, mb: 0, pt: 1 }} component="div">
                      {activeStep === 0 ? (
                        <>
                          <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            className={classes.modalTitle}
                          >
                            Upload File
                          </Typography>
                          <label htmlFor="upload-photo">
                            <input
                              className={classes.inputModal}
                              id="upload-photo"
                              name={`Armstrong__${modifiedDate[3]}${modifiedDate[1]}${modifiedDate[2]}_${files.name}`}
                              type="file"
                              onChange={(e) => handleOpenUpload(e)}
                              accept=".xlsx,.pdf"
                              multiple={true}
                            />
                            <Fab
                              sx={{ width: '-webkit-fill-available' }}
                              size="large"
                              component="span"
                              aria-label="add"
                              variant="extended"
                              className={classes.uploadBox}
                            >
                              {Array.isArray(files) || files?.name ? (
                                Array.isArray(files) ? (
                                  <div
                                    className={files.length > 4 ? 'upload-scroll' : ''}
                                  >
                                    <Grid container direction="column">
                                      {files.map((f: null | any) => (
                                        <Box key={f.name} sx={{ display: 'block' }}>
                                          {f.name}
                                        </Box>
                                      ))}
                                    </Grid>
                                  </div>
                                ) : (
                                  files.name
                                )
                              ) : (
                                <>
                                  <AddIcon /> Upload File
                                </>
                              )}
                            </Fab>
                          </label>
                          <Typography component="div" className={classes.fileType}>
                            <InfoUpload /> &nbsp; Accepted file types are XLSL and PDF
                          </Typography>
                        </>
                      ) : (
                        ''
                      )}
                      {activeStep === 1 ? (
                        <>
                          <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            className={classes.modalTitle}
                          >
                            Edit Details
                          </Typography>
                          <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            className={classes.fileTitle}
                          >
                            <FileAttatchment /> &nbsp; File
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <Chip
                              label={
                                Array.isArray(files) ? (
                                  <div className={files.length > 4 ? 'upload-scroll-second' : '' }>
                                      {files.map((file: any | null, index: number) => (
                                        <Box key={index} className={classes.chipGap}>{file.name}</Box>
                                      ))}
                                      </div>
                                ) : (
                                  files.name
                                )
                              }
                              deleteIcon={<CloseIconModals setSnackClose={handleClose} />}
                              className={classes.fileChip}
                            />
                          </Stack>
                          <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            className={classes.fileTitle}
                          >
                            <CalendarEdit /> &nbsp; Statement Date
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <MobileDatePicker
                                label="Date Desktop"
                                inputFormat="MM/DD/YYYY"
                                value={dateData}
                                onChange={handleChange}
                                className="calendarTitHead"
                                renderInput={(params: any) => <TextField {...params} />}
                              />
                            </Stack>
                          </LocalizationProvider>
                          {/* <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            className={classes.affiliateTitle}
                          >
                            <Affiliate /> &nbsp; Select Affiliate
                          </Typography> */}
                          {/* {affiliates?.length > 0 ? (
                            <ComboBox
                              options={affiliates}
                              setOption={setSelectedAffiliate}
                            />
                          ) : (
                            ''
                          )} */}
                        </>
                      ) : (
                        ''
                      )}
                      {activeStep === 2 ? (
                        <>
                          <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            className={classes.modalTitle}
                          >
                            Edit Details
                          </Typography>
                          <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            className={classes.fileTitle}
                          >
                            <FileAttatchment /> &nbsp; File
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <Chip
                              label={
                                Array.isArray(files) ? (
                                  <Box>
                                    <div
                                      className={
                                        files.length > 4 ? 'upload-scroll-second' : ''
                                      }
                                    >
                                      {files.map((file: any | null, index: number) => (
                                        <Box key={index}>{file.name}</Box>
                                      ))}
                                    </div>
                                  </Box>
                                ) : (
                                  files.name
                                )
                              }
                              deleteIcon={<CloseIconModals setSnackClose={handleClose} />}
                              className={classes.fileChip}
                            />
                          </Stack>
                          <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            className={classes.fileTitle}
                          >
                            <CalendarEdit /> &nbsp; Statement Date
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3} className="cal">
                              <MobileDatePicker
                                label="Date mobile"
                                inputFormat="MM/DD/YYYY"
                                value={dateData}
                                openTo="year"
                                onChange={handleChange}
                                className="calendarTitHead"
                                renderInput={(params: any) => (
                                  <TextField
                                    size="small"
                                    required
                                    className={'red-asterik'}
                                    {...params}
                                  />
                                )}
                              />
                            </Stack>
                          </LocalizationProvider>
                          {/* <Typography component="div" className={Array.isArray(files) ? classes.statementName2 : classes.statementName}> */}
                            {/* <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h2"
                              className={classes.fileTitle}
                            >
                              <StatementName /> &nbsp; {Array.isArray(files) ? 'Statement Names' : 'Statement Name'}
                            </Typography> */}
                            {/* {selectedAffiliate?.AffiliateName === undefined ? (
                              <TextField
                                error
                                required
                                className={` red-asterik ${classes.textFields}`}
                                size="small"
                                id="outlined-basic"
                                name="username"
                                value={
                                      `${selectedAffiliate?.AffiliateName}__${modifiedDate[3]}${modifiedDate[1]}${modifiedDate[2]}_${files?.name}`
                                }
                                label="Statement Name"
                                variant="outlined"
                                inputProps={{ readOnly: true }}
                                helperText="Incorrect Filename."
                              />
                            ) : (
                              <div >
                                {Array.isArray(files) ? (
                                  
                                  <div className={files.length > 4 ? `upload-scroll-third ${classes.StatNameHeight}` : classes.StatNameHeight} >{
                                  files.map((f: any | null) => (
                                    <TextField
                                      key={f.name}
                                      required
                                      className={ ` red-asterik ${classes.textFields2} ` }
                                      sx={{ marginBottom:'-5% !important', fontFamily:'sans-serif !important'  }}
                                      size="small"
                                      id="outlined-basic"
                                      name="username"
                                      value={`${selectedAffiliate?.AffiliateName}__${modifiedDate[3]}${modifiedDate[1]}${modifiedDate[2]}_${f.name}`}
                                      variant="outlined"
                                      inputProps={{ readOnly: true }}
                                      
                                    />
                                  ))
                                  }
                                  </div>
                                ) : (
                                  <TextField
                                    required
                                    className={` red-asterik ${classes.textFields} `}
                                    size="small"
                                    id="outlined-basic"
                                    name="username"
                                    value={ `${selectedAffiliate?.AffiliateName}__${modifiedDate[3]}${modifiedDate[1]}${modifiedDate[2]}_${files?.name}`
                                    }
                                    label="Statement Name"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                )}
                              </div>
                            )} */}
                          {/* </Typography> */}
                        </>
                      ) : (
                        ''
                      )}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {activeStep !== steps.length &&
                        (complete[activeStep] ? (
                          <Typography variant="caption" sx={{ display: 'inline-block' }}>
                            Step {activeStep + 1} already completed
                          </Typography>
                        ) : Array.isArray(files) || files?.name ? (
                          <Button
                            onClick={handleComplete}
                            className={classes.updateButton}
                          >
                            {completedSteps() === totalSteps() - 0
                              ? 'Finish'
                              : 'Upload File'}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleComplete}
                            className={classes.updateButton}
                            disabled
                          >
                            {completedSteps() === totalSteps() - 0
                              ? 'Finish'
                              : 'Upload File'}
                          </Button>
                        ))}
                    </Box>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default UploadStatement;