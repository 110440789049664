import {
  FETCH_WEEKLY_REVENUES_REQUEST,
  FETCH_WEEKLY_REVENUES_SUCCESS,
  FETCH_WEEKLY_REVENUES_FAILURE,
  FETCH_WEEKLY_REVENUES_COMPARE_REQUEST,
  FETCH_WEEKLY_REVENUES_COMPARE_SUCCESS,
  FETCH_WEEKLY_REVENUES_COMPARE_FAILURE,
} from '../../actions/performance/affiliateWeeklyRevenues/weeklyRevenuesActionTypes';

const initialState = {
  loading: false,
  weeklyRevenues: [],
  error: '',
  fetched: false,
  weeklyRevenuesCompare: [],
};

const userReducer = (  state = initialState, action: any):any => {
  switch (action.type) {
    case FETCH_WEEKLY_REVENUES_REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      };
    case FETCH_WEEKLY_REVENUES_SUCCESS:
      return {
        ...state,
        loading: false,
        weeklyRevenues: action.payload,
        fetched: true,
        error: '',
      };
    case FETCH_WEEKLY_REVENUES_FAILURE:
      return {
        ...state,
        loading: false,
        weeklyRevenues: [],
        error: action.payload,
        fetched: false,
      };
    case FETCH_WEEKLY_REVENUES_COMPARE_REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      };
    case FETCH_WEEKLY_REVENUES_COMPARE_SUCCESS:
      return {
        ...state,
        loading: false,
        weeklyRevenuesCompare: action.payload,
        fetched: true,
        error: '',
      };
    case FETCH_WEEKLY_REVENUES_COMPARE_FAILURE:
      return {
        ...state,
        loading: false,
        weeklyRevenues: [],
        error: action.payload,
        fetched: false,
      };
    default:
      return state;
  }
};

export default userReducer;
