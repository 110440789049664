import React from 'react';

const Affiliate: React.FC = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66683 9.33345V10.6668H0.333496V9.33345C0.333496 9.33345 0.333496 6.66678 5.00016 6.66678C9.66683 6.66678 9.66683 9.33345 9.66683 9.33345ZM7.3335 3.00012C7.3335 2.53863 7.19665 2.0875 6.94026 1.70379C6.68387 1.32007 6.31945 1.021 5.89309 0.844398C5.46673 0.667793 4.99757 0.621585 4.54495 0.711618C4.09233 0.80165 3.67657 1.02388 3.35025 1.3502C3.02392 1.67652 2.8017 2.09228 2.71166 2.54491C2.62163 2.99753 2.66784 3.46668 2.84444 3.89304C3.02105 4.3194 3.32012 4.68382 3.70383 4.94021C4.08755 5.1966 4.53867 5.33345 5.00016 5.33345C5.619 5.33345 6.21249 5.08762 6.65008 4.65003C7.08766 4.21245 7.3335 3.61895 7.3335 3.00012V3.00012ZM9.62683 6.66678C10.0367 6.98395 10.372 7.38707 10.6093 7.84779C10.8465 8.3085 10.98 8.81562 11.0002 9.33345V10.6668H13.6668V9.33345C13.6668 9.33345 13.6668 6.91345 9.62683 6.66678ZM9.00016 0.666783C8.54129 0.664228 8.09251 0.80142 7.7135 1.06012C8.11846 1.62594 8.3362 2.30431 8.3362 3.00012C8.3362 3.69593 8.11846 4.37429 7.7135 4.94012C8.09251 5.19881 8.54129 5.336 9.00016 5.33345C9.619 5.33345 10.2125 5.08762 10.6501 4.65003C11.0877 4.21245 11.3335 3.61895 11.3335 3.00012C11.3335 2.38128 11.0877 1.78779 10.6501 1.3502C10.2125 0.912616 9.619 0.666783 9.00016 0.666783V0.666783Z"
        fill="#001B33"
      />
    </svg>
  );
};

export default Affiliate;
