import {
  FETCH_ADMIN_AFFILIATES_REQUEST,
  FETCH_ADMIN_AFFILIATES_SUCCESS,
  FETCH_ADMIN_AFFILIATES_FAILURE,
  ADD_AFFILIATE_REQUEST,
  ADD_AFFILIATE_SUCCESS,
  ADD_AFFILIATE_FAILURE,
  EDIT_AFFILIATE_REQUEST,
  EDIT_AFFILIATE_SUCCESS,
  EDIT_AFFILIATE_FAILURE,
  DELETE_AFFILIATE_REQUEST,
  DELETE_AFFILIATE_SUCCESS,
  DELETE_AFFILIATE_FAILURE,
} from './adminAffliatesActionType';
import { store } from '../../../app/store';
import axios from 'axios';
import { alertAction } from '../Alert/alertActions';
import { loaderAction } from '../loader/loaderActions';
import secureLocalStorage from 'react-secure-storage';

//////////////////////////////////////// GET AFFILIATES ///////////////////////////////////////////////
interface AFFILIATES {
  AppUserID: number;
  AffiliateName: Array<string>;
  UserName: string;
  Email: string;
  Detail: any;
}
interface Header {
  AffiliateName: Array<string>;
  UserName: string;
  UserEmail: string;
  Email: string;
  Password: string;
  IsNewPassword: boolean;
}
interface HeaderEdit {
  AffiliateName: Array<string>;
  UserName: string;
  UserID: number;
  Email: string;
  Password: string;
  IsNewPassword: boolean;
}

const fetchAdminAffiliateRequest: any = () => {
  return {
    type: FETCH_ADMIN_AFFILIATES_REQUEST,
  };
};

const fetchAdminAffiliateSuccess: any = (AFFILIATES: AFFILIATES) => {
  return {
    type: FETCH_ADMIN_AFFILIATES_SUCCESS,
    payload: AFFILIATES,
  };
};

const fetchAdminAffiliateFailure: any = (error: string) => {
  return {
    type: FETCH_ADMIN_AFFILIATES_FAILURE,
    payload: error,
  };
};

export const fetchAdminAffiliates: any = () => {

  let token = store.getState().user.token;
  if (!token || token.length === 0) {
    token = secureLocalStorage.getItem('sessionToken');
  }
  return function (dispatch: any) {
    dispatch(loaderAction(true) as any);
    dispatch(fetchAdminAffiliateRequest());

    axios
      .get(`${process.env.REACT_APP_BACKEND}api/UserAffiliateManage/GetUserAffiliates`, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((response) => {
        const AFFILIATES = response.data;
        AFFILIATES.forEach((obj: any) => {
          if (obj.AffiliateName !== null) {
            obj.AffiliateName = obj.AffiliateName.split(',');
          }
        });
        dispatch(fetchAdminAffiliateSuccess(AFFILIATES));
        dispatch(loaderAction(false) as any);
      })
      .catch((error) => {
        dispatch(fetchAdminAffiliateFailure(error.response.data.Message));
        dispatch(alertAction(`${error.response.data.Message}`, true, 'error') as any);
        dispatch(loaderAction(false) as any);
      });
  };
};

//////////////////////////////////////// ADD AFFILIATE ///////////////////////////////////////////////

const addAffiliateRequest: any = () => {
  return {
    type: ADD_AFFILIATE_REQUEST,
  };
};

const addAffiliateSuccess: any = (AFFILIATE: AFFILIATES) => {
  return {
    type: ADD_AFFILIATE_SUCCESS,
    payload: AFFILIATE,
  };
};

const addAffiliateFailure: any = (error: string) => {
  return {
    type: ADD_AFFILIATE_FAILURE,
    payload: error,
  };
};

export const addAffiliate: any = (headerValue: Header) => {
  const token = store.getState().user.token;

  return function (dispatch: any) {
    dispatch(addAffiliateRequest());
    dispatch(loaderAction(true) as any);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND}api/UserAffiliateManage/SaveUserAffiliates`,
      headers: {
        UserAffiliateFilter: JSON.stringify(headerValue),
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const AFFILIATES = response.data;
        dispatch(addAffiliateSuccess(AFFILIATES));
        dispatch(alertAction('New User Added ...', true, 'success'));
        dispatch(fetchAdminAffiliates());
        dispatch(loaderAction(false) as any);
      })
      .catch((error) => {
        dispatch(alertAction(`${error.response.data.Message}`, true, 'error') as any);
        dispatch(addAffiliateFailure(error.response.data.Message));
        dispatch(loaderAction(false) as any);
      });
  };
};

//////////////////////////////////////// EDIT AFFILIATE ///////////////////////////////////////////////

const editAffiliateRequest: any = () => {
  return {
    type: EDIT_AFFILIATE_REQUEST,
  };
};

const editAffiliateSuccess: any = (AFFILIATE: AFFILIATES) => {
  return {
    type: EDIT_AFFILIATE_SUCCESS,
    payload: AFFILIATE,
  };
};

const editAffiliateFailure: any = (error: string) => {
  return {
    type: EDIT_AFFILIATE_FAILURE,
    payload: error,
  };
};

export const editAffiliate: any = (headerValue: HeaderEdit) => {
  const token = store.getState().user.token;

  return function (dispatch: any) {
    dispatch(editAffiliateRequest());
    dispatch(loaderAction(true) as any);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND}api/UserAffiliateManage/UpdateUserAffiliates`,
      headers: {
        UserAffiliateFilter: JSON.stringify(headerValue),
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const AFFILIATES = response.data;
        dispatch(editAffiliateSuccess(AFFILIATES));
        dispatch(alertAction('User Edit Successfully ...', true, 'success'));
        dispatch(fetchAdminAffiliates());
        dispatch(loaderAction(false) as any);
      })
      .catch((error) => {
        dispatch(alertAction(`${error.response.data.Message}`, true, 'error') as any);
        dispatch(editAffiliateFailure(error.response.data.Message));
        dispatch(loaderAction(false) as any);
      });
  };
};

////////////////////////////////////// DELETE AFFILIATE //////////////////////////////////////

const deleteAffiliateRequest: any = () => {
  return {
    type: DELETE_AFFILIATE_REQUEST,
  };
};

const deleteAffiliateSuccess: any = (AFFILIATE: AFFILIATES) => {
  return {
    type: DELETE_AFFILIATE_SUCCESS,
    payload: AFFILIATE,
  };
};

const deleteAffiliateFailure: any = (error: string) => {
  return {
    type: DELETE_AFFILIATE_FAILURE,
    payload: error,
  };
};

export const deleteAffiliate: any = (deleteId: string) => {
  const token = store.getState().user.token;
  return function (dispatch: any) {
    dispatch(deleteAffiliateRequest());
    dispatch(loaderAction(true) as any);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND}api/UserAffiliateManage/DeleteUser`,
      data: deleteId,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json-patch+json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const AFFILIATES = response.data;
        dispatch(deleteAffiliateSuccess(AFFILIATES));
        dispatch(alertAction('User Deleted Successfully ...', true, 'success'));

        dispatch(fetchAdminAffiliates());
        dispatch(loaderAction(false) as any);
      })
      .catch((error) => {
        dispatch(alertAction(`${error.response.data.Message}`, true, 'error'));
        dispatch(deleteAffiliateFailure(error.response.data.Message));
        dispatch(loaderAction(false) as any);
      });
  };
};
