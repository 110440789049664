import {
  FETCH_REVENUE_BY_REQUEST,
  FETCH_REVENUE_BY_SUCCESS,
  FETCH_REVENUE_BY_FAILURE,
} from '../../actions/performance/affiliateRevenueBy/revenueByActionTypes';

const initialState = {
  loading: false,
  revenueBy: [],
  error: '',
  fetched: false,
};

const revenueByReducer = ( state = initialState, action: any):any => {
  switch (action.type) {
    case FETCH_REVENUE_BY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REVENUE_BY_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueBy: action.payload,
        error: '',
        fetched: true,
      };
    case FETCH_REVENUE_BY_FAILURE:
      return {
        ...state,
        loading: false,
        revenueBy: [],
        error: action.payload,
        fetched: false,
      };
    default:
      return state;
  }
};

export default revenueByReducer;
