import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Checkbox } from '@material-ui/core';
import { salesMarketFilterStyles } from '../styles';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { applyDataChange, setSelectedSalesMarketsRedux } from '../../../redux';

interface SalesfilterType {
  label: string;
  selectAllLabel: string;
  limitTags: number;
  placeholder: string;
}



const ExportSalesBox = ({ label, selectAllLabel, limitTags }: SalesfilterType): any => {
  const items = useAppSelector((store: any) => store.salesMarkets.salesMarkets);
  const selectedSalesMarket = useAppSelector((store: any) => store.selectedValue.salesMarkets);
  const [selectedOptions, setSelectedOptions] = useState<any>(items);

  


  useEffect(() => {
    if (selectedSalesMarket && selectedSalesMarket.length > 0) {
      setSelectedOptions(selectedSalesMarket);
    }
  }, [selectedSalesMarket]);

  const dispatch = useAppDispatch();

  let initial = items; 


  const allSelected = items.length === selectedOptions.length;
  const handleToggleOption = (selectedOption: any): void =>
    setSelectedOptions(selectedOption);
  const handleClearOptions = (): void => setSelectedOptions([]);

  const getOptionLabel:any = (option: any): string => `${option.label}`;

  const handleSelectAll = (isSelected: boolean): void => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  useEffect(() => {
    if (selectedOptions.length > 0) {
      dispatch(setSelectedSalesMarketsRedux(selectedOptions));
    }
    if (initial !== selectedOptions) {
    dispatch(setSelectedSalesMarketsRedux(selectedOptions));
      dispatch(applyDataChange(true));
      initial = selectedOptions;
    }
  }, [selectedOptions]);

  const handleToggleSelectAll = (): void => (
    handleSelectAll && handleSelectAll(!allSelected)
  );

  useEffect(() => {
    handleSelectAll(true);
  }, [items]);

  const handleChange = (
    event: React.SyntheticEvent,
    selectedOption: any,
    reason: any,
  ): any => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOption.find((option: any) => option.value === 'select-all')) {
        handleToggleSelectAll();

      } else {
        handleToggleOption(selectedOption);
      }
    } else if (reason === 'clear') {
      handleClearOptions();
    }
  };
  const optionRenderer = (props: any, option: any, { selected }: any): any => {
    const selectAllProps =
      items.length > 0 && option.value === 'select-all' // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <>
        <li {...props}>
          <Checkbox
            color="primary"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={selected}
            {...selectAllProps}
          />
          {getOptionLabel(option)}
        </li>
      </>
    );
  };
  const inputRenderer = (params: any): JSX.Element => (
    <>
      <TextField
        {...params}
        className='red-asterik'
        required
        label={
          <>
          {label}
          </>
        }
        variant="outlined"
      />
    </>
  );
  const [sortedOptions, setSortedOptions] = useState(
    [...items].sort((a, b) => (a.label > b.label ? 1 : -1)),
  );
  useEffect(() => {
    setSortedOptions([...items].sort((a, b) => (a.label > b.label ? 1 : -1)));
  }, [items]);

  const filter = createFilterOptions();
  const classes = salesMarketFilterStyles();
  return (
    <Autocomplete
      includeInputInList
      size="small"
      multiple
      limitTags={limitTags}
      className={`${classes.filterMain} comboBox chips`}
      options={sortedOptions}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option: any, value: any) => {
        return option.value === value.value;
      }}
      filterOptions={(options, params) => {
        if (sortedOptions.length > 0) {
          const filtered = filter(options, params);
          return [{ label: selectAllLabel, value: 'select-all' }, ...filtered] as any;
        } else if (sortedOptions.length === 0) {
          const filtered = filter(options, params);
          return [...filtered] as any;
        }
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
};

ExportSalesBox.defaultProps = {
  limitTags: 5,
  items: [],
  selectedValues: [],
  getOptionLabel: (value: any) => value,
};

export default ExportSalesBox;
