import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import 'rc-pagination/assets/index.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAdminAffiliates } from '../../redux';
import { adminStyles } from './styles';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import OptionsModal from '../../shared/Modals/OptionsModal/OptionsModal';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import AddUser from '../../shared/Modals/AddUser/AddUser';
import './Pagination.css';
import SearchBar from '../../shared/Search/Search';
import Paginations from '../../shared/Pagination/Pagination';

interface CollectionAffiliate {
  AppUserID: number;
  AffiliateName: Array<string>;
  UserName: string;
  Email: string;
  Detail: any;
}

const Admin: any = () => {


  const classes: any = adminStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(920));

  const affiliate: any = useAppSelector((state: any) => state.admin.affiliates);
  affiliate.sort((a:any, b:any) => {
    const nameA = a.UserName.toUpperCase(); 
    const nameB = b.UserName.toUpperCase(); 
  
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  const fetched: boolean = useAppSelector((store: any) => store.admin.fetched);
  const countPerPage = 10;
  let affiliateCounter = 0;

  const [value, setValue] = React.useState('');
  const [expandedId, setExpandedId] = useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [collection, setCollection] = React.useState(
    cloneDeep(cloneDeep(affiliate.slice(0, countPerPage))),
  );
  const [filteredData, setFilteredData] = useState(affiliate);
  const dispatch: any = useAppDispatch();
  useEffect(() => {
    setCollection(affiliate.slice(0, countPerPage));
    setFilteredData(affiliate);
    setCurrentPage(1);
  }, [fetched]);

  useEffect(() => {
    if (affiliateCounter === 0) {
      affiliateCounter = affiliateCounter + 1;
      dispatch(fetchAdminAffiliates());
    }
  }, []);


  const searchData2: any = () => {
    let filteredAffiliates;
    if (value && value.length > 0) {
      filteredAffiliates = affiliate.filter(
        (row: CollectionAffiliate) =>
          row.UserName.toLowerCase().includes(value.toLowerCase()) ||
          row.Email.toLowerCase().includes(value.toLowerCase()),
      );
    } else {
      filteredAffiliates = affiliate;
    }
    setFilteredData(filteredAffiliates);
    const from = (currentPage - 1) * countPerPage;
    const to = from + countPerPage;
    setCollection(filteredAffiliates.slice(from, to));
  };

  React.useEffect(() => {
    searchData2();
  }, [value, currentPage]);

  const updatePage: any = (p: number) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(affiliate.slice(from, to)));
  };

  const showmore: any = (AppUserID: number) => {
    setExpandedId(AppUserID);
  };
  const showless: any = () => {
    setExpandedId(0);
  };

  const tableData: any = () => {
       return (
      collection &&
      collection.map((row: CollectionAffiliate, index: number) => {
        return (
          <Card className={classes.card} key={index}>
            {isMatch ? (
              <div className={classes.mobOptions}>
                <OptionsModal row={row} setCurrentPage={setCurrentPage} />
              </div>
            ) : (
              ''
            )}
            <Typography className={classes.userInfo}>
              <Typography className={classes.userInfoContent}>
                <Typography className={classes.userIMG}>
                  {row.UserName.match(/\b(\w)/g)
                    ?.join()
                    .toUpperCase()
                    .split(',')}
                </Typography>
                <Typography className={classes.userNameEmail}>
                  <Typography className={classes.userName}>{row.UserName}</Typography>
                  <Link className={classes.userEmail}>{row.Email}</Link>
                </Typography>
              </Typography>
            </Typography>

            <Typography className={classes.affiliatesInfo}>
              <Typography className={classes.affiliateTitle}> Affiliates</Typography>
              <Typography className={classes.allAffiliateDiv}>
                {row && row.AffiliateName && row.AppUserID === expandedId
                  ? row.AffiliateName.map((x: string, ind: number) => (
                    <Typography className={classes.singleAffiliate} key={ind}>
                      {x}
                    </Typography>
                  ))
                  : row &&
                  row.AffiliateName &&
                  row.AffiliateName.slice(0, 5).map((x: string, i: number) => (
                    <Typography className={classes.singleAffiliate} key={i}>
                      {x}
                    </Typography>
                  ))}

                {row.AffiliateName && row.AffiliateName.length > 5 ? (
                  row.AppUserID !== expandedId ? (
                    <Button
                      onClick={() => showmore(row.AppUserID)}
                      className={classes.showMoreLess}
                    >
                      + and {row.AffiliateName.length - 5} others
                    </Button>
                  ) : (
                    <Button className={classes.showMoreLess} onClick={() => showless()}>
                      Show Less
                    </Button>
                  )
                ) : (
                  ''
                )}
              </Typography>
            </Typography>
            {!isMatch ? (
              <div>
                <OptionsModal row={row} setCurrentPage={setCurrentPage} />
              </div>
            ) : (
              ''
            )}
          </Card>
        );
      })
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
    setCurrentPage(1);
  };

  return (
    <>
      <Typography component="div" className={classes.mainDiv}>
        <Typography component="div" className={classes.titleDiv}>
          <Typography component="div" className={classes.title}>
            Admin
          </Typography>
          <AddUser />
        </Typography>
      <SearchBar values={value} onChange={handleChange}/>

        <table className={classes.table}>
          <tbody>{tableData()}</tbody>
        </table>
       
        <Paginations 
          updatePage={updatePage} 
          updatePage1={()=>updatePage(1)} 
          currentPage={currentPage} 
          countPerPage={countPerPage} 
          filteredData={filteredData} 
          updatePage2={() =>
            updatePage(Math.floor((filteredData.length / 10) % 10 === 0 ? filteredData.length / 10 : filteredData.length / 10 + 1),
            )}
        />
      </Typography>
    </>
  );
};
export default Admin;
