import React from 'react';

const ButtonArrowLeft: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#F2F3F5" />
      <path
        d="M18.2735 12.94L17.3335 12L13.3335 16L17.3335 20L18.2735 19.06L15.2202 16L18.2735 12.94Z"
        fill="#001B33"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#CCD1D6" />
    </svg>
  );
};

export default ButtonArrowLeft;
