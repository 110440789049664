export const FETCH_STATEMENT_REQUEST = 'FETCH_STATEMENT_REQUEST';
export const FETCH_STATEMENT_SUCCESS = 'FETCH_STATEMENT_SUCCESS';
export const FETCH_STATEMENT_FAILURE = 'FETCH_STATEMENT_FAILURE';

export const UPLOAD_STATEMENT_REQUEST = 'UPLOAD_STATEMENT_REQUEST';
export const UPLOAD_STATEMENT_SUCCESS = 'UPLOAD_STATEMENT_SUCCESS';
export const UPLOAD_STATEMENT_FAILURE = 'UPLOAD_STATEMENT_FAILURE';

export const DELETE_STATEMENT_REQUEST = 'DELETE_STATEMENT_REQUEST';
export const DELETE_STATEMENT_SUCCESS = 'DELETE_STATEMENT_SUCCESS';
export const DELETE_STATEMENT_FAILURE = 'DELETE_STATEMENT_FAILURE';
