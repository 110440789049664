import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { TextField, Typography } from '@mui/material';
import './Login.css';
import { useAppDispatch } from '../../app/hooks';
import { fetchUser, changeFetchedState, changeFetchedStateSupport } from '../../redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { loginStyles } from './styles';

export const Login: any = () => {
  const classes = loginStyles();
  const dispatch: any = useAppDispatch();

  useEffect(() => {
    dispatch(changeFetchedState());
    dispatch(changeFetchedStateSupport());
  }, []);


  //   // -------------------------PASSWORD TOGGLE---------------------------------

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginPassword, setLoginPassword] = useState({ password :'', username :'' });
  const [toggleIcon, setToggleIcon] = useState(true);

  const togglePassword: any = () => {
    setPasswordVisible(!passwordVisible);
    setToggleIcon(!toggleIcon);
  };
const navigate = useNavigate();
  const handleLogin: any = () => {
    if (loginPassword.username.length > 0 && loginPassword.password.length > 0) {
      dispatch(
        fetchUser({
          userName: loginPassword.username,
          password: loginPassword.password,
        }),
      );
    }
 
      navigate('/performance');
  };
  const handleKeyDown: any = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLogin(e);
    }
  };

  const handleChange  = (event : any ) : any => {
    setLoginPassword((prevState) => ({
  ...prevState,
  [event.target.name] : event.target.value,
    }));

   };

  return (
    <Typography component="div" className={classes.mainDiv}>
      <Typography component="div" className={classes.loginImage}></Typography>
      <Typography component="div" className={classes.signUpBox}>
        <Typography component="div" className={classes.signInTitle}>
          Sign In
        </Typography>

        <TextField
          required
          label="Email"
          className={`${classes.username} red-asterik`}
          name='username'
          value={loginPassword.username}
          onChange={(e)=>handleChange(e)}
          onKeyDown={handleKeyDown}
        />
        <TextField
          required
          label="Password"
          autoComplete="current-password"
          className={`${classes.password} red-asterik`}
          type={passwordVisible ? 'text' : 'password'}
          name='password'
          value={loginPassword.password}
          onChange={(e)=>handleChange(e)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <>
                {toggleIcon ? (
                  <InputAdornment position="end">
                    <VisibilityIcon
                      onClick={togglePassword}
                      className={classes.toggleIcon}
                    />
                  </InputAdornment>
                ) : (
                  <VisibilityOffIcon
                    onClick={togglePassword}
                    className={classes.toggleIcon}
                  />
                )}
              </>
            ),
          }}
        />
        <Stack direction="row" className={classes.signInButtonStack}>
          <Button
            variant="contained"
            className={classes.signInButton}
            onClick={handleLogin}
            type="submit"
          >
           
            Sign In
          </Button>
        </Stack>
        <Typography component="h1" className={classes.resetPassword}>
          <Link
            to="./resetPassword"
            color="inherit"
            className={classes.resetPasswordLink}
          >
            Reset Password
          </Link>
        </Typography>
      </Typography>
    </Typography>
  );
};
