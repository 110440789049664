import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(() => ({
  loader: {
    position: 'fixed',
    top: '64px',
    left: 0,
    height: '100vh' /* to make it responsive */,
    width: '100vw' /* to make it responsive */,
    overflow: 'hidden' /*to remove scrollbars */,
    backgroundColor: '#FFFFFF',
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 2s linear',
    zIndex: '99999 !important' as any,
  },
  fadeOut: {
    backgroundColor: '#FFFFFFdb',
    position: 'fixed',
    top: '84px',
    left: 0,
    height: '100vh' /* to make it responsive */,
    width: '100vw' /* to make it responsive */,
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s .5s, opacity .5s linear',
  },
  loaderImg: {
    position: 'relative',
    top: 'calc(50% - 60px)',
    left: 'calc(50% - 60px)',
  },
}));
