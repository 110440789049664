import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const statementStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainDiv: {
      marginTop: '136px!important',
      minHeight: '86vh!important',
      padding: '0 14%',
      [theme.breakpoints.between(1240, 1448)]: {
        marginTop: '136px!important',
      },
      [theme.breakpoints.between(920, 1240)]: {
        padding: '0 4%',
      },
      [theme.breakpoints.down(920)]: {
        padding: '0 4%',
        marginTop: '120px!important',
      },
    },
    titleDiv: {
      display: 'flex',
      justifyContent: 'space-between!important',
      width: '100%',
      [theme.breakpoints.down(920)]: {
        marginBottom: '12px!important',
      },
    },
    title: {
      fontSize: '39.81px!important',
      color: '#001B33 !important',
    },
    addUser: {
      display: 'flex',
      alignItems: 'center!important',
    },
    searchDiv: {
      marginBottom: '24px!important',
      display: 'flex',
      alignItems: 'end!important',
      [theme.breakpoints.between(616, 920)]: {
        display: 'block',
        marginBottom: '8px!important',
      },
      [theme.breakpoints.between(0, 616)]: {
        display: 'block',
        marginBottom: '8px!important',
      },
    },
    paper: {
      padding: '12px 15px !important',
      borderRadius: '4px!important',
      display: 'flex !important',
      alignItems: 'center !important',
      width: '100% !important',
      boxShadow: 'none !important',
      border: '1px solid #CCD1D6 !important',
      height: '20px!important',
      fontStyle: 'italic!important',
      fontSize: '16px!important',
      [theme.breakpoints.between(616, 920)]: {
        width: '95% !important',
      },
      [theme.breakpoints.between(0, 616)]: {
        width: '90% !important',
      },
    },
    table: {
      width: '100%!important',
    },
    filterComponents: {
      display: 'flex!important',
      marginTop: '16px!important',
      [theme.breakpoints.between(0, 616)]: {
        display: 'block!important',
      },
    },
    tableHead: {
      borderBottom: '1px solid #CCD1D6!important ',
      padding: '8px 0px !important',
      display: 'flex!important',
    },
    reportDateHead: {
      width: '11.24%!important',
      fontWeight: '600!important' as any,
    },
    affiliateHead: {
      width: '14.34%!important',
      marginLeft: '2.32%!important',
      fontWeight: '600!important' as any,
    },
    reportHead: {
      width: '69.76%!important',
      marginLeft: '2.32%!important',
      fontWeight: '600!important' as any,
    },
    tableBody: {
      borderBottom: '1px solid #CCD1D6!important ',
      display: 'flex!important',
      marginBottom: '16px!important',
      padding: '2.32% 0 2.5%!important',
    },
    reportDateBody: {
      width: '11.24%!important',
      height: '2.42%!important',
    },
    affiliateBody: {
      width: '14.34%!important',
      marginLeft: '2.32%!important',
      height: '2.42%!important',
    },
    reportBody: {
      width: '52.32%!important',
      marginLeft: '2.32%!important',
    },
    fileName: {
      fontSize: '13.33px!important',
      color: '#001B33!important',
      display: 'flex!important',
      alignItems: 'center!important',
    },
    downloadReport: {
      width: '16.11%!important',
      background: '#007DB3!important',
      borderRadius: '40px!important',
      textTransform: 'capitalize!important' as any,
      marginLeft: '2%!important',
      [theme.breakpoints.between(920, 1280)]: {
        width: '21.11% !important',
      },
      [theme.breakpoints.down(920)]: {
        width: '100%!important',
        marginTop: '28px!important',
        height: '48px',
        marginLeft: '0%!important',
      },
    },
    deleteModal: {
      display: 'flex',
      justifyContent: 'center',
    },

    card: {
      display: 'flex !important',
      padding: '0px 0px 32px 24px !important',
      marginBottom: '16px!important',
      flexDirection: 'row!important' as any,
      boxShadow: 'none!important',
      border: '1px solid #CCD1D6!important',
      [theme.breakpoints.up(1448)]: {
        padding: '4px 4px 32px 24px !important',
      },
      [theme.breakpoints.between(616, 920)]: {
        display: 'block!important',
      },
      [theme.breakpoints.between(0, 616)]: {
        display: 'block!important',
      },
    },
    mobOptions: {
      float: 'right!important' as any,
    },
    userInfo: {
      width: '50%',
      paddingTop: '24px!important',
      [theme.breakpoints.between(616, 920)]: {
        width: '100%',
      },
      [theme.breakpoints.between(0, 616)]: {
        display: 'block!important',
        width: '100%',
      },
    },
    userInfoContent: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.between(0, 616)]: {
        display: 'block!important',
      },
    },
    userIMG: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#001B33',
      color: '#ffffff',
      width: '40px',
      borderRadius: '40px',
      height: '40px',
      [theme.breakpoints.between(0, 616)]: {
        marginBottom: '24px!important',
      },
    },
    userNameEmail: {
      paddingLeft: '16px !important',
      [theme.breakpoints.between(0, 616)]: {
        paddingLeft: '0px !important',
        width: '100%',
      },
    },
    userName: {
      color: '#001B33 !important',
      fontSize: '19.2px !important',
    },
    userEmail: {
      color: '#007DB3 !important',
      fontSize: '16px !important',
      textUnderlineColor: '#007DB3 !important',
      cursor: 'pointer',
    },
    affiliatesInfo: {
      marginTop: '24px!important',

      paddingLeft: '16px',
      borderLeft: '1px solid #CCD1D6',
      width: '50%',
      [theme.breakpoints.between(616, 800)]: {
        width: '90%',
        borderTop: '1px solid #CCD1D6',
        borderLeft: 'none',
        paddingLeft: '0',
        paddingTop: '16px',
        marginTop: '16px!important',
        marginLeft: '9%!important',
      },
      [theme.breakpoints.between(0, 616)]: {
        width: '100%',
        borderTop: '1px solid #CCD1D6',
        borderLeft: 'none',
        paddingLeft: '0',
        paddingTop: '16px',
        marginTop: '16px!important',
      },
      [theme.breakpoints.between(800, 920)]: {
        width: '90%',
        borderTop: '1px solid #CCD1D6',
        borderLeft: 'none',
        paddingLeft: '0',
        paddingTop: '16px',
        marginLeft: '7.4%!important',
        marginTop: '16px!important',
      },
    },
    affiliateTitle: {
      color: '#001B33!important',
      fontSize: '13.33px!important',
      [theme.breakpoints.down(920)]: {
        marginBottom: '8px!important',
      },
    },
    allAffiliateDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    singleAffiliate: {
      display: 'flex!important',
      alignItems: 'end',
      background: '#007DB3',
      borderRadius: '4px',
      border: 'none',
      fontSize: '11.33px!important',
      marginRight: '8px!important',
      marginBottom: '8px!important',
      padding: '2px 8px !important',
      color: '#ffffff',
      height: '20px!important',
    },
    showMoreLess: {
      color: 'rgba(0, 27, 51, 0.8) !important',
      background: '#E6E9EB !important',
      padding: ' 2px 8px !important',
      marginRight: '0!important',
      fontSize: '11.33px !important',
      marginBottom: '0!important',
      height: '20px',
      textTransform: 'capitalize !important' as any,
      border: '1px solid #CCD1D6 !important',
    },
    stackPagination: {
      display: 'flex !important',
      alignItems: 'center !important',
      paddingTop: '26px!important',
      marginBottom: '112px !important',
      [theme.breakpoints.between(0, 616)]: {
        marginBottom: '72px !important',
      },
      [theme.breakpoints.between(616, 1240)]: {
        marginBottom: '96px !important',
      },
    },
    Search: {
      marginRight: '15px!important',
      height: '40px!important',
    },
    lastButton: {
      height: '20px!important',
      width: '20px!important',
      border: '1px solid #CCD1D6 !important',
    },
    paginationResult1: {
      marginTop: '24px !important',
      fontSize: '.85em!important',
      color: '#667685',
    },
    paginationResult2: {
      marginTop: '0px !important',
      fontSize: '.85em!important',
      color: '#667685',
    },
    pagination: {
      display: 'inline-flex',
    },
    firstBtn: {
      cursor: 'pointer',
      margin: '1px 15px 0px 0px',
    },
    lastBtn: {
      cursor: 'pointer',
      marginLeft: '15px !important',
      [theme.breakpoints.between(0, 616)]: {
        marginLeft: '15px !important',
      },
    },
    totalAffiliateResults: {
      marginTop: '24px!important',
      color: '#667685 !important',
    },
    pageResults: {
      marginTop: '5px!important',
      color: '#667685 !important',
    },

    // ================================MOBILE=============================
    box: {
      padding: '24px 24px 32px 24px !important',
      borderRadius: '4px!important',
      border: '1px solid #CCD1D6!important',
      marginTop: '8px!important',
      width: '77vw',
      [theme.breakpoints.between(616, 920)]: {
        width: '85vw !important',
      },
    },
    yearMonthAffMob: {
      display: 'flex',
    },
    yearMonth: {
      fontSize: '16px!important',
      color: 'rgba(0, 27, 51, 0.8)!important',
      marginRight: '8px!important',
    },
    AffName: {
      fontSize: '16px!important',
      color: 'rgba(0, 27, 51, 0.8)!important',
      paddingLeft: '8px!important',
      borderLeft: '1px solid #CCD1D6',
    },
    financalStatement: {
      fontSize: '27px!important',
      lineHeight: '40px!important',
      marginBottom: '8px!important',
      wordBreak: 'break-word !important' as any,
    },
    fileNameMob: {
      fontSize: '13.3px!important',
      backgroundColor: '#E6F5FC',
      width: 'fit-content!important',
      padding: '4px!important',
      display: 'flex!important',
      alignItems: 'center!important',
      borderRadius: '4px!important',
      wordBreak: 'break-all',
    },
    deleteIcon: {
      marginTop: '0.5em !important',
      cursor: 'pointer',
    },
  }),
);
