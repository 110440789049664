import {
  FETCH_RESET_PASSWORD_REQUEST,
  FETCH_RESET_PASSWORD_SUCCESS,
  FETCH_RESET_PASSWORD_FAILURE,
} from '../../actions/resetPassword/resetPasswordActionTypes';

const initialState = {
  loading: false,
  response: '',
  error: '',
  fetched: false,
};

const resetPasswordReducer = (state = initialState, action: any):any => {
  switch (action.type) {
    case FETCH_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      };
    case FETCH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: '',
        fetched: true,
      };
    case FETCH_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        response: '',
        error: action.payload,
        fetched: false,
      };
    default:
      return state;
  }
};

export default resetPasswordReducer;
