import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
export const uploadStatementStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
      [theme.breakpoints.between(0, 920)]: {
        width: '70%',
      },
      backgroundColor: '#ffffff!important',
      borderRadius: '4px!important',
      padding: '10px 16px 32px!important',
    },
    addUser: {
      borderRadius: '40px!important',
      display: 'flex',
      alignItems: 'center!important',
      textTransform: 'capitalize !important' as any,
      background: '#007DB3!important',
      color: '#FFFFFF!important',
      boxShadow: 'none!important',
      [theme.breakpoints.between(0, 616)]: {
        width: '48px!important',
        minWidth: ' 0px!important',
        height: '48px!important',
        padding: '0px!important',
      },
    },
    modalTitle: {
      marginTop: '6px!important',
      marginBottom: '16px!important',
      fontSize: '27.65px!important' as any,
      height: '40px!important',
    },
    textFields: {
      width: '100%!important',
      marginBottom: '16px!important',
    },
    textFields2: {
      width: '100%!important',
      marginBottom: '16px!important',
    }
    ,
    inputModal: {
      display: 'none',
    },
    updateButton: {
      width: '100%!important',
      textTransform: 'capitalize!important' as any,
      borderRadius: '40px!important',
      backgroundColor: '#007DB3!important',
      padding: '10px 24px!important',
      color: 'white!important',
      marginTop: '20px!important',
    },
    resize:{
      fontSize: '12px!important',
    },
    StatNameHeight:{
      height: '90px!important',
    },
    copyButton: {
      width: '100%!important',
      textTransform: 'capitalize!important' as any,
      borderRadius: '40px!important',
      backgroundColor: '#007DB3!important',
      padding: '10px 24px!important',
      marginBottom: '8px!important',
    },

    fileType: {
      fontSize: '13.33px!important',
      color: '#667685!important',
      display: 'flex!important',
      alignItems: 'center!important',
    },
    fileTitle: {
      marginLeft: '7px!important',
      fontWeight: '700!important' as any,
      marginBottom: '8px!important',
      fontSize: '16px!important' as any,
      display: 'flex!important',
      alignItems: 'center!important',
    },
    affiliateTitle: {
      marginTop: '10px !important',
      marginLeft: '7px!important',
      fontWeight: '700!important' as any,
      marginBottom: '8px!important',
      fontSize: '16px!important' as any,
      display: 'flex!important',
      alignItems: 'center!important',
    },
    fileChip: {
      width: '100%!important',
      borderRadius: '2px!important',
      border: '1px dashed #B3BBC2!important',
      color: '#667685!important',
      height: '98px!important',
      marginBottom: '24px!important',
    },
    chipGap:{ 
      width: '100%!important',
    },
    statementName: {
      marginTop: '24px!important',
      paddingTop: '24px!important',
      borderTop: '1px solid #B3BBC2!important',
      height: '75px!important',
    },
    statementName2: {
      marginTop: '24px!important',
      paddingTop: '24px!important',
      borderTop: '1px solid #B3BBC2!important',
      height: '100px!important',
    },
    stepper: {
      width: '50%!important',
    },
    uploadCompleteText: {
      fontSize: '27.65px!important',
    },
    uploadBox: {
      fontSize: '10.65px!important',
      width: '100%!important',
      backgroundColor: '#F2F3F5!important',
      color: '#B3BBC2!important',
      boxShadow: 'none!important',
      borderRadius: '0!important',
      padding: '16px 24px!important',
      height: 'auto!important',
      textTransform: 'capitalize!important' as any,
      marginBottom: '16px!important',
      border: '1px dashed #B3BBC2!important',
    },
  }),
);
