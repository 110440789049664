/* eslint-disable import/no-extraneous-dependencies */
import { ThunkAction, Action } from '@reduxjs/toolkit';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../redux/rootReducer/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

export const store:any = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
