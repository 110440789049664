import React from 'react';
import Navbar from './shared/Navbar/Navbar';
import Footer from './shared/Footer/Footer';
import Routing from './routes/index';
import Loader from './shared/Loader/Loader';
import { useAppSelector } from './app/hooks';
import PositionedSnackbar from './shared/Alert';
import './App.css';

const App: React.FC = () => {
  const loading = useAppSelector((store:any) => store.loader.loading);
  return (
    <div className="App">
      <Loader isLoading={loading}/>
      <Navbar/>
      <Routing/>
      <Footer/>
      <PositionedSnackbar/>
    </div>
  );
};

export default App;
