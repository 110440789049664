import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  CHANGE_FETCHED_STATE,
} from '../../actions/forgotPassword/forgotPasswordActionTypes';

const initialState = {
  loading: false,
  response: {},
  error: '',
  fetched: false,
  token: '',
};

const forgotPasswordReducer = (state = initialState, action: any):any => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        fetched: true,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        response: {},
        error: action.payload,
      };
    case CHANGE_FETCHED_STATE:
      return {
        ...state,
        fetched: false,
      };
    default:
      return state;
  }
};

export default forgotPasswordReducer;
