import {
  UPLOAD_STATEMENT_REQUEST,
  UPLOAD_STATEMENT_SUCCESS,
  UPLOAD_STATEMENT_FAILURE,
} from '../../actions/statements/statementActionTypes';

const initialState = {
  loading: false,
  response: '',
  error: '',
  fetched: false,
};
interface Payload {
  Id: number;
  AffiliateName: string;
  DocumentFieldId: string;
  FileName: string;
  Month: string;
  Year: number;
}
interface UploadStatements {
  payload: Payload;
  type: string;
}
const uploadStatementReducer = ( state = initialState, action: UploadStatements):any => {
  switch (action.type) {
    case UPLOAD_STATEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      };
    case UPLOAD_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: '',
        fetched: true,
      };
    case UPLOAD_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        response: '',
        error: action.payload,
        fetched: false,
      };
    default:
      return state;
  }
};

export default uploadStatementReducer;
