import { Button, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import { changeFetchedState, changeFetchedStateSupport } from '../../redux';
import { useAppDispatch } from '../../app/hooks';
import React, { useEffect } from 'react';
import { useAppSelector } from '../../app/hooks';

interface CustomizedState {
  heading: string;
  message: string;
}
const ConfirmPage: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const isLoggedIn = useAppSelector((store) => store.user.isLoggedIn);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(changeFetchedStateSupport());
    dispatch(changeFetchedState());
  }, []);

  return (
    <Typography component="div" className={classes.mainDiv}>
      <Typography component="div" className={classes.internalMainDiv}>
        <Typography component="h1" className={classes.confirmedTitle}>
          {state ? state.heading : 'Confirm Page'}
        </Typography>
        <Typography component="p" className={classes.confirmedtext}>
          {state ? state.message : 'Confirm Message'}
        </Typography>
        <Button
          variant="contained"
          className={classes.Button}
        >
          <Link to='/' className={classes.links}>
            {isLoggedIn ? 'Back to Performance Dashboard' : 'Back to sign in'}
          </Link>

        </Button>
      </Typography>
    </Typography>
  );
};

export default ConfirmPage;
