import React from 'react';

const UpArrow: React.FC = () => {
  return (
    <svg
      width="4"
      height="9"
      viewBox="0 0 4 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.977 9V1.421C1.69967 1.655 1.28367 1.90633 0.729 2.175V1.72C1.04967 1.44267 1.327 1.161 1.561 0.875C1.795 0.589 1.98567 0.298666 2.133 0.00399959H2.367C2.51433 0.298666 2.705 0.589 2.939 0.875C3.173 1.161 3.45033 1.44267 3.771 1.72V2.175C3.50233 2.045 3.264 1.91933 3.056 1.798C2.848 1.668 2.67033 1.54233 2.523 1.421V9H1.977Z"
        fill="#09753B"
      />
    </svg>
  );
};

export default UpArrow;
