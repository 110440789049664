import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteStatement } from '../../../redux';
import CloseIconModals from '../../Media/Svg/CloseIconModals';
import { OptionsModalStyles } from '../OptionsModal/styles';

interface StatementDel {
  row: {
    AffiliateName: string;
    DocumentFileId: string;
    FileName: string;
    Id: number;
    Month: string;
    Year: number;
  };
}

export default function DeleteStatementModal({ row }: StatementDel): React.ReactElement | null {
  const dispatch = useDispatch();
  const classes = OptionsModalStyles();

  const [openModalDelete, setOpenModalDelete] = useState(false);

  const handleOpenModalDelete = (): void => setOpenModalDelete(true);
  const handleCloseModalResetDelete = (): void => setOpenModalDelete(false);

  const deleteRow = async (): Promise<void> => {
    const id = row.DocumentFileId.split('?id=')[1].split("'>")[0];
    dispatch(deleteStatement(id) as any);
    handleCloseModalResetDelete();
  };
  return (
    <>
      <Button onClick={handleOpenModalDelete}>
        <DeleteIcon color="disabled" />
      </Button>
      <Modal
        keepMounted
        open={openModalDelete}
        onClose={handleCloseModalResetDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className={classes.modal}>
          <CloseIconModals setSnackClose={handleCloseModalResetDelete} />
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            className={classes.modalTitle}
          >
            Are you sure you want to delete <b>{row.FileName}</b>?
          </Typography>

          <Button
            variant="contained"
            className={classes.updateButton}
            onClick={() => deleteRow()}
          >
            Delete Statement
          </Button>
          <Button
            variant="outlined"
            onClick={handleCloseModalResetDelete}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </>
  );
}
