import { getHelpLogo } from './styles';
import React from 'react';
const GetHelpLogo: React.FC = () => {
  const classes = getHelpLogo();

  return (
    <svg
      className={classes.SVGDropdown}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00016 0.333252C3.32016 0.333252 0.333496 3.31992 0.333496 6.99992C0.333496 10.6799 3.32016 13.6666 7.00016 13.6666C10.6802 13.6666 13.6668 10.6799 13.6668 6.99992C13.6668 3.31992 10.6802 0.333252 7.00016 0.333252ZM7.66683 11.6666H6.3335V10.3333H7.66683V11.6666ZM9.04683 6.49992L8.44683 7.11325C7.96683 7.59992 7.66683 7.99992 7.66683 8.99992H6.3335V8.66659C6.3335 7.93325 6.6335 7.26659 7.1135 6.77992L7.94016 5.93992C8.18683 5.69992 8.3335 5.36659 8.3335 4.99992C8.3335 4.26659 7.7335 3.66659 7.00016 3.66659C6.26683 3.66659 5.66683 4.26659 5.66683 4.99992H4.3335C4.3335 3.52659 5.52683 2.33325 7.00016 2.33325C8.4735 2.33325 9.66683 3.52659 9.66683 4.99992C9.66683 5.58659 9.42683 6.11992 9.04683 6.49992Z"
        fill="#001B33"
      />
    </svg>
  );
};

export default GetHelpLogo;
